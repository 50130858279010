import * as React from 'react';
import { Box, Typography, Divider, makeStyles, ThemeProvider } from "@material-ui/core";
import { cpTheme, cpThemeDark } from '../../theme/cpTheme';
import { EquipmentControls, ModeState } from '../equipment/equipment-controls';
import { fahrenheitTemperatureFormatter } from '../_page-device';
import Axios from 'axios';
import { API_BASE_URL, WS_BASE_URL } from '../../config';
import ReconnectingWebsocket from "reconnecting-websocket"
import { useLocation, useRouteMatch } from 'react-router';
import { TouchTemperatureController } from '../_physical-devices/temperature-controller/temperature-controller';
import { LiveReadingsContext } from '../../hooks/liveReadings';
import { SyncStatus } from '../status-cloud-sync';
import ReconnectingWebSocket from 'reconnecting-websocket';

const useStyles = makeStyles(theme => ({
  touchScreen: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  },
}));

export const useDebugMessages = (id: string) => {
  return {}
}

export const useLiveReading = (id: string, port?: string | null) => {
  const deviceId = id.toLowerCase()

  const liveReadingsContext = React.useContext(LiveReadingsContext)

  const key = `${deviceId}${port ? `-${port}` : ""}`

  React.useEffect(() => liveReadingsContext.subscribe(deviceId, port), [deviceId, port])

  const currentReading = liveReadingsContext.readings[key]?.currentValue
  const currentSetpoint = liveReadingsContext.readings[key]?.setpoint
  const currentMode = liveReadingsContext.readings[key]?.mode
  const title = liveReadingsContext.titles[key] ?? ""

  const pendingChange = liveReadingsContext.pendingChanges[deviceId]

  const setpointSaving = pendingChange?.setpoint && (pendingChange.setpoint !== currentSetpoint)
  const modeSaving = pendingChange?.mode && (pendingChange.mode !== currentMode)

  const eitherSaving = setpointSaving || modeSaving

  const syncStatus = (() => {
    switch (eitherSaving) {
      case true:
        return SyncStatus.syncing
      case false:
        return SyncStatus.success
    }
  })()

  const setMode = (mode: ModeState) =>
    liveReadingsContext.setPendingChange(deviceId, { ...pendingChange, mode })

  const setSetpoint = (setpoint: number) =>
    liveReadingsContext.setPendingChange(deviceId, { ...pendingChange, setpoint })

  return {
    currentReading,
    title,
    mode: currentMode,
    setpoint: currentSetpoint,
    setMode,
    setSetpoint,
    syncStatus,
    pendingMode: pendingChange?.mode,
    pendingSetpoint: pendingChange?.setpoint,
    error: liveReadingsContext.errors[deviceId]
  }
}

export const TemperatureController: React.FC = (props) => {
  const { params: { id: deviceId } } = useRouteMatch()

  return (
    <TouchTemperatureController deviceId={deviceId || "95E96AC5-36C5-4BF1-857F-5F1512B06316"} />
  )
}
