import * as React from "react";
import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";
import { gql } from "apollo-boost";
import { Table, TableRow, CircularProgress, TableCell, Box, Button, TableHead, Card, Grid } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { DeviceDetailQuery } from "./__generated__/DeviceDetailQuery";
import { useParams } from "react-router";

type SuperadminDeviceDetailProps = ISuperadminDeviceDetailProps;

interface ISuperadminDeviceDetailProps {

}

const DEVICE_DETAIL_QUERY = gql`
  query DeviceDetailQuery(
    $identifier: String!
  ) {
    device(id: $identifier) {
      identifier
      created
      equipmentInstance {
        id
        name
        equipment {
          id
          name
        }
      }
      owner {
        id
        name
      }
      topics {
        subscribes {
          name
        }
        publishes{
          name
        }
      }
      ports {
        position
        serial
        type
        installation {
          id
          name
          instance {
            id
            name
            equipment {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const SuperadminDeviceDetail: React.FC<SuperadminDeviceDetailProps> = (props) => {
  const { identifier } = useParams()

  const { data: device, loading } = useQuery<DeviceDetailQuery>(DEVICE_DETAIL_QUERY, {
    variables: {
      identifier
    }
  })

  if (loading || !device) return <CircularProgress />

  return (
    <LayoutWithHeaderAndBody
      tenantName={"Connected Proof"}
      title={"Superadmin"}
      contentSection={
        <Grid container direction="row">
          <Grid item md={6}>
            <Card>
              <Table>
                <TableRow>
                  <TableCell>Identifier</TableCell>
                  <TableCell>{device.device.identifier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Owner</TableCell>
                  <TableCell>{device.device.owner ? (
                    <Table>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{device.device.owner.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{device.device.owner.name}</TableCell>
                      </TableRow>
                    </Table>
                  ) : (<Box />)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Topics</TableCell>
                  <TableCell>
                    <Table>
                      <TableRow>
                        <TableCell>
                          Subscribes
                    </TableCell>
                        <TableCell>
                          {device.device.topics.subscribes.map(topic => <Box>{topic.name}</Box>)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Publishes
                    </TableCell>
                        <TableCell>
                          {device.device.topics.publishes.map(topic => <Box>{topic.name}</Box>)}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ports</TableCell>
                  <TableCell>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Serial</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Installation</TableCell>
                        </TableRow>
                      </TableHead>
                      {device.device.ports.map(port => (
                        <TableRow>
                          <TableCell>{port.serial}</TableCell>
                          <TableCell>{port.type}</TableCell>
                          <TableCell>{port.installation?.id}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableCell>
                </TableRow>
              </Table>
            </Card>
          </Grid>
          <Grid item md={6}>
            Logs
          </Grid>
        </Grid>
      }
    />
  )
}