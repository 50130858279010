import * as React from "react";
import { Hidden, IconButton, Typography } from "@material-ui/core";
import { CPIcon, ECPIcon } from "../icon";

type InstallationPageActionsProps = IInstallationPageActionsProps

interface IInstallationPageActionsProps {
  setRefresh: (v: string) => void;
}

export const DevicesPageActions: React.FC<InstallationPageActionsProps> = (props) => {
  const { setRefresh } = props

  return (
    <>

    </>
  )
}