import * as React from "react";

import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import RouterOutlinedIcon from '@material-ui/icons/RouterOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined"
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { UserAvatar } from "../user-button";
import { FacilityIcon } from "../icons-custom/facility";


type CPIconProps = ICPIconProps

interface ICPIconProps {
  icon: ECPIcon
}

export enum ECPIcon {
  Devices,
  Facilty,
  Control,
  Settings,
  Refresh,
  Account,
  Superadmin
}

export const CPIcon: React.FC<CPIconProps> = (props) => {
  const iconMap = {
    [ECPIcon.Devices]: RouterOutlinedIcon,
    [ECPIcon.Control]: TuneOutlinedIcon,
    [ECPIcon.Facilty]: FacilityIcon,
    [ECPIcon.Settings]: SettingsOutlinedIcon,
    [ECPIcon.Refresh]: SyncOutlinedIcon,
    [ECPIcon.Account]: UserAvatar,
    [ECPIcon.Superadmin]: SupervisorAccountIcon,
  }

  const component = iconMap[props.icon]

  if (!component) throw Error()

  return React.createElement(component)
}