import * as React from "react";
import { Box, Typography, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { cpTheme } from "../../theme/cpTheme";
import Axios from "axios";
import { API_BASE_URL } from "../../config";

type PageResetPasswordProps = IPageResetPasswordProps;

interface IPageResetPasswordProps {

}

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1, 0),
    },
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3, 0),
  },
  mktbox: {
    background: theme.palette.grey[200],
  },
  authFormHeader: {
    padding: theme.spacing(3, 0),
  },
  authFormAction: {
    padding: theme.spacing(3, 0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  authFormAfter: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #ccc',
    borderTopColor: theme.palette.divider,
  },
}));

export const PageResetPassword: React.FC<PageResetPasswordProps> = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const location = useLocation()

  const token = location.search?.substring(1)

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  if (!token) throw Error()

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const submitResetRequest = async () => {
    setLoading(true)
    const response = await Axios.post(`${API_BASE_URL}/auth/resetPassword`, JSON.stringify(
      {
        newPassword,
        token
      }
    ))

    if (response.status == 200) {
      window.location.href = "/login"
    }
  }

  return (
    <Box pb={14}>
      <Box className={classes.authFormHeader}>
        <Typography component="h2" variant="h6">
          Reset your password
        </Typography>
      </Box>
      <form onSubmit={(event) => {
        event.preventDefault()
        submitResetRequest()
      }} className={classes.root} noValidate autoComplete="off">
        <FormControl
          className={classes.margin}
          variant="outlined"
          fullWidth
        >
          <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={false}

            labelWidth={75}
          />
        </FormControl>
        <FormControl
          className={classes.margin}
          variant="outlined"
          fullWidth
        >
          <InputLabel htmlFor="standard-adornment-password">Confirm New Password</InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            error={false}

            labelWidth={75}
          />
        </FormControl>
        <Box className={classes.authFormAction}>
          <Button type="submit" disabled={!newPassword || !confirmNewPassword || (newPassword != confirmNewPassword) || (newPassword.length < 6)} size="large" variant="contained" color="primary">
            {loading ? <CircularProgress /> : "Reset Password"}
          </Button>
        </Box>
      </form>
    </Box>
  )
}