import * as React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { TenantDynamoLookupQuery, TenantDynamoLookupQueryVariables } from "./__generated__/TenantDynamoLookupQuery";
import { CircularProgress, Table, TableHead, TableCell, TableRow, TableContainer, TableBody } from "@material-ui/core";

type AdminTenantDynamoLookupTableProps = IAdminTenantDynamoLookupTableProps;

interface IAdminTenantDynamoLookupTableProps {
  tenantId: string;
}

const DYNAMO_LOOKUP_QUERY = gql`
  query TenantDynamoLookupQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      id
      dynamoReadingLookupMappings {
        installation {
          id
        }
        idValue
        valueColumn
        table
      }
    }
  }
`;

export const AdminTenantDynamoLookupTable: React.FC<AdminTenantDynamoLookupTableProps> = (props) => {
  const lookups = useQuery<TenantDynamoLookupQuery, TenantDynamoLookupQueryVariables>(DYNAMO_LOOKUP_QUERY, {
    variables: {
      tenantId: props.tenantId
    }
  })

  if (lookups.loading) return <CircularProgress />

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Table Name</TableCell>
            <TableCell>ID Value</TableCell>
            <TableCell>Column Name</TableCell>
            <TableCell>Installation ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lookups.data?.tenant.dynamoReadingLookupMappings.map(lookup => (
            <TableRow>
              <TableCell>{lookup.table}</TableCell>
              <TableCell>{lookup.idValue}</TableCell>
              <TableCell>{lookup.valueColumn}</TableCell>
              <TableCell>{lookup.installation.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}