import * as React from "react";

import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { useTenant } from "../../hooks/tenant";
import { EquipmentCard } from "../equipment/equipment-card";
import { useAppState } from "../../hooks/appState";

import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";
import { CPIcon, ECPIcon } from "../icon";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
}));

export const ControlPage: React.FC = (props) => {
  const { tenantId, setDrawerDeviceId } = useAppState()
  const { data } = useTenant(tenantId || "")
  const classes = useStyles();

  return (
    <LayoutWithHeaderAndBody
      tenantName={data?.tenant?.name ?? ""}
      title={"Control Panel"}
      actionsSection={
        <span />
      }
      contentSection={
        <Grid
          container
          spacing={3}
        >
          {/* <Grid item xs={12} sm={6} md={4} xl={3}>
            <EquipmentCard />
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3}>
            <EquipmentCard />
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3}>
            <EquipmentCard />
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3}>
            <EquipmentCard />
          </Grid> */}

        </Grid>
      }
    />
  )
}