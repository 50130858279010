/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountTenantRoleType {
  Administrator = "Administrator",
  Member = "Member",
  Owner = "Owner",
}

export enum GqlPortType {
  Input = "Input",
  Output = "Output",
}

export enum NavigationItem {
  ControlPanel = "ControlPanel",
  Devices = "Devices",
  Facility = "Facility",
}

export interface InterfaceInstanceNameUpdateInput {
  id: string;
  newName: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
