import * as React from 'react';

import { makeStyles, Box, Typography, IconButton, Divider, Button, Hidden, Container, Toolbar, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Panel } from '../panel';
import { cpTheme, cpVars } from '../../theme/cpTheme';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ResponsiveContainer, LineChart, ReferenceLine, YAxis, Line } from 'recharts';

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
      minWidth: 450,
    },
  },
  section: {
    margin: theme.spacing(2, 0, 3),
  },
  type: {
    margin: theme.spacing(0, 0, 2),
  },
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },

}));


export const DeviceSettingsDrawer: React.FC = (props) => {
  const classes = useStyles();

  const [age, setAge] = React.useState('');

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };

  return (
    <Box className={classes.equipmentDrawer}>
      <Panel
        header={
          <Toolbar>
            <Box>
              <Typography variant="overline" display="block">Device Settings</Typography>
              {/* <Typography variant="h6">Device Name</Typography> */}
            </Box>
            <Box className={classes.grow} />
            <Box className={classes.actions}>
              <Hidden xsDown>
                <IconButton>
                  <CloseOutlinedIcon />
                </IconButton>
              </Hidden>
            </Box>
          </Toolbar>
        }
        body={
          <>
            <Container className={classes.section}>
              <Typography variant="body1" className={classes.type}>
                <strong>Device Info</strong>
              </Typography>
              <form className={classes.root}>
                <div>
                  <TextField
                    id="device-name"
                    label="Device name"
                    defaultValue="Some Device Name 0123456"
                    variant="outlined"
                  />
                </div>
                <div>
                  <FormControl variant="outlined">
                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                      Installation Location
                  </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={age}
                      onChange={handleChange}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value={10}>Ferm #1</MenuItem>
                      <MenuItem value={20}>Ferm #2</MenuItem>
                      <MenuItem value={30}>Ferm #3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form>
            </Container>
            <Container className={classes.section}>
              <Typography variant="body1" className={classes.type}>
                <strong>Sensor Input</strong>
              </Typography>
              <form className={classes.root}>
                <div>
                  <TextField
                    id="sensor-name"
                    label="Sensor name"
                    defaultValue="Default Sensor Input 0123456"
                    variant="outlined"
                  />
                </div>
              </form>
            </Container>
            <Container className={classes.section}>
              <Typography variant="body1" className={classes.type}>
                <strong>Control Output</strong>
              </Typography>
              <form className={classes.root}>
                <div>
                  <TextField
                    id="controller-name"
                    label="Controller name"
                    defaultValue="Default Controller Output 0123456"
                    variant="outlined"
                  />
                </div>
              </form>
            </Container>
          </>
        }
        footer={
          <Hidden smUp>
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Hidden>
        }
      />
    </Box>
  )
}
