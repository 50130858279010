import * as React from "react";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useLiveReading } from "../physical-devices/temperature-controller";
import { NoData } from "../device-card";

type SensorReadingsProps = ISensorReadingsProps;

export interface ISensorReading {
  title: string;
  name?: string;
  currentReading?: number | null;
  deviceId?: string | null;
}

interface ISensorReadingsProps {
  formatter?: (v: number) => string;
}

const useStyles = makeStyles(theme => ({
  deviceRow: {
    width: '100%',
  },
  sensorRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    justifyContent: 'space-between',
  },
}))

interface ISensorReadingRowProps {
  deviceId: string;
  port: string;
  title: string;
  subtitle?: string | null;
}

type SensorReadingRowProps = ISensorReadingRowProps

export const SensorReadingRow: React.FC<SensorReadingRowProps> = (props) => {
  const value = useLiveReading(props.deviceId, props.port)
  const classes = useStyles()

  return (
    <Box className={classes.sensorRow}>
      <Typography variant="body2" color="textSecondary" noWrap>
        {props.title}{props.subtitle ? `/ ${props.subtitle}` : null}
      </Typography>
      <Typography variant="h6">
        {value.currentReading ? fahrenheitTemperatureFormatter(value.currentReading) : <NoData />}
      </Typography>
    </Box>
  )
}

export const SensorReadings: React.FC<SensorReadingsProps> = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.deviceRow}>
      {props.children}
    </Box>
  )
}