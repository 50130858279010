import * as React from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Panel } from "../panel";
import { Toolbar, Box, Typography, IconButton, Hidden, Container, makeStyles, Divider, Button } from "@material-ui/core";
import { ArrowBackOutlined, SettingsOutlined, CloseOutlined, ArrowBack } from "@material-ui/icons";
import { cpTheme } from "../../theme/cpTheme";

type PanelWithSettingsAndBackProps = IPanelWithSettingsAndBackProps;

interface IPanelWithSettingsAndBackProps {
  mainContents: React.ReactNode;
  settingsContents: React.ReactNode;
  settingsActions?: React.ReactNode;
  onClose?: null | undefined | (() => void);
  onBack?: null | undefined | (() => void);
  title: string;
}

enum PanelMode {
  Main = "main",
  Settings = "settings"
}


const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
      minWidth: 450,
    },
  },
  section: {
    margin: theme.spacing(3, 0, 8),
  },
  type: {
    margin: theme.spacing(0, 0, 2),
  },
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  settingsTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
  },
  footerContentMain: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
  footerContentSettings: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
  }
}));

export const PanelWithSettingsAndBack: React.FC<PanelWithSettingsAndBackProps> = (props) => {
  const [mode, setMode] = React.useState(PanelMode.Main)
  const classes = useStyles()

  const onClose = () => {
    setMode(PanelMode.Main)
    props.onClose && props.onClose()
  }

  const back = () => {
    setMode(PanelMode.Main)
    props.onBack && props.onBack()
  }

  const panelProps = {
    header: (
      <Toolbar>
        <Box>
          {(mode === PanelMode.Main) && (
            <Typography variant="overline">{props.title}</Typography>
          )}
          {(mode == PanelMode.Settings) && (
            <Box className={classes.settingsTitle}>
              {/* <IconButton onClick={() => setMode(PanelMode.Main)}>
                <ArrowBackOutlined />
              </IconButton>
              <Divider flexItem orientation="vertical" /> */}
              <Typography variant="overline">Settings</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.grow} />
        <Box className={classes.actions}>
          <Hidden xsDown>
            {(mode === PanelMode.Main) && (
              <>
                <IconButton aria-label="settings" onClick={() => setMode(PanelMode.Settings)}>
                  <SettingsOutlined />
                </IconButton>
                <IconButton aria-label="close" onClick={onClose}>
                  <CloseOutlined />
                </IconButton>
              </>
            )}
            {(mode === PanelMode.Settings) && (
              <>
                {props.settingsActions}
                <Button onClick={() => setMode(PanelMode.Main)}>
                  Done
                </Button>
              </>
            )}
          </Hidden>
        </Box>
      </Toolbar>
    ),
    body: (
      <Container className={classes.section}>
        <Box>
          {(mode == PanelMode.Main) && props.mainContents}
          {(mode == PanelMode.Settings) && props.settingsContents}
        </Box>
      </Container>
    ),
    footer: (
      <Hidden smUp>
        {(mode === PanelMode.Main) && (
          <Box className={classes.footerContentMain}>
            <IconButton aria-label="close">
              <ArrowBack onClick={onClose} />
            </IconButton>
            <IconButton aria-label="settings" onClick={() => setMode(PanelMode.Settings)}>
              <SettingsOutlined />
            </IconButton>
          </Box>
        )}
        {(mode === PanelMode.Settings) && (
          <Box className={classes.footerContentSettings}>
            {props.settingsActions}
            <Button onClick={() => setMode(PanelMode.Main)}>
              Done
            </Button>
          </Box>
        )}
      </Hidden>
    ),
  }

  return (
    <Panel 
      footerVariant="raised"
      {...panelProps} 
    />
  )
};