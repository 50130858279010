import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, FormControl, InputLabel, Select, MenuItem, DialogActions, Button, makeStyles } from "@material-ui/core";
import { cpTheme } from "../../theme/cpTheme";

type ModalDialogProps = IModalDialogProps;

interface IModalDialogProps {
  open: boolean;
  title: string;
  handleClose: () => void;
  content: React.ReactNode;
  handleSubmit: () => void;
  submitText: string;
}

const useStyles = makeStyles( theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  dialog: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
    },
  },
}));

export const ModalDialog: React.FC<ModalDialogProps> = ({
  open,
  title,
  handleClose,
  content,
  submitText,
  handleSubmit
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={classes.dialog}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}