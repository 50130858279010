import * as React from "react";

type BeltlineFacilityViewProps = IBeltlineFacilityViewProps;

interface IBeltlineFacilityViewProps {

}

export const BeltlineFacilityView: React.FC<BeltlineFacilityViewProps> = (props) => {
  return (
    <span/>
  )
}