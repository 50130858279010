import * as React from "react";
import clsx from 'clsx';
import { Typography, Box, makeStyles, Toolbar } from "@material-ui/core";


const useStyles = makeStyles( theme => ({
  mainContent: {
    // These should match padding in <MainContent>
    padding: theme.spacing(0,3),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0,4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0,5),
    },
  },
  pageTitle: {
    '& .MuiTypography-root': {
      lineHeight: '1.1',
    },
  }
}));

interface IHeaderDefaultProps {
  tenantName?: string,
  title: string,
  actionsSection?: React.ReactNode,
  className?: string,
}

type HeaderDefaultProps = IHeaderDefaultProps

export const HeaderDefault: React.FC<HeaderDefaultProps> = ({
  className, ...props
}) => {
  const classes = useStyles();
  return (
    <Toolbar className={clsx(
        className, classes.mainContent
      )}>
      <Box className={classes.pageTitle} flexGrow={1}>
        {props.tenantName &&
        <Typography variant="caption">
          {props.tenantName}
        </Typography>
        }
        <Typography variant="body1" component="h1">
          <b>{props.title}</b>
        </Typography>
      </Box>
      <Box>
        {props.actionsSection}
      </Box>
    </Toolbar>
  )
};