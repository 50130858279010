import * as React from "react";
import {LayoutAuthentication} from "../layout-authentication";
import {RouteProps} from "../route";
import {Route} from "react-router";
import { useAppState } from "../../hooks/appState";

type RouteWithAuthenticationLayoutProps = RouteProps

export const RouteWithAuthenticationLayout: React.FC<RouteWithAuthenticationLayoutProps> = (props) => {
  const { appRoute, setAppRoute } = useAppState()

  React.useEffect(
    () => {
      setAppRoute(false)
    }, []
  )

  return (
    <Route path={props.path}>
      <LayoutAuthentication
        content={
          <props.component {...props} />
        }
      />
    </Route>
  )
}