import * as React from 'react';
import { Box, Typography, makeStyles, Paper } from "@material-ui/core";
import { EquipmentControls, IEquipmentControls } from "../../equipment/equipment-controls";
import { SystemStatusBar } from "../system/system-status-bar";
import { Alert } from '@material-ui/lab';
import { fahrenheitTemperatureFormatter } from '../../_page-device';
import { useLiveReading } from '../../physical-devices/temperature-controller';

// Notes:
// When getting this on an actual device, we need to:
// • Add the correct fonts to the HTML head
// • Confirm that viewport takes up 100vw/vh units
// • Ensure the bundle uses ThemeProvider at the root (the override is broken)

const useStyles = makeStyles(theme => ({
  touchScreen: {
    // reset to use viewport units to ensure we're taking up full screen regardless of state of <body>
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',

    // We change properties based on this class here for the touchscreen only
    // but the class is assigned in equipment-controls.tsx
    
    '& .modeCool':{
      '& .MuiOutlinedInput-root, & .MuiOutlinedInput-root:hover': {
        backgroundColor: theme.palette.modeCool.main,
        color: theme.palette.modeCool.dark,
      },
      '& .MuiIconButton-root': {
        backgroundColor: theme.palette.modeCool.main,
        color: theme.palette.modeCool.dark,
      },
    },
    
  },
  bgPaper: {
    // because of quirk with ThemeProvider, we need to use a Paper element to ensure children inherit some CSS correctly. 
    // This should be the default parent.
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    border: 'none',
  },
  header: {
    height: 'auto',
    width: '100%',
  },
  content: {
    flex: 'auto',
    height: 'auto',
    width: '100%',
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  currentReading: {
    fontSize: '3.8rem',
    marginTop: '-1.4rem',
  },
}));

interface ITemperatureControllerProps {
  deviceId: string;
}

type TemperatureControllerProps = ITemperatureControllerProps

export const TouchTemperatureController: React.FC<TemperatureControllerProps> = (props) => {
  const classes = useStyles();
  const device = useLiveReading(props.deviceId)

  const alerts = [
    <Alert severity="warning">Cooling output device is not connected. Check wired connection or view settings in CP Cloud to troubleshoot or remove.</Alert>,
    <Alert severity="success">Synced with Cloud Connect.</Alert>
  ]

  return (
    <Box className={classes.touchScreen}>
      <Paper elevation={0} variant="outlined" square className={classes.bgPaper}>

        <Box className={classes.header}>
          <SystemStatusBar
            systemTitle={device.title}
            statusDrawerContent={alerts}
            syncStatus={device.syncStatus}
          />
        </Box>

        {device.currentReading ? (
          <Box className={classes.content}>
            {/* <Typography className={classes.currentReading} variant="h1" align="center" gutterBottom>
              {device.currentReading && fahrenheitTemperatureFormatter(device.currentReading)}
            </Typography> */}
            <EquipmentControls deviceId={props.deviceId} />
          </Box>
        ) : <span />}

      </Paper>
    </Box>
  )
};