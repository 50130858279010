import * as React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { AccountTenantRoleType } from "../../__generated/globalTypes";

type AccountTenantAssociationRoleSelectorProps = IAccountTenantAssociationRoleSelectorProps;

interface IAccountTenantAssociationRoleSelectorProps {
  value: AccountTenantRoleType | null | undefined
  onChange: (role: AccountTenantRoleType) => any
  disabled?: boolean
}

const allValidRoles = [
  AccountTenantRoleType.Member,
  AccountTenantRoleType.Administrator,
  AccountTenantRoleType.Owner
]

export const AccountTenantAssociationRoleSelector: React.FC<AccountTenantAssociationRoleSelectorProps> = (props) => {
  return (
    <Select
      value={props.value}
      disabled={props.disabled}
      placeholder="Role for new account"
      onChange={(e) => props.onChange(e.target.value as AccountTenantRoleType)}
    >
      {allValidRoles.map(role => (
        <MenuItem value={role}>{role}</MenuItem>
      ))}
    </Select>
  )
}