import * as React from "react";
import { Box, Button, Link, TextField, Typography, makeStyles } from "@material-ui/core";
import { cpTheme } from "../../theme/cpTheme";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { API_BASE_URL } from "../../config";

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1, 0),
    },
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3, 0),
  },
  mktbox: {
    background: theme.palette.grey[200],
  },
  authFormHeader: {
    padding: theme.spacing(3, 0),
  },
  authFormAction: {
    padding: theme.spacing(3, 0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  authFormAfter: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #ccc',
    borderTopColor: theme.palette.divider,
  },
}));

interface State {
  password: string;
  showPassword: boolean;
}

type PageForgotPasswordProps = IPageForgotPasswordProps;

interface IPageForgotPasswordProps {

}

export const PageForgotPassword: React.FC<PageForgotPasswordProps> = (props) => {
  const classes = useStyles();
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const [saving, setSaving] = React.useState(false)
  const [emailAddress, setEmailAddress] = React.useState("") 

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const sendReset = async () => {
    setSaving(true)
    await Axios.post(`${API_BASE_URL}/auth/forgotPassword`, {
      emailAddress
    })
    setSaving(false)
  }

  return (
    <Box>
      <Box className={classes.authFormHeader}>
        <Typography component="h2" variant="h6" gutterBottom>
          Forgot your password?
        </Typography>
        <Typography color="textSecondary">
          Enter the email you registered with and we'll send you instructions to reset it.
        </Typography>
      </Box>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          fullWidth
          disabled={saving}
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
        />
        <Box className={classes.authFormAction}>
          <Button onClick={() => sendReset()} size="large" variant="contained" color="primary">
            Send Reset
          </Button>
          <Typography component="span">
            <NavLink to="/login">Go to Sign In &rarr;</NavLink>
          </Typography>
        </Box>
      </form>
      {/* <Box className={classes.authFormAfter}>
        <Typography>
          Don't have an account? <Link href="#" onClick={preventDefault}>Register now.</Link>
        </Typography>
      </Box> */}
    </Box>
  )
}