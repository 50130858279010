import * as React from "react";
import { gql } from "apollo-boost";
import { Tabs, Tab, CircularProgress, Card, CardContent, Typography, Box, TableCell, TableBody, Table, TableRow, TableHead } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { TenantViewsQuery, TenantViewsQueryVariables } from "./__generated__/TenantViewsQuery";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { IdLink } from "../id-link";
import { TreeView, TreeItem } from '@material-ui/lab';

type AdminTenantViewsProps = IAdminTenantViewsProps;

interface IAdminTenantViewsProps {
  tenantId: string;
}

const TENANT_VIEWS_QUERY = gql`
  query TenantViewsQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      id
      navigation
      views {
        control {
          id
        }
      }
    }
  }
`

export const AdminTenantViews: React.FC<AdminTenantViewsProps> = (props) => {
  const [tab, setTab] = React.useState(0)

  const views = useQuery<TenantViewsQuery, TenantViewsQueryVariables>(TENANT_VIEWS_QUERY, {
    variables: {
      tenantId: props.tenantId
    },
    fetchPolicy: "no-cache"
  })

  if (views.loading) return <CircularProgress />

  const tabContent: { [key: number]: React.ReactNode } = {
    0: (
      <>
        <ul>
          {views.data?.tenant.navigation.map(navigation => (
            <li>{navigation}</li>
          ))}
        </ul>
      </>
    ),
    1: (
      <>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Installation ID</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Installation Name</TableCell>
                <TableCell>Equipment Instance</TableCell>
                <TableCell>Installation Target</TableCell>
              </TableRow>
            </TableHead>
            {/* <TableBody>
              {views.data?.tenant.views.devices.map((device, index) => (
                <TableRow key={device.id}>
                  <TableCell><IdLink value={device.id} /></TableCell>
                  <TableCell>{device.created}</TableCell>
                  <TableCell>{device.name ?? "(unnamed)"}</TableCell>
                  <TableCell>
                    <IdLink value={device.instance.id} />
                    ({device.instance.name ?? device.instance.equipment.name})
                  </TableCell>
                  {
                    (device.installationTarget.__typename === "EquipmentInterfaceInstanceInstallationTarget") && (
                      <TableCell>
                        <TreeView
                          expanded={
                            [device.installationTarget.equipmentInterfaceInstance.equipmentInstance.id]
                          }
                          onNodeToggle={() => { }}>
                          <TreeItem
                            nodeId={device.installationTarget.equipmentInterfaceInstance.equipmentInstance.id}
                            label={
                              <>
                                <IdLink value={device.installationTarget.equipmentInterfaceInstance.equipmentInstance.id} />
                                ({device.installationTarget.equipmentInterfaceInstance.equipmentInstance.name ??
                                  device.installationTarget.equipmentInterfaceInstance.equipmentInstance.equipment.name})
                            </>
                            }
                          >
                            <TreeItem
                              nodeId={device.installationTarget.equipmentInterfaceInstance.id}
                              label={
                                <>
                                  <IdLink value={device.installationTarget.equipmentInterfaceInstance.id} />
                                  ({device.installationTarget.equipmentInterfaceInstance.name ??
                                    device.installationTarget.equipmentInterfaceInstance.equipmentInterface.name})
                              </>
                              }></TreeItem>

                          </TreeItem>
                        </TreeView>
                      </TableCell>
                    )
                  }
                  {
                    (device.installationTarget.__typename === "LocationInstallationTarget") && (
                      <TableCell>
                        {device.installationTarget.location.name}
                      </TableCell>
                    )
                  }
                </TableRow>
              ))}
            </TableBody> */}
          </Table>
        </Card>
      </>
    ),
    2: (
      <>
        <ul>
          {/* {views.data?.tenant.views.facility.map(installation => (
            <Card>
              <CardContent>
                <Typography>Installation</Typography>
                <Typography>{installation.installation.id}</Typography>
                {installation.diagram ? <div dangerouslySetInnerHTML={{ __html: installation.diagram.svg }} /> : null}
              </CardContent>
            </Card>
          ))} */}
        </ul>
      </>
    ),
    3: (
      <>
        <h3>Coming soon</h3>
      </>
    )
  }

  return (
    <>
      <Tabs
        value={tab}
        onChange={(e, index) => setTab(index)}
      >
        <Tab label="Nav Bars" />
        <Tab label="Devices" />
        <Tab label="Facility" />
        <Tab label="Control Panel" />
      </Tabs>
      {tabContent[tab]}

    </>
  )
}