import * as React from "react";
import {
  Box, Button,
  FormControl, FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel, Link,
  OutlinedInput,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1, 0),
    },
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3, 0),
  },
  mktbox: {
    background: theme.palette.grey[200],
  },
  authFormHeader: {
    padding: theme.spacing(3, 0),
  },
  authFormAction: {
    padding: theme.spacing(3, 0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  authFormAfter: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #ccc',
    borderTopColor: theme.palette.divider,
  },
}));

interface State {
  password: string;
  showPassword: boolean;
}


type PageRegisterProps = IPageRegisterProps;

interface IPageRegisterProps {

}

export const PageRegister: React.FC<PageRegisterProps> = (props) => {
  const classes = useStyles();
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box pb={14}>
      <Box className={classes.authFormHeader}>
        <Typography component="h2" variant="h6">
          Register for Connected Proof
        </Typography>
      </Box>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="first-name"
          label="First Name"
          variant="outlined"
          fullWidth
        />
        <TextField
          id="last-name"
          label="Last Name"
          variant="outlined"
          fullWidth
        />
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          fullWidth
        />
        <FormControl
          className={classes.margin}
          variant="outlined"
          fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Create Password</InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={130}
          />
          <FormHelperText id="standard-adornment-helper-text">Must be at least 8 characters long, with both uppercase and lowercase letters.</FormHelperText>
        </FormControl>
        <Box className={classes.authFormAction}>
          <Button size="large" variant="contained" color="primary">
            Register
          </Button>
        </Box>
      </form>
      <Box className={classes.authFormAfter}>
        <Typography>
          Already have an account? <Link href="#" onClick={preventDefault}>Sign in.</Link>
        </Typography>
      </Box>
    </Box>
  )
}