import * as React from "react";
import { InstallationCard, WiredInstallationCard } from "../card-installation/card-installation";
import { useAppState } from "../../hooks/appState";
import CardGrid from "../cardgrid/cardgrid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DevicesPageActions } from "../devices-page-actions";
import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { DeviceDrawer } from "../device-drawer";
import { useLocation, useRouteMatch, useParams } from "react-router";
import { StringParam, useQueryParam, ArrayParam } from "use-query-params";
import { gql } from "apollo-boost";
import { DeviceViewQuery, DeviceViewQueryVariables } from "./__generated__/DeviceViewQuery";
import { DeviceCard, DeviceCardDefaultInput, DeviceCardDefaultOutput } from "../device-card";
import { Box, Grid, Typography } from "@material-ui/core";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import { DEVICE_FRAGMENT } from "../../fragments";
import { history } from "../../history";
import { HashRouter } from "react-router-dom";
import { RightDrawerModal } from "../right-drawer-modal";

interface IDevicesPageProps {
  installationId?: string;
  highlightedInstallationIds?: string;
}
type DevicesPageProps = IDevicesPageProps

const DEVICE_VIEW_QUERY = gql`
  query DeviceViewQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId){
      id
      name
      views {
        devices {
          ...DeviceFragment 
        }
      }
    }
  }
  
  ${DEVICE_FRAGMENT}
`

export const PageDevices: React.FC<DevicesPageProps> = (props) => {
  const { tenantId } = useAppState()
  const [installationId, setInstallationId] = React.useState("")
  const [deviceId, setDeviceId] = React.useState("")

  const [query, { data, loading, refetch }] = useLazyQuery<DeviceViewQuery, DeviceViewQueryVariables>(
    DEVICE_VIEW_QUERY,
    {
      variables: { tenantId: tenantId || "" },
      fetchPolicy: "no-cache"
    }
  )

  React.useEffect(
    () => {
      if (tenantId) {
        query({
          variables: {
            tenantId
          }
        })
      }
    }, [tenantId]
  )

  const closeDeviceDrawer = () => {
    setInstallationId("")
  }

  if (loading || !data) return <CircularProgress />

  return (
    <LayoutWithHeaderAndBody
      tenantName={data?.tenant?.name ?? ""}
      title={"Devices"}
      contentSection={
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            {/* 
                Chris ToDo: 
                Show "all devices" and the device count when grouping set = none
               
                Allow "Group By Equipment" and then:
                - h5 should show name of equipment (i.e. "Fermenter #2")
                - # devices should show number installed in that piece of equip.
                
               */}
            <Typography variant="h5" component="h2">
              All Devices
              </Typography>
            {/* <Typography variant="body2" color="textSecondary">
                # devices
              </Typography> */}
          </Grid>
          <Grid item xs={12} md={10}>
            <CardGrid
              cards={
                data.tenant.views.devices.map(device => (
                  <DeviceCard
                    data={device}
                    onClick={() => {
                      setInstallationId(device.installation.id)
                      device.deviceId && setDeviceId(device.deviceId)
                    }}
                  />
                )) || []
              }
            />
          </Grid>
          <RightDrawerModal onClose={closeDeviceDrawer} open={!!installationId}>
            {installationId && (
              <DeviceDrawer
                onClose={closeDeviceDrawer}
                deviceId={deviceId}
                installationId={installationId}
              />
            )}
          </RightDrawerModal>
        </Grid>
      }
      actionsSection={
        <DevicesPageActions setRefresh={() => { }} />
      }
    />
  )
}
