import * as React from "react";
import clsx from 'clsx';
import { LinkProps as RouterLinkProps, NavLink, NavLink as RouterLink, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/core/styles";
import { cpThemeDark } from "../../theme/cpTheme";
import { BottomNavigation, BottomNavigationAction, Box, Divider, Hidden, IconButton, List, ListItem, useMediaQuery } from "@material-ui/core";
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { CPIcon, ECPIcon } from "../icon";
import { useTenant } from "../../hooks/tenant";
import { useAppState } from "../../hooks/appState";
import { CpLogoIcon } from "../icons-custom/cp-logo";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import { gql } from "apollo-boost";
import { UpdateExpandedSidebarMutation, UpdateExpandedSidebarMutationVariables } from "./__generated__/UpdateExpandedSidebarMutation";
import { useMutation } from "@apollo/react-hooks";
import { useNavigationProvider } from "../../providers";


interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

const openDrawerDesktopWidth = 174;
const closeDrawerDesktopWidth = 72;

const drawerContentStyles = makeStyles( theme => ({
  list: {
    width: "100%",
  },
  cpLogoSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(3, 1.25),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    opacity: '0.4',

    '& .cpLogo': {
      color: theme.palette.text.hint,
      padding: 0,
      width: `calc( ${closeDrawerDesktopWidth}px - ${theme.spacing(2.5)}px )`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: `0 0 calc( ${closeDrawerDesktopWidth}px - ${theme.spacing(2.5)}px )`,
    },
    '& .cpName': {
      display: 'inline-block',
      whiteSpace: 'normal',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '1.1',
      overflow: 'hidden',
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
}));

const desktopDrawerStyles = makeStyles( theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: openDrawerDesktopWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: openDrawerDesktopWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closeDrawerDesktopWidth,
    
    '& .listItemLabel': {
      opacity: '0',
    },
    '& .cpName': {
      opacity: '0',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },  
  widthToggle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  widthToggleButton: {
    '& .widthToggleIcon': {
      transition: 'transform 0.25s',
      opacity: '0.7',
    },
  },
  widthToggleOpen: {
    '& .widthToggleIcon': {
      transform: 'rotate(180deg)',
    },
  },
  
}));

const listItemStyles = makeStyles( theme => ({
  listItem: {
    padding: theme.spacing(0, 1.25),
  },
  listItemLink: {
    padding: 0,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    
    "&.active": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      
      "& .listItemIcon": {
        color: theme.palette.text.primary,
      },
    },
    "& .listItemIcon": {
      color: theme.palette.text.hint,
      padding: theme.spacing(1.3, 0),
      width: `calc( ${closeDrawerDesktopWidth}px - ${theme.spacing(2.5)}px )`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: `0 0 calc( ${closeDrawerDesktopWidth}px - ${theme.spacing(2.5)}px )`,
    },
    '& .listItemLabel': {
      display: 'inline-block',
      overflow: 'hidden',
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
}));

const toolTipStyles = makeStyles( theme => ({
  tooltip: {
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
}));

const mobileStyles = makeStyles( theme => ({
  mobileRoot: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    width: '100%',
    zIndex: 99,
    boxShadow: '0px -2px 6px rgba(0,0,0,0.2)'
  },
}));


function NavBarTooltip(props: TooltipProps) {
  const classes = toolTipStyles();
  const { navDesktopOpen } = useNavigationProvider();

  const theme = useTheme();
  // NavBarGlobalMobile currently hidden abover medium
  // So we hide tooltips on mobile
  const usesMobile = useMediaQuery(theme.breakpoints.up('md'));
  // console.log(`breakpoint above medium? = ${usesMobile}`)

  return <Tooltip 
    arrow 
    disableHoverListener={navDesktopOpen || !usesMobile} 
    disableTouchListener={true}
    classes={classes} 
    {...props} 
  />;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;
  const classes = listItemStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>(
        (itemProps, ref) => (
          // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
          // See https://github.com/ReactTraining/react-router/issues/6056
          <RouterLink to={to} {...itemProps} innerRef={ref} />
        ),
      ),
    [to],
  );

  return (
    <li className={classes.listItem}>
      <NavBarTooltip 
        title={primary} 
        placement="right"
      >
        <ListItem button component={renderLink} className={classes.listItemLink}>
          {icon ? <Box component="span" className="listItemIcon">{icon}</Box> : null}
          {primary ? <Box component="span" className="listItemLabel">{primary}</Box> : null}
        </ListItem>
      </NavBarTooltip>
    </li>
  );
}

interface INavItem {
  label: string
  path: string
  icon: ECPIcon
}

export const mainNavItems: { [key: string]: INavItem } = {
  "ControlPanel": {
    label: "Control Panel",
    path: "/control",
    icon: ECPIcon.Control
  },
  "Facility": {
    label: "Facility",
    path: "/facility",
    icon: ECPIcon.Facilty
  },
  "Devices": {
    label: "Devices",
    path: "/devices",
    icon: ECPIcon.Devices
  },
  "Settings": {
    label: "Settings",
    path: "/organization",
    icon: ECPIcon.Settings
  },
}

const bottomNavItems: INavItem[] = [
  { 
    label: "Your Account",
    path: "/account",
    icon: ECPIcon.Account
  }
]

export const DrawerContents: React.FC = (props) => {
  
  const drawerContentclasses = drawerContentStyles();  
  const { tenantId, viewer } = useAppState()
  const { data } = useTenant(tenantId ?? "")

  return (
    <>
      <Box
        width="100%"
        flex="1"
        overflow="scroll-y"
      >
        <Box className={drawerContentclasses.cpLogoSection} >
          <CpLogoIcon className="cpLogo" />
          <span className="cpName">Connected Proof</span>
        </Box>

        <List aria-label="nav main">
          {data?.tenant.navigation.map(navigation => {
            const item = mainNavItems[navigation]
            return (
              <ListItemLink
                to={item.path}
                primary={item.label}
                icon={<CPIcon icon={item.icon} />}
              />
            )
          })}

          <ListItemLink
            to={"/organization"}
            primary={"Settings"}
            icon={<CPIcon icon={ECPIcon.Settings} />}
          />

        </List>
      </Box>
      <Box
        width="100%"
        alignSelf="flex-end"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={0.5}
      >
        <List
          aria-label="nav global secondary"
          classes={{
            root: drawerContentclasses.list,
          }}
        >
          {viewer?.superadmin ? (
            <ListItemLink
              to={"/_superadmin"}
              primary={"Super Admin"}
              icon={<CPIcon icon={ECPIcon.Superadmin} />}
            />
          ) : null}
          {bottomNavItems.map(navItem => (
            <ListItemLink
              to={navItem.path}
              primary={navItem.label}
              icon={<CPIcon icon={navItem.icon} />}
            />
          ))}
        </List>
      </Box>
    </>
  )
}


export const UPDATE_EXPANDED_SIDEBAR_MUTATION = gql`
  mutation UpdateExpandedSidebarMutation(
    $id: String!,
    $showSidebarExpanded: Boolean!
  ) {
    updateAccount(input: {
      id: $id,
      showSidebarExpanded: { newValue: $showSidebarExpanded }
    }) {
      account {
        id
        showSidebarExpanded
      }
    }
  }
`


export const NavBarGlobal: React.FC = (props) => {
  const { navDesktopOpen, handleDesktopDrawerToggle } = useNavigationProvider();
  const desktopClasses = desktopDrawerStyles();
  
  return (
    <ThemeProvider theme={cpThemeDark}>
      {/* Desktop */}
      <Hidden smDown>
          <Drawer 
            variant="permanent"
            className={clsx(desktopClasses.drawer, {
              [desktopClasses.drawerOpen]: navDesktopOpen,
              [desktopClasses.drawerClose]: !navDesktopOpen,
            })}
            classes={{
              paper: clsx({
                [desktopClasses.drawerOpen]: navDesktopOpen,
                [desktopClasses.drawerClose]: !navDesktopOpen,
              }),
            }}
          >
            <DrawerContents/>
            
            {/* Start Bottom Width Toggle */}
            <Divider />
            <Box className={desktopClasses.widthToggle}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                size="small"
                onClick={handleDesktopDrawerToggle}
                className={clsx(desktopClasses.widthToggleButton, {
                  [desktopClasses.widthToggleOpen]: navDesktopOpen,
                })}
              >
                <ChevronRightIcon className="widthToggleIcon" />
              </IconButton>
            </Box>

          </Drawer>
        </Hidden>
    </ThemeProvider>
  )
}

export const NavBarGlobalMobile: React.FC = (props) => {
  const mobileClasses = mobileStyles();
  const { tenantId, viewer } = useAppState()
  const { data } = useTenant(tenantId ?? "")

  // Auto set mobile link value
  const [value, setValue] = React.useState("");
  const location = useLocation()
  React.useEffect(
    () => {
      setValue(location.pathname)
    }, [location.pathname]
  )

  // Toggle drawer on mobile
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <ThemeProvider theme={cpThemeDark}>

      {/* Mobile */}
      <Hidden mdUp>
        <Drawer 
          container={container}
          variant="temporary"
          anchor="bottom"
          open={mobileOpen}
          onClose={handleMobileDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerContents/>
        </Drawer>
        <BottomNavigation
          value={value}
          className={mobileClasses.mobileRoot}
        >
          {data?.tenant.navigation.map(navigation => {
            const item = mainNavItems[navigation]
            return (
              <BottomNavigationAction
                component={NavLink}
                to={item.path}
                label={item.label}
                value={item.path}
                icon={<CPIcon icon={item.icon} />}
                showLabel={true}
              />
            )
          })}
          <BottomNavigationAction
            label={"Menu"}
            icon={ <MenuIcon/> }
            showLabel={true}
            onClick={handleMobileDrawerToggle}
          />
        </BottomNavigation>
      </Hidden>
    </ThemeProvider>
  )
}