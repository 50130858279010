import * as React from "react";
import { gql } from "apollo-boost";
import { CircularProgress, Box, Table, TableHead, TableRow, TableCell, Card, Button, Modal, TextField, Select, MenuItem, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { SuperadminDevicesQuery, SuperadminDevicesQuery_devices, SuperadminDevicesQuery_tenants, SuperadminDevicesQuery_equipmentInstances, SuperadminDevicesQuery_equipment } from "./__generated__/SuperadminDevicesQuery";
import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";
import { history } from "../../history";
import { ModalDialog } from "../modal-dialog";
import { Formik, FormikProps } from "formik";
import { GqlPortType } from "../../__generated/globalTypes";
import { CreateDeviceModalTenantInstallationsQueryVariables, CreateDeviceModalTenantInstallationsQuery } from "./__generated__/CreateDeviceModalTenantInstallationsQuery";

type SuperadminDevicesProps = ISuperadminDevicesProps;

interface ISuperadminDevicesProps {

}

const SUPERADMIN_DEVICES_QUERY = gql`
  query SuperadminDevicesQuery {
    tenants {
      id
      name
    }
    equipmentInstances {
      id
      name
      equipment {
        id
        name
      }
    }
    equipment {
      id
      name
    }
    devices {
      identifier
      created
      equipmentInstance {
        id
        name
        equipment {
          id
          name
        }
      }
      topics {
        subscribes {
          name
        }
        publishes{
          name
        }
      }
      owner {
        id
        name
      }
      ports {
        position
        serial
        type
        installation {
          id
          name
          instance {
            id
            name
            equipment {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const SuperadminDevices: React.FC<SuperadminDevicesProps> = (props) => {
  const [createDeviceModalOpen, setCreateDeviceModalOpen] = React.useState(false)

  const { data, loading } =
    useQuery<SuperadminDevicesQuery>(SUPERADMIN_DEVICES_QUERY)

  const showDetail = (identifier: string) => {
    history.push(`/_superadmin/device/${identifier}`)
  }

  if (!data || loading) return <CircularProgress />

  return (
    <>
      <LayoutWithHeaderAndBody
        tenantName={"Connected Proof"}
        title={"Admin"}
        contentSection={
          <Box>
            <Box>
              <Button variant="outlined" onClick={() => setCreateDeviceModalOpen(true)}>New Device</Button>
              <Card>
                <Table>
                  <TableHead>
                    <TableCell>Identifier</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell></TableCell>
                  </TableHead>
                  {data.devices.map(device => (
                    <TableRow>
                      <TableCell>{device.identifier} </TableCell>
                      <TableCell>{device.created}</TableCell>
                      <TableCell>
                        <Button onClick={() => showDetail(device.identifier)} variant="outlined">Details</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Card>
            </Box>

          </Box>
        }
      />
      <Formik
        onSubmit={(values) => console.log(values)}
        initialValues={{}}>
        {formikProps => (
          <ModalDialog
            title={"Create a new device"}
            open={createDeviceModalOpen}
            handleClose={() => setCreateDeviceModalOpen(false)}
            handleSubmit={() => formikProps.submitForm()}
            submitText="Create"
            content={
              <CreateDeviceModalContents
                formikProps={formikProps}
                tenants={data.tenants}
                equipmentInstances={data.equipmentInstances}
                equipments={data.equipment}
              />
            }
          />
        )}
      </Formik>

    </>
  )
}

type CreateDeviceModalDeviceTypeExisting = {
  id: string;
}

type CreateDeviceModalDeviceTypeNew = {
  identifier: string;
  name: string;
}

type CreateDeviceModalDeviceType =
  CreateDeviceModalDeviceTypeNew | CreateDeviceModalDeviceTypeExisting

interface ICreateDeviceModalPort {
  type: GqlPortType | null;
  installation: string | null;
}

type CreateDeviceModalPort = ICreateDeviceModalPort

type CreateDeviceModalFormFields = {
  ports?: CreateDeviceModalPort[];
  owner?: string | null;
  deviceType?: CreateDeviceModalDeviceType | null;
}
interface ICreateDeviceModalContentsProps {
  formikProps: FormikProps<CreateDeviceModalFormFields>
  tenants: SuperadminDevicesQuery_tenants[]
  equipmentInstances: SuperadminDevicesQuery_equipmentInstances[]
  equipments: SuperadminDevicesQuery_equipment[]
}
type CreateDeviceModalContentsProps = ICreateDeviceModalContentsProps

const removeItem = (items: any[], i: number) =>
  items.slice(0, i - 1).concat(items.slice(i, items.length))

const CREATE_DEVICE_MODAL_TENANT_INSTALLATIONS_QUERY = gql`
  query CreateDeviceModalTenantInstallationsQuery(
    $tenantId: String!
  ) {
    installations(tenant: $tenantId) {
      id
      name
      instance {
        id
        name
        equipment {
          id
          name
        }
      }
    }
  }
`

const CreateDeviceModalContents: React.FC<CreateDeviceModalContentsProps> = (props) => {
  const { formikProps } = props;
  const [ports, setPorts] = React.useState<CreateDeviceModalPort[]>([])

  const { data, loading } = useQuery<CreateDeviceModalTenantInstallationsQuery, CreateDeviceModalTenantInstallationsQueryVariables>(CREATE_DEVICE_MODAL_TENANT_INSTALLATIONS_QUERY, {
    variables: {
      tenantId: formikProps.values?.owner || ""
    },
    skip: !formikProps.values.owner
  })

  const addPort = () => {
    formikProps.setFieldValue("ports", [...(formikProps.values.ports || []), { type: null }])
  }

  const removePort = (index: number) => {

  }

  return (
    <>
      <Box>
        <Select
          value={formikProps.values.owner}
          onChange={(e) => formikProps.setFieldValue("owner", e.target.value)}
        >
          {props.tenants.map(tenant => (
            <MenuItem value={tenant.id}>{tenant.name}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box>
        <Button variant="outlined" onClick={() => { addPort() }}>Add Port</Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Installation</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {formikProps.values.ports?.map((port, index) => (
            <TableRow>
              <TableCell>{index}</TableCell>
              <TableCell>{index}</TableCell>
              <TableCell>
                <Select
                  onChange={(e) => formikProps.setFieldValue(`ports.${index}.type`, e.target.value)}
                  value={port.type}
                >
                  <MenuItem value={GqlPortType.Input}>Input</MenuItem>
                  <MenuItem value={GqlPortType.Output}>Output</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={port.installation}
                  onChange={(e) => formikProps.setFieldValue(`ports.${index}.installation`, e.target.value)}>
                  {data?.installations.map(installation => (
                    <MenuItem value={installation.id}>
                      <Box>
                        <Box><Typography variant="overline">{installation.id}</Typography></Box>
                        <Box>{installation.name || "Unnamed Installation"}</Box>
                        <Box>{installation.instance.equipment.name}</Box>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => removePort(index)}>Remove</Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>

      </Box>
    </>
  )
}