import * as React from "react";
import clsx from 'clsx';
import { useAppState } from "../../hooks/appState";
import { Typography, Box, IconButton, Hidden, Card, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { Panel } from "../panel";
import { CPButton } from "../button/button-muted-primary";
import { useTenant } from "../../hooks/tenant";
import { cpTheme } from "../../theme/cpTheme";
import { InstallationCard, WiredMiniInstallationCard, MiniInstallationCard } from "../card-installation/card-installation";
import * as _ from "lodash"

//Icons
import SyncIcon from '@material-ui/icons/Sync';
import { IInstallation } from "../../models";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { FacilityInstallations, FacilityInstallationsVariables } from "./__generated__/FacilityInstallations";
import { FacilityPageQuery, FacilityPageQueryVariables, FacilityPageQuery_tenant_views_facility_installation, FacilityPageQuery_tenant_views_facility, FacilityPageQuery_tenant_views_facility_inputs, FacilityPageQuery_tenant, FacilityPageQuery_tenant_views_facility_facilityGroup } from "./__generated__/FacilityPageQuery";
import { InstallationQuery_installation } from "../card-installation/__generated__/InstallationQuery";
import { HeaderDefault } from "../header-default";
import { FacilityPageCardsForDevicesInstalledInEquipmentQueryVariables, FacilityPageCardsForDevicesInstalledInEquipmentQuery, FacilityPageCardsForDevicesInstalledInEquipmentQuery_installations } from "./__generated__/FacilityPageCardsForDevicesInstalledInEquipmentQuery";
import { RightDrawer } from "../right-drawer";
import { EquipmentDrawer } from "../equipment-drawer";
import { useQueryParam, StringParam, ArrayParam } from "use-query-params";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import { DeviceCardDefaultOutput } from "../device-card";
import { useLiveReading } from "../physical-devices/temperature-controller";
import { EquipmentControlChip } from "../equipment/equipment-controls";


export const FACILITY_PAGE_QUERY = gql`
  query FacilityPageQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      id
      name
      views {
        facility {
          title
          deviceId
          facilityGroup {
            id
            name
          }
          type {
            id
            name
          }
          installedDevices {
            installation {
              id
              name
            }
            title
            inputs {
              device {
                id
              }
              port
              installation {
                id
                name
              }
            }
          }
          installation {
            id
            name
          }
          showEquipmentSummary
          inputs {
            device {
              id
            }
            port
            installation {
              id
              readings(latest: 1) {
                id
                value
                secondsBucket
                differenceFromNow
              }
            }
          }
          outputs {
            id
          }
          diagram {
            svg
          }
        }
      }
    }
  }
`

const drawerWidth = 450;

const useStyles = makeStyles(theme => ({
  panelRoot: {
    flexGrow: 1,
    height: '100%',
    maxWidth: '100%',
    paddingRight: 0,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    paddingRight: drawerWidth,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  facilityContainer: {
    backgroundColor: theme.palette.grey[100],
    display: 'block',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
    padding: theme.spacing(0, 8, 0, 5),
  },

  //
  // Start Groups
  //
  equipmentGroup: {
    // backgroundColor: theme.palette.grey[200],
    display: 'inline-flex',
    whiteSpace: 'normal',
    flexDirection: 'row',
    height: '100% !important',
    width: 'auto',
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  equipmentGroupName: {
    // TODO: Hiding for now
    display: 'none',
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(4),
    transformOrigin: '0 0',
    transform: 'rotate(90deg)',
  },

  //
  // Start Individual Equipment
  //
  equipmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      boxShadow: theme.shadows[4],
      cursor: 'pointer',
    },
  },
  equipmentRender: {
    position: 'relative',
    // backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    flex: '0 0 80%',
    display: 'flex',
    overflow: 'hidden',
    padding: theme.spacing(2, 8, 0),
    
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 70%',
    },
    '& span': {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    '& svg': {
      alignSelf: 'flex-end',
      height: '100%',
      marginBottom: '-2px',
    },
  },
  deviceOverview: {
    position: 'absolute',
    padding: theme.spacing(2),
    top: '45%',
    left: '50%',
    marginTop: '-25%',
    marginLeft: '-25%',
    textAlign: 'center',
    width: 'auto',
  },
  equipmentInfo: {
    flex: '0 0 20%',
    width: '100%',
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(2, 2, 6),
  },
  //
  // End Individual Equipment

  cardContainer: {
    zIndex: 4,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  testCard: {
    width: '100px',
    height: '100px',
    position: 'absolute',
    backgroundColor: '#000',
  },

  batchCard: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
  }

}));

interface IMiniCardsProps {
  refresh: string;
}

interface IDelayRenderProps {
  component: React.ReactNode
  delayMs: number;
}

type DelayRenderProps = IDelayRenderProps
const DelayRender: React.FC<DelayRenderProps> = (props) => {
  const [delayedComponent, setDelayedComponent] = React.useState<React.ReactNode>(<span />)

  React.useEffect(
    () => {
      setTimeout(() => {
        setDelayedComponent(props.component)
      }, props.delayMs)
    }, []
  )

  return (
    <>
      {delayedComponent}
    </>
  )
}

interface IMiniCardProps {
  installation: FacilityPageQuery_tenant_views_facility_inputs
  onClick: () => void;
  refresh: string;
}
type MiniCardProps = IMiniCardProps

const AutoPositionedMiniCard: React.FC<MiniCardProps> = (props) => {
  const { installation } = props;

  const [positionedCard, setPositionedCard] = React.useState(<span />)
  const liveReading = useLiveReading(installation.device?.id ?? "", installation.port)

  const autoPosition = () => {
    const element = document.getElementById(`target_${installation.installation.id}`)

    if (element) {
      const position = element && element.getBoundingClientRect();

      if (position) {
        const top = (position.top - 80)
        const left = (position.left - 70)

        setPositionedCard(
          <div
            key={installation.installation.id}
            style={{ zIndex: 2, position: "absolute", top, left }}
          >
            <MiniInstallationCard
              latestReading={liveReading.currentReading || undefined}
              onClick={() => {
                props.onClick()
              }}
              loading={false}
            />
          </div>
        )
      }
    }
  }

  React.useEffect(
    () => {
      autoPosition()
      return undefined
    }, [liveReading.currentReading]
  )

  return (
    <>
      {positionedCard}
    </>
  )
}

interface IFacilityPageLocationProps {
  group: FacilityPageQuery_tenant_views_facility[]
  onSelectInstallation: (installationId: string, subInstallationId?: string) => void;
}

type FacilityPageLocationProps = IFacilityPageLocationProps

interface IDeviceOverviewCardProps {
  deviceId: string;
  actions: React.ReactNode;
  readings: number[];
  formatter: (v: number) => string;
  onClick: () => void;
}

type DeviceOverviewCardProps = IDeviceOverviewCardProps

const DeviceOverviewCard: React.FC<DeviceOverviewCardProps> = (props) => {
  const { readings } = props
  const classes = useStyles()

  const min = Math.min(...readings)
  const max = Math.max(...readings)

  const liveReading = useLiveReading(props.deviceId)

  return (
    <>
      {liveReading.currentReading && (
        <Card onClick={props.onClick} className={classes.deviceOverview}>
          <Typography variant="h5">
            <span>{fahrenheitTemperatureFormatter(liveReading.currentReading)}</span>
            {(readings.length === 1) && (<span>{fahrenheitTemperatureFormatter(liveReading.currentReading)}</span>)}
            {(readings.length > 1) && (<span>{fahrenheitTemperatureFormatter(min)} - {fahrenheitTemperatureFormatter(max)}</span>)}
          </Typography>
          {props.actions}
        </Card>
      )}
    </>
  )
}

type BatchCardProps = {}

const BatchCard: React.FC<BatchCardProps> = (props) => {
  const classes = useStyles()
  return (
    <Card className={classes.batchCard}>Test Batch Card</Card>
  )

  /* If there's no batch, show button to add: */
  /* <Button variant="outlined">Add Batch</Button> */
}

interface IFacilityPageEquipmentInstallationProps {
  installation: FacilityPageQuery_tenant_views_facility
  onSelectInstallation: (installationId: string, subInstallationId?: string) => void;
}

type FacilityPageEquipmentInstallationProps = IFacilityPageEquipmentInstallationProps

const FacilityPageEquipmentInstallation: React.FC<FacilityPageEquipmentInstallationProps> = (props) => {
  const classes = useStyles()
  const { installation } = props

  const liveReading = useLiveReading(installation.deviceId || "")

  const readings = props.installation.inputs.map(input => input.installation.readings[0]?.value).filter(v => !!v) || []

  return (
    <Box
      className={classes.equipmentContainer}
      onClick={() => props.onSelectInstallation(installation.installation.id)}
    >
      <Box className={classes.equipmentRender}>
        {installation.diagram ? <span dangerouslySetInnerHTML={{ __html: installation.diagram?.svg }} /> : null}
        {(installation.deviceId && installation.showEquipmentSummary) && (
          <DeviceOverviewCard
            deviceId={installation.deviceId}
            actions={
              (installation.outputs.length > 0) ? (
                <DeviceCardDefaultOutput deviceId={installation.deviceId} readOnly mini {...liveReading} />
              ) : <span />
            }
            onClick={() => props.onSelectInstallation(installation.installation.id)}
            readings={readings}
            formatter={fahrenheitTemperatureFormatter}
          />
        )}
      </Box>
      <Box className={classes.equipmentInfo}>
        <Typography variant="h6" gutterBottom>
          {installation.title}
        </Typography>
        <EquipmentControlChip deviceId={installation.deviceId || ""}></EquipmentControlChip>
        {false && <BatchCard />}
      </Box>
      <DelayRender
        delayMs={200}
        component={
          <>
            {installation.inputs.map(input => (
              <AutoPositionedMiniCard
                installation={input}
                onClick={() => props.onSelectInstallation(installation.installation.id, input.installation.id)}
                refresh={""}
              />
            ))}
          </>
        }
      />
    </Box>
  )
}

const FacilityPageLocation: React.FC<FacilityPageLocationProps> = (props) => {
  const classes = useStyles()
  const { group } = props

  return (
    <Box className={classes.equipmentGroup}>
      {group.map(installation => (
        <FacilityPageEquipmentInstallation
          installation={installation}
          onSelectInstallation={props.onSelectInstallation}
        />
      ))}
      <span className={classes.equipmentGroupName}>{group[0]?.facilityGroup?.name || ""}</span>
    </Box>
  )
}

export const FacilityPage: React.FC = (props) => {
  const { tenantId } = useAppState()
  const [installationId, setInstallationId] = React.useState("")
  const [deviceId, setDeviceId] = React.useState("")

  const { data, loading } = useQuery<FacilityPageQuery, FacilityPageQueryVariables>(
    FACILITY_PAGE_QUERY,
    {
      variables: {
        tenantId: tenantId ?? ""
      },
      fetchPolicy: "no-cache"
    }
  )

  const [refresh, setRefresh] = React.useState("")
  const classes = useStyles();

  const groups = _.groupBy(
    data?.tenant.views.facility
      .filter(installation => !!installation.diagram?.svg),
    (installation) => installation.facilityGroup?.id || ""
  )

  const closeDrawer = () => {
    setInstallationId("")
  }

  const selectInstallation = (id: string, subId?: string) => {
    setInstallationId(id)
  }

  return (
    <>
      <Box
        className={clsx(classes.panelRoot, {
          [classes.contentShift]: !!installationId,
        })}
      >
        <Panel
          variant="overflowX"
          header={
            <HeaderDefault
              tenantName={data?.tenant?.name ?? ""}
              title={"Facility"}
              actionsSection={
                <span />
              }
            />
          }
          body={
            <>
              <Box className={classes.facilityContainer}>
                {loading && <CircularProgress />}
                {Object.values(groups).map(group => (
                  <FacilityPageLocation
                    group={group}
                    onSelectInstallation={selectInstallation}
                  />
                ))}
              </Box>
            </>
          }
        />
      </Box>
      <RightDrawer
        onClose={closeDrawer}
        open={!!installationId}>
        {installationId && (
          <EquipmentDrawer
            onClose={closeDrawer}
            deviceId={deviceId}
            installationId={installationId}
          />
        )}
      </RightDrawer>
    </>
  )
};