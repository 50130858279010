import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom"
import { useAppState } from "./hooks/appState";
import { useAuth } from "react-use-auth";
import { useAccount } from "./hooks/account";
import { FacilityPage } from "./components/page-facility/facility";
import { PageOrganizationSettings } from "./components/page-organization-settings";
import { DesignTestPage } from "./components/_page-static-designs/design-test";
import { NotFoundPage } from "./components/_page-not-found/not-found";
import { PageUserAccount } from "./components/page-user-account/settings-user";
import { AdminTenantPage } from "./components/admin-cp/admin-cp";
import { ControlPage } from "./components/page-control/control-page";
import { EquipmentTestPage } from "./components/_page-static-designs/equip-test";
import { RouteWithAppLayout } from "./components/route-with-app-layout";
import { RouteWithAuthenticationLayout } from "./components/route-with-authentication-layout";
import { PageLogin } from "./components/page-login";
import { PageForgotPassword } from "./components/page-forgot-password";
import { PageRegister } from "./components/page-register";
import { PageDevices } from "./components/page-devices";
import { RouteProps } from "./components/route";
import { PageAdminTenant } from "./components/page-admin-tenant";
import { RightSideSheet } from "./components/right-side-sheet";
import { PageResetPassword } from "./components/page-reset-password";
import { BeltlineDevicesView } from "./components/beltline-devices-view";
import { BeltlineFacilityView } from "./components/beltline-facility-view";
import { BeltlineEquipmentDrawerContents } from "./components/beltline-equipment-drawer-contents";
import { BeltlineDeviceSettingsDrawerContents } from "./components/beltline-device-settings-drawer-contents";
import { Superadmin } from "./components/superadmin";
import { SuperadminDevices } from "./components/superadmin-devices";
import { SuperadminDeviceDetail } from "./components/superadmin-device-detail";
import { TemperatureController } from "./components/physical-devices/temperature-controller";
import { NavBarGlobal } from "./components/nav-global/NavBarGlobal";
import { Box } from "@material-ui/core";


interface IRoute {
  path: string;
  layout: React.FC<RouteProps>;
  component: React.Component
}

export const DEVICES_PATH_PATTERN = "devices/:installationId?/:highlighted?"

export const AppRouter: React.FC = (props) => {
  const { setTenantId, setLocationId, tenantId, appRoute } = useAppState()

  const { loading: accountLoading, account } = useAccount()

  React.useEffect(() => {
    if (!tenantId && account && account.tenants[0]) {
      setTenantId(account.tenants[0].id)
    }
  }, [account, tenantId])

  const authRoutes = [
    {
      path: "login",
      component: PageLogin
    },
    {
      path: "forgot-password",
      component: PageForgotPassword
    },
    {
      path: "reset-password",
      component: PageResetPassword
    },
    {
      path: "register",
      component: PageRegister
    }
  ]


  const appRoutes = [
    {
      path: "devices",
      component: PageDevices
    },
    {
      path: "control",
      component: ControlPage
    },
    {
      path: "facility/:installationId?/:tab?",
      component: FacilityPage
    },
    {
      path: "organization",
      component: PageOrganizationSettings
    },
    {
      path: "account",
      component: PageUserAccount
    },
    {
      path: "_superadmin/tenant/:id",
      component: PageAdminTenant
    },
    {
      path: "_superadmin/tenant",
      component: AdminTenantPage
    },
    {
      path: "_superadmin/device/:identifier",
      component: SuperadminDeviceDetail
    },
    {
      path: "_superadmin/device",
      component: SuperadminDevices
    },
    {
      path: "_superadmin",
      component: Superadmin
    },
    {
      path: "equiptest",
      component: EquipmentTestPage
    },
    {
      path: "designtest",
      component: DesignTestPage
    },
    {
      path: "scaffolding/beltline/devices-view",
      component: BeltlineDevicesView
    },
    {
      path: "scaffolding/beltline/facility-view",
      component: BeltlineFacilityView
    },
    {
      path: "scaffolding/beltline/equipment-drawer-contents",
      component: BeltlineEquipmentDrawerContents
    },
    {
      path: "scaffolding/beltline/device-settings-drawer-contents",
      component: BeltlineDeviceSettingsDrawerContents
    }
  ]

  if (process.env.REACT_APP_IS_TOUCH === "true") {
    return (
      <>
        <Route path="/" component={TemperatureController} />
      </>
    )
  } else {
    return (
      <Box display="flex">
        { appRoute && <RightSideSheet /> }
        { appRoute && <NavBarGlobal/> }

        <Switch>
          {authRoutes.map(route =>
            <RouteWithAuthenticationLayout exact path={`/${route.path}`} component={route.component} />
          )}
          {appRoutes.map(route =>
            <RouteWithAppLayout key={route.path} path={`/${route.path}`} component={route.component} />
          )}
          <Route path="/temperatureController/:id" component={TemperatureController} />
          <Redirect from={"/"} to={"/devices"} />
          <Route component={NotFoundPage} />
        </Switch>
      </Box>
    )
  }
}
