import * as React from "react";
import { Box, Toolbar, Typography, Hidden, IconButton, Container, makeStyles, CircularProgress, Button } from "@material-ui/core";
import { DeviceSettingsDrawer } from "../beltline-device-settings-drawer-contents/device-settings-drawer";
import { Panel } from "../panel";
import { ArrowBack, CloseOutlined, SettingsOutlined, ArrowBackOutlined } from "@material-ui/icons";
import { cpTheme, cpVars } from '../../theme/cpTheme';
import { DeviceDrawerMainContents } from "../device-drawer-main-contents";
import { DeviceSettingsDrawerContents } from "../device-settings-drawer-contents";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { PanelWithSettingsAndBack } from "../panel-with-settings-and-back";
import { Formik, FormikConfig, FormikProps } from "formik";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { DeviceDrawerMainQuery, DeviceDrawerMainQueryVariables } from "./__generated__/DeviceDrawerMainQuery";
import { DeviceDrawerMutation, DeviceDrawerMutationVariables } from "./__generated__/DeviceDrawerMutation";

const DEVICE_DRAWER_MUTATION = gql`
  mutation DeviceDrawerMutation(
    $installationId: String!
    $title: String!
    $interfaceInstanceNameUpdates: [InterfaceInstanceNameUpdateInput!]!
  ) {
    updateDevicesViewInstallation(input: {
      installation: $installationId,
      title: $title,
      interfaceInstanceNameUpdates: $interfaceInstanceNameUpdates
    }) {
      installation {
        id
        name
      }
      equipmentInterfaceInstances {
        id
        name
      }
    }
  }
`

const DEVICE_DRAWER_MAIN_QUERY = gql`
query DeviceDrawerMainQuery(
  $installationId: String!
) {
  outputEffects {
    id
    name
  }
  outputTypes {
    id
    name
  }
  outputStates {
    id
    name
  }
  installation(id: $installationId) {
    id
    name
    outputType {
      id
      name
    }
    outputEffect {
      id
      name
    }
    devicesView {
      title
      output {
        installation {
          id
        }
        device {
          id
        }
        port
      }
      inputs {
        device {
          id
        }
        port
        installation {
          id
          name
        }
      }
    }
    instance {
      id
      name
      interfaces {
        __typename
        id
        name
        installationTarget {
          ... on EquipmentInterfaceInstanceInstallationTarget {
            installations {
              id
              name
            }
          } 
        }
        equipmentInterface {
          __typename
          id
          name
          ...on EquipmentOutputInterface {
            defaultOutputEffect {
              id
              name
            }
            defaultOutputType {
              id
              name
            }
            defaultFailsafeStandbyState {
              id
              name
            }
          }
        }
      }
      equipment {
        id
        name
        manufacturer {
          id
          name
        }
      }
    }
    installedTo {
      id
      __typename
      ...on LocationInstallationTarget {
        location {
          id
        }
      }
      ...on EquipmentInterfaceInstanceInstallationTarget {
        id
        equipmentInterfaceInstance {
          id
          name
          equipmentInstance {
            id
            name
            installations {
              id
            }
            equipment {
              id
              name
            }
          }
          equipmentInterface {
            __typename
            id
            name
          }
          id
        }
      }
    }
  }
}
`;

type DeviceDrawerProps = IDeviceDrawerProps;

interface IDeviceDrawerProps {
  deviceId: string
  installationId: string
  onClose?: () => void;
}

interface IDeviceForm {
  title: string;
  interfaceInstanceNames: { [key: string]: string }
}

export type DeviceForm = IDeviceForm

export const DeviceDrawer: React.FC<DeviceDrawerProps> = (props) => {
  const { data, loading } = useQuery<DeviceDrawerMainQuery, DeviceDrawerMainQueryVariables>(DEVICE_DRAWER_MAIN_QUERY,
    {
      variables: {
        installationId: props.installationId
      },
      fetchPolicy: "no-cache"
    })



  const [mutate, { data: mutationData, loading: mutationLoading }] =
    useMutation<DeviceDrawerMutation, DeviceDrawerMutationVariables>(DEVICE_DRAWER_MUTATION)


  const [saving, setSaving] = React.useState(false)

  if (!data || loading) return <CircularProgress />

  const save = async (values: DeviceForm) => {
    setSaving(true)
    await mutate({
      variables: {
        installationId: data.installation.id,
        title: values.title,
        interfaceInstanceNameUpdates: Object
          .keys(values.interfaceInstanceNames)
          .map(update => ({
            id: update,
            newName: values.interfaceInstanceNames[update] || ""
          }))
      }
    })
    setSaving(false)
  }

  const formikConfig: FormikConfig<DeviceForm> = {
    initialValues: {
      title: data.installation.devicesView?.title || "",
      interfaceInstanceNames: (data.installation.instance
        .interfaces.reduce((acc, iface) => ({
          ...acc,
          [iface.id]: iface.name
        }), {})
      )
    },
    onSubmit: (values) => {
      save(values)
    }
  }

  const onBack = (formik: FormikProps<DeviceForm>) => {
    formik.resetForm()
  }

  return (
    <Formik {...formikConfig}>
      {formikProps => (
        <PanelWithSettingsAndBack
          mainContents={<DeviceDrawerMainContents {...{ ...props, data, formikProps }} />}
          settingsContents={<DeviceSettingsDrawerContents {...{ ...props, data, formikProps }} />}
          settingsActions={
            <Button
              color="primary"
              disabled={saving}
              onClick={() => formikProps.submitForm()}
            >
              {saving && <CircularProgress />}
              {!saving && <span>Save</span>}
            </Button>
          }
          onClose={props.onClose}
          onBack={() => onBack(formikProps)}
          title={"Device"}
        />
      )}
    </Formik>
  )
}