import * as React from "react";
import { useMutation } from "@apollo/react-hooks";
import { ModalDialog } from "../modal-dialog";
import { DialogContentText, TextField, FormControl, InputLabel, Select, MenuItem, Box, Button } from "@material-ui/core";
import { gql } from "apollo-boost";
import { AccountTenantAssociationRoleSelector } from "../account-tenant-association-role-selector";
import { AccountTenantRoleType } from "../../__generated/globalTypes";

type InviteNewUserDialogProps = IInviteNewUserDialogProps;

interface IInviteNewUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

// const MUTATION = gql`
//   mutation CreateAndInviteNewUser(
//     $firstName: String!,
//     $lastName: String!,
//     $emailAddress: String!,
//     $tenantId: String!
//   ) {

//   }
// `

export const InviteNewUserDialog: React.FC<InviteNewUserDialogProps> = (props) => {
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [emailAddress, setEmailAddress] = React.useState("")
  const [accountTenantAssociationRole, setAccountTenantAssociationRole] = React.useState<AccountTenantRoleType | null>()

  const submit = async () => {
    props.onSubmit()
    props.onClose()
  }

  return (
    <ModalDialog
      title={"Invite a new user"}
      open={props.open}
      handleClose={props.onClose}
      handleSubmit={submit}
      submitText="Invite"
      content={
        <>
          <Box>
            <TextField
              id="first-name"
              label="First name"
              placeholder=""
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              id="last-name"
              label="Last name"
              placeholder=""
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              id="email-address"
              label="Email address"
              placeholder=""
              variant="outlined"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </Box>
          <Box>
            <AccountTenantAssociationRoleSelector
              value={accountTenantAssociationRole}
              onChange={setAccountTenantAssociationRole}
            />
          </Box>
          <Box>
            <Button />
          </Box>
        </>
      }
    />
  )
}