import * as React from "react";
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function CpLogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
        <path d="M2.95137 2.08334C2.47195 2.08334 2.08331 2.47199 2.08331 2.9514V9.89584C2.08331 10.3753 2.47195 10.7639 2.95137 10.7639H9.69913L15.0212 16.086C14.5265 16.7909 14.2361 17.6498 14.2361 18.5764C14.2361 20.9735 16.1793 22.9167 18.5764 22.9167C20.9734 22.9167 22.9166 20.9735 22.9166 18.5764C22.9166 16.1793 20.9734 14.2361 18.5764 14.2361C17.7059 14.2361 16.8952 14.4924 16.2157 14.9336L10.7639 9.48174V2.9514C10.7639 2.47199 10.3752 2.08334 9.89581 2.08334H2.95137Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5764 2.08334C16.1793 2.08334 14.2361 4.02655 14.2361 6.42362C14.2361 8.82069 16.1793 10.7639 18.5764 10.7639C20.9734 10.7639 22.9166 8.82069 22.9166 6.42362C22.9166 4.02655 20.9734 2.08334 18.5764 2.08334ZM15.9722 6.42362C15.9722 7.86186 17.1381 9.02779 18.5764 9.02779C20.0146 9.02779 21.1805 7.86186 21.1805 6.42362C21.1805 4.98538 20.0146 3.81945 18.5764 3.81945C17.1381 3.81945 15.9722 4.98538 15.9722 6.42362Z"/>
    </SvgIcon>
  )
};