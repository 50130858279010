import * as React from "react";
import { Drawer, makeStyles } from "@material-ui/core";

type RightDrawerProps = IRightDrawerProps;

interface IRightDrawerProps {
  open: boolean;
  onClose: () => void;
}

const drawerWidth = 450;

const useStyles = makeStyles( theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    maxWidth: "100vw",
    width: drawerWidth,
    boxShadow: theme.shadows[4],
    
    [theme.breakpoints.down('xs')]: {
      width: "100vw",
    },
  },
})); 

export const RightDrawer: React.FC<RightDrawerProps> = (props) => {
  const classes = useStyles()

  return (
    <Drawer
      className={classes.drawer}
      open={props.open}
      anchor="right"
      variant="persistent"
      onClose={() => props.onClose()}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {props.children}
    </Drawer>
  )
};