import * as React from "react";
import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";
import { useParams } from "react-router";
import { useTenant } from "../../hooks/tenant";
import { CircularProgress, Tabs, Tab } from "@material-ui/core";
import { AdminTenantInstallationsTable } from "../admin-tenant-installations-table";
import { AdminTenantAccountsTable } from "../admin-tenant-accounts-table";
import { AdminTenantReadingsTable } from "../admin-tenant-readings-table";
import { AdminTenantBasicInformation } from "../admin-tenant-basic-information";
import { AdminTenantViews } from "../admin-tenant-views";
import { AdminTenantDynamoLookupTable } from "../admin-tenant-dynamo-lookup-table";

type PageAdminTenantProps = IPageAdminTenantProps;

interface IPageAdminTenantProps {

}

export const PageAdminTenant: React.FC<PageAdminTenantProps> = (props) => {
  const { id } = useParams()
  const { data, loading } = useTenant(id ?? "")

  const [tabShown, setTabShown] = React.useState(0)

  if (loading || !data) {
    return <CircularProgress />
  }

  const sectionProps = {
    tenantId: id ?? ""
  }

  const tabContent: { [key: number]: React.ReactNode } = {
    0: (
      <div>
        <h3>Basic Information</h3>
        <AdminTenantBasicInformation {...sectionProps} />
      </div>
    ),
    1: (
      <div>
        <AdminTenantInstallationsTable {...sectionProps} />
      </div>
    ),
    2: (
      <div>
        <h3>Accounts</h3>
        <AdminTenantAccountsTable {...sectionProps} />
      </div>
    ),
    3: (

      <div>
        <AdminTenantViews {...sectionProps} />
      </div>
    ),
    4: (
      <div>
        <h3>Troubleshooting</h3>
      </div>
    ),
    5: (
      <div>
        <h3>Dynamo Lookups</h3>
        <AdminTenantDynamoLookupTable {...sectionProps} />
      </div>
    ),
    6: (
      <div>
        <h3>Simulators</h3>
        <>
          <iframe width="320" height="240" src="/temperatureController/287C6764-25F7-4838-845E-2A073AD76B5D"></iframe>
          <iframe width="320" height="240" src="/temperatureController/BEBF540E-6E05-41AC-9734-B6BA34B0BB24"></iframe>
          <iframe width="320" height="240" src="/temperatureController/42E89075-AD89-459A-8837-2D22DCB175CC"></iframe>
          <iframe width="320" height="240" src="/temperatureController/A36DAC57-C369-4456-81ED-0E4FEAD5352B"></iframe>
          <iframe width="320" height="240" src="/temperatureController/F0606441-6FA5-4DFA-807D-3F54AB6779D4"></iframe>
        </>
      </div>
    )
  }

  return (
    <LayoutWithHeaderAndBody
      tenantName={data.tenant.name}
      title={"Admin"}
      contentSection={
        <>
          <Tabs value={tabShown} onChange={(event, index) => setTabShown(index)}>
            <Tab label="Basic Information" />
            <Tab label="Installations" />
            <Tab label="Accounts" />
            <Tab label="Views" />
            <Tab label="Troubleshooting" />
            <Tab label="Dynamo Lookups" />
            <Tab label="Simulators" />
          </Tabs>
          {tabContent[tabShown]}
        </>
      }
    />
  )
}
