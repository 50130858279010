import { gql } from "apollo-boost";

export const INSTALLATION_FRAGMENT = gql`
fragment InstallationFragment on Installation {
  id
  created
  name
  installedTo {
    ...on EquipmentInterfaceInstanceInstallationTarget {
      id
      equipmentInterfaceInstance {
        id
        name
        equipmentInterface {
          __typename
          id
          name
          ...on EquipmentOutputInterface {
            defaultOutputType {
              id
              name
            }
            defaultOutputEffect {
              id
              name
            }
            defaultFailsafeStandbyState {
              id
              name
            }
          }
        }
        equipmentInstance {
          id
          name
          equipment {
            id
            name
          }
          interfaces {
            id
            installationTarget {
              ...on EquipmentInterfaceInstanceInstallationTarget {
                id
                installations {
                  readings(latest: 1) {
                    id
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  readings(latest: 1) {
    observed
    value
  }
  instance {
    id
    name
    equipment {
      id
      name
      graphicalDiagramSvg
    }
  }
}
`

export const DEVICE_FRAGMENT = gql`
  fragment DeviceFragment on TenantDevicesViewInstallation {
    title
    deviceId
    inputs {
      device {
        id
      }
      port
      installation {
        id
        name
        instance {
          equipment {
            name
          }
        }
        readings(latest: 1) {
          id
          created
          value
        }
      }
    }
    output {
      installation {
        id
      }
    }
    installation {
      id
      name
      installedTo {
        id
        __typename
        ...on LocationInstallationTarget {
          location {
            id
          }
        }
        ...on EquipmentInterfaceInstanceInstallationTarget {
          id
          equipmentInterfaceInstance {
            id
            name
            equipmentInstance {
              id
              name
              installations {
                id
              }
              equipment {
                id
                name
              }
            }
            equipmentInterface {
              __typename
              id
              name
            }
            id
          }
        }
      }
      instance {
        id
        equipment {
          id
          name
          graphicalDiagramSvg
        }
      }
    }
  }
`
