import * as React from 'react';
import PermScanWifiOutlinedIcon from '@material-ui/icons/PermScanWifiOutlined';
import SignalWifi0BarOutlinedIcon from '@material-ui/icons/SignalWifi0BarOutlined';
import SignalWifi1BarOutlinedIcon from '@material-ui/icons/SignalWifi1BarOutlined';
import SignalWifi2BarOutlinedIcon from '@material-ui/icons/SignalWifi2BarOutlined';
import SignalWifi3BarOutlinedIcon from '@material-ui/icons/SignalWifi3BarOutlined';
import SignalWifi4BarOutlinedIcon from '@material-ui/icons/SignalWifi4BarOutlined';

export enum WirelessStrength {
  Low,
  Medium,
  High
}

export interface IWirelessIndicatorProps {
  strength: WirelessStrength
}

export function WirelessIndicator(props: IWirelessIndicatorProps) {
  return (
    <SignalWifi3BarOutlinedIcon />
  );
}
