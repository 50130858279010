import * as React from "react";
import { Typography, Divider, Paper, Box, makeStyles, CircularProgress, Table, TableRow, TableCell, Checkbox, Card, CardActionArea, CardContent, Link } from "@material-ui/core";
import { EquipmentControls } from "../equipment/equipment-controls";
import { ChartDefault } from "../charts/chart-default";
import { cpTheme, cpVars, getGraphColor } from "../../theme/cpTheme";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import { history } from "../../history";
import { IChartLegendSeries } from "../equipment-drawer-main-contents";
import { EquipmentDrawerMainContentsWithReadingsQuery, EquipmentDrawerMainContentsWithReadingsQueryVariables } from "../equipment-drawer-main-contents/__generated__/EquipmentDrawerMainContentsWithReadingsQuery";
import _ from "lodash";
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Line } from "recharts";
import { DeviceDrawerWithReadingsMainQueryVariables, DeviceDrawerWithReadingsMainQuery } from "./__generated__/DeviceDrawerWithReadingsMainQuery";
import CallMadeIcon from '@material-ui/icons/CallMade';
import { LineGraph, TimeSeriesDataPoint } from "../line-graph";
import { ChartLegend } from "../chart-legend";
import { useLiveReading } from "../physical-devices/temperature-controller";
import { DeviceDrawerMainQuery } from "../device-drawer/__generated__/DeviceDrawerMainQuery";
import { SensorReadings, SensorReadingRow } from "../sensor-readings";

type DeviceDrawerMainContentsProps = IDeviceDrawerMainContentsProps;

interface IDeviceDrawerMainContentsProps {
  data: DeviceDrawerMainQuery;
  deviceId: string;
}

const useStyles = makeStyles(theme => ({
  // Chart Styles
  chartControls: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
  },
  legendItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  legendItemName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  legendItemValue: {
    paddingLeft: theme.spacing(4),
  },

  // Drawer Styles
  deviceInfo: {
    paddingBottom: theme.spacing(2),
  },
  equipmentInstall: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '& > .MuiLink-root': {
      color: theme.palette.text.primary,
    }
  },
  allControls: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },


}));

const DEVICE_DRAWER_WITH_READINGS_MAIN_QUERY = gql`
query DeviceDrawerWithReadingsMainQuery(
  $installationId: String!
) {
  installation(id: $installationId) {
    id
    name
    outputType {
      id
      name
    }
    outputEffect {
      id
      name
    }
    devicesView {
      title
      output {
        installation {
          id
        }
      }
      inputs {
        device {
          id
        }
        port
        installation {
          name
          id
          readings {
            id
            created
            value
            secondsBucket
          }
        }
      }
    }
  }
}
`

interface IDeviceDrawerMainContentsChartProps {
  installationId: string;
}

type DeviceDrawerMainContentsChartProps =
  IDeviceDrawerMainContentsChartProps &
  DeviceDrawerMainContentsProps

const DeviceDrawerMainContentsChart: React.FC<DeviceDrawerMainContentsChartProps> = (props) => {
  const { installationId } = props;

  const [chartData, setChartData] = React.useState<TimeSeriesDataPoint[]>()
  const [series, setSeries] = React.useState<IChartLegendSeries[]>()

  const { data, loading } = useQuery<DeviceDrawerWithReadingsMainQuery, DeviceDrawerWithReadingsMainQueryVariables>(
    DEVICE_DRAWER_WITH_READINGS_MAIN_QUERY, {
    variables: {
      installationId
    }
  })

  React.useEffect(
    () => {
      if (!data) return;

      setSeries(
        data.installation.devicesView?.inputs?.map((input, index) => ({
          key: input.installation.id,
          color: getGraphColor(index),
          title: `${input.installation.name}`,
          currentValue: 0,
          formatter: fahrenheitTemperatureFormatter
        })) || []
      )

      return undefined;
    }, [data]
  )

  if (!data || loading || !series || !chartData) return <CircularProgress />

  return (
    <Box>
      <LineGraph series={series} data={chartData} />
    </Box>
  )
}

export const DeviceDrawerMainContents: React.FC<DeviceDrawerMainContentsProps> = (props) => {
  const classes = useStyles();
  const { data } = props

  const device = useLiveReading(props.deviceId)
  const names = props.data.installation.devicesView?.inputs.map(i => i.installation?.name ?? "Unnamed Device")

  let installedInto = null
  let installedIntoId = ""

  switch (data.installation.installedTo.__typename) {
    case "EquipmentInterfaceInstanceInstallationTarget":
      installedInto = <span>{data.installation.installedTo.equipmentInterfaceInstance.equipmentInstance.name || data.installation.installedTo.equipmentInterfaceInstance.equipmentInstance.equipment.name}</span>
      installedIntoId = data.installation.installedTo.equipmentInterfaceInstance.equipmentInstance.installations[0].id
      break;
    case "LocationInstallationTarget":
      installedInto = <span />
      break;
  }

  const showSensorTable = !(data.installation.devicesView?.inputs.length === 1 && !!data.installation.devicesView?.output?.device)

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="body2" display="block" color="textSecondary">
          {data.installation.instance.equipment.name}
        </Typography>
      </Box>

      <Box className={classes.deviceInfo}>
        <Typography variant="h5">
          {installedIntoId && (
            <span className={classes.equipmentInstall}>
              <Link onClick={() => history.push(`/facility?i=${installedIntoId}`)}>
                {installedInto || " - "}
              </Link>
              <span> / </span>
            </span>
          )}
          {props.data.installation.devicesView?.title ?? "Unnamed Device"}
        </Typography>
      </Box>


      { showSensorTable && (
        <Box pt={4}>
          <SensorReadings>
            {data.installation.devicesView?.inputs.map(input => (
              <>
                {(input.device?.id && input.port) && (
                  <SensorReadingRow
                    title={input.installation.name || "Unnamed sensor"}
                    deviceId={input.device.id}
                    port={input.port}
                  ></SensorReadingRow>
                )}
              </>
            ))}
          </SensorReadings>
        </Box>
      )}

      <Box className={classes.allControls}>
        {((data.installation.devicesView?.output?.device) && (
          <EquipmentControls deviceId={data.installation.devicesView.output.device.id} />
        ))}
      </Box>
      {/* 
      <DeviceDrawerMainContentsChart {
        ...{
          ...props,
          installationId: data.installation.id
        }
      } /> */}
    </Box>
  )
}