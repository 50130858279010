import * as React from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { WirelessIndicator, WirelessStrength } from "../status-wifi";
import { ResponsiveContainer, LineChart, ReferenceLine, YAxis, Line } from "recharts";
import { useInstallation } from "../../hooks/installations";
import { cpVars } from "../../theme/cpTheme";
import numeral from "numeral"
import { makeStyles } from "@material-ui/styles";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { InstallationQuery, InstallationQueryVariables } from "../card-installation/__generated__/InstallationQuery";
import { DevicePageInstallationQuery, DevicePageInstallationQueryVariables } from "./__generated__/DevicePageInstallationQuery";

interface IDevicePageProps {
  deviceId: string;
}

const useStyles = makeStyles({
  deviceDrawer: {
    // minWidth: "800px",
    // maxWidth: "100%",
    // padding: theme.spacing(2),
  },
})

type DevicePageProps = IDevicePageProps

const TEMP_MARGIN = 5

const DEVICE_PAGE_INSTALLATION_QUERY = gql`
  query DevicePageInstallationQuery($installationId: String!) {
    installation(id: $installationId) {
      id
      created
      name
      readings {
        observed
        value
      }
      instance {
        id
        equipment {
          id
          name
        }
      }
    }
  }
`

export const fahrenheitTemperatureFormatter = (temp: number) => `${numeral(temp).format("0.0")}°`

export const fahrenheitTemperatureFormatterWithUnit = (temp: number) => `${numeral(temp).format("0.0")}°F`

export const fahrenheitTemperatureFormatterSetpoint = (temp: number) => `${numeral(temp).format("0")}`

function groupBy(xs: any, f: any) {
  return xs.reduce((r: any, v: any, i: any, a: any, k: any = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export const DevicePage: React.FC<DevicePageProps> = (props) => {
  const [refetching, setRefetching] = React.useState(false)

  const { data, loading, refetch } = useQuery<DevicePageInstallationQuery, DevicePageInstallationQueryVariables>(DEVICE_PAGE_INSTALLATION_QUERY, {
    variables: {
      installationId: props.deviceId
    },
    fetchPolicy: "no-cache"
  })

  if (loading || !data) return <CircularProgress />

  const latestReading = data.installation.readings[data.installation.readings.length - 1]?.value
  const readings = data.installation.readings.map(r => r.value)

  const wirelessStrength = WirelessStrength.Medium
  const average = readings ? readings[0] : 0

  const minTemp = readings ? (Math.min(...readings)) : 0
  const maxTemp = readings ? (Math.max(...readings)) : 0

  return (
    <>
      <Typography variant="h6" component="h2">
        {data.installation.name}
      </Typography>

      <Box style={{ minHeight: "50px" }}>
        {!loading && (
          <Typography variant="h5" component="p" color="primary"><b>{latestReading ?
            <span>{fahrenheitTemperatureFormatter(latestReading)}</span> : <span />}</b></Typography>
        )}
        {loading && <CircularProgress />}
      </Box>

      <div className={loading ? `tc center` : ""} style={{ minHeight: "130px", minWidth: "100%" }}>
        {!loading && (
          <ResponsiveContainer width="100%" height={130}>
            <LineChart
              margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
              data={readings?.map((r: any) => ({ reading: r }))}
              width={400}
              height={130}
            >
              <ReferenceLine
                strokeDasharray="3 3"
                y={minTemp - TEMP_MARGIN}
                stroke="#9F9F9F"
              />
              <ReferenceLine
                strokeDasharray="3 3"
                y={maxTemp + TEMP_MARGIN}
                stroke="#9F9F9F"
              />
              <ReferenceLine
                strokeDasharray="3 3"
                y={average}
                stroke="#9F9F9F"
              />
              <YAxis
                domain={[`dataMin - ${TEMP_MARGIN / 5}`, `dataMax + ${TEMP_MARGIN / 5}`]}
                tickSize={0}
                ticks={[minTemp - TEMP_MARGIN, average, maxTemp + TEMP_MARGIN]}
                tickMargin={10}
                axisLine={false}
                orientation={"right"}
                tickFormatter={fahrenheitTemperatureFormatter}
              />
              <Line
                dot={false}
                type="monotone"
                dataKey="reading"
                strokeWidth={3}
                stroke={cpVars.paletteBrandPrimary}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  )
};