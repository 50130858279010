import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    modeCool: Palette['primary'];
    modeHeat: Palette['primary'];
    bgScreen: Palette['primary'];
  }
  interface PaletteOptions {
    modeCool: PaletteOptions['primary'];
    modeHeat: PaletteOptions['primary'];
    bgScreen: PaletteOptions['primary'];
  }
}


const muiTheme = createMuiTheme();

const graphSeriesColors = [
  'green',
  'cyan',
  'blue',
  'purple',
  'red',
  'orange',
  'gold'
]

export const cpVars = {
  drawerWidth: '70px',
  // colors
  paletteBrandPrimary: '#0FA041',
  paletteBrandBright: '#00D179',
  paletteModeCool: '#029DCE',
  // spacing
  guttersMdUp: muiTheme.spacing(4),
  // type
  defaultLineHeight: '1.1',
  graphSeriesColors
}

export const getGraphColor = (index: number) => cpVars.graphSeriesColors[(index % cpVars.graphSeriesColors.length)]

export const cpTheme = responsiveFontSizes(createMuiTheme({

  typography: {
    htmlFontSize: 10,
    fontFamily: '"Barlow", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 9,
    fontWeightRegular: 500,
    h1: {
      fontWeight: 700,
      lineHeight: cpVars.defaultLineHeight,
    },
    h2: {
      fontWeight: 700,
      lineHeight: cpVars.defaultLineHeight,
    },
    h3: {
      fontWeight: 700,
      lineHeight: cpVars.defaultLineHeight,
    },
    h4: {
      fontWeight: 700,
      lineHeight: cpVars.defaultLineHeight,
    },
    h5: {
      fontWeight: 700,
      lineHeight: cpVars.defaultLineHeight,
    },
    h6: {
      // Font size further altered in Override section below
      fontSize: '1.2rem',
      fontWeight: 700,
      lineHeight: cpVars.defaultLineHeight,
    },
    overline: {
      lineHeight: 1,
      letterSpacing: '0.04rem',
    },
    subtitle1: {
      // fontSize: '1.8rem',
      lineHeight: cpVars.defaultLineHeight,
    },
    subtitle2: {
      // This font size sets the reference multiple
      // but it's resized with the responsiveFontSizes function
      // it's broken at 'lg', so we override in that section below
      // fontSize: '1.5rem',
      lineHeight: cpVars.defaultLineHeight,
    },
    body1: {
      letterSpacing: '0',
    },
    body2: {
      letterSpacing: '0',
    },
    caption: {
      lineHeight: cpVars.defaultLineHeight,
    },
    button: {
      fontWeight: 700,
      letterSpacing: '0.04em',
    },
  },
  palette: {
    // 
    // new CP colors
    modeCool: {
      main: '#029DCE',
      dark: '#003444'
    },
    modeHeat: {
      main: '#E85555',
      dark: '#511616'
    },
    bgScreen: {
      main: 'rgba(242, 242, 242, 0.93)',
    },
    // End Custom CP Colors
    //
    primary: {
      light: cpVars.paletteBrandBright,
      main: cpVars.paletteBrandPrimary,
    },
    secondary: {
      main: '#2E96C2',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    divider: "rgba(0, 0, 0, 0.22)",
    contrastThreshold: 5,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 5,
  },

  //
  // Add Overrides here:
  // Listed A-Z
  // Look for the "rule name" in the CSS tables on any component API page
  overrides: {

    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
    MuiCircularProgress : {
      root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
    },
    MuiContainer: {
      root: {
        [muiTheme.breakpoints.up('md')]: {
          paddingLeft: cpVars.guttersMdUp,
          paddingRight: cpVars.guttersMdUp,
        },
      },
    },
    MuiIconButton: {
      label: {
        '& .MuiSvgIcon-root': {
          width: '0.8em',
          height: '0.8em',
        },
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      }
    },
    MuiSelect: {
      disabled: {
        cursor: 'not-allowed',
        background: 'pink',
      },
    },
    MuiTextField: {
      root: {
        margin: muiTheme.spacing(1, 0),
        maxWidth: '100%',
      }
    },
    MuiToolbar: {
      gutters: {
        [muiTheme.breakpoints.up('md')]: {
          paddingLeft: cpVars.guttersMdUp,
          paddingRight: cpVars.guttersMdUp,
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1em',
      },
      // h6: {
      //   [muiTheme.breakpoints.up('md')]: {
      //     fontSize: '1.45rem',
      //   },
      // },
      overline: {
        opacity: '0.7',
      },
      // subtitle2: {
      //   [muiTheme.breakpoints.up('lg')]: {
      //     fontSize: '1.45rem',
      //   },
      // },
      paragraph: {
        lineHeight: 1.45,
      }
    },
  },

  // Override Behaviors via props, not just styles (above)
  props: {
    // Name of the component ⚛️
    MuiButton: {
      // The default props to change
      disableElevation: true,
    },
  },
}));

export const cpThemeDark = createMuiTheme({
  ...cpTheme,
  palette: {
    type: 'dark',
    modeCool: {
      ...cpTheme.palette.modeCool
    },
    modeHeat: {
      ...cpTheme.palette.modeHeat
    },
    bgScreen: {
      main: 'rgba(56, 56, 56, 0.93)',
    },
    primary: {
      main: cpVars.paletteBrandBright,
    },
    divider: 'rgba(255, 255, 255, 0.22)',
    background: {
      paper: '#292C2E',
      default: '#1A1E20',
    },
  },
});