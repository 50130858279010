import * as React from "react";
import { Container, Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
}));

export const NotFoundPage: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Box 
    display="flex" 
    flexDirection="column" 
    overflow="hidden" 
    height="100vh"
    >

    <Box overflow="scroll" pt={4} pb={6}>
      <Container maxWidth={false}>
        <Typography variant="h1">
          404
        </Typography> 
        <Typography>
          Not found
        </Typography>         
      </Container>
    </Box>

  </Box>
  )
}