import * as React from "react";
import {Box, Typography} from "@material-ui/core";

type AuthenticationMarketingContentProps = IAuthenticationMarketingContentProps;

interface IAuthenticationMarketingContentProps {

}

export const AuthenticationMarketingContent: React.FC<AuthenticationMarketingContentProps> = (props) => {
  return (
    <>
      <Typography component="h1" variant="h4">
        Welcome to Connected Proof
      </Typography>
      <Box pt={2}>
        <Typography>
          Smart automation for craft food and beverage makers.
        </Typography>
      </Box>
    </>
  )
}