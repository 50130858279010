import * as React from 'react';

import { makeStyles, Box, Typography, IconButton, Divider, Button, Hidden, Container, Toolbar } from "@material-ui/core";
import { Panel } from '../panel';
import { EquipmentControls } from './equipment-controls';
import { cpTheme, cpVars } from '../../theme/cpTheme';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ResponsiveContainer, LineChart, ReferenceLine, YAxis, Line, AreaChart, XAxis, CartesianGrid, Area, Tooltip, ComposedChart } from 'recharts';

const useStyles = makeStyles(theme => ({
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
  equipmentDrawerActions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  controlInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'baseline',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(0, 0, 1),
  },
  chart: {
    width: '100%',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 0, 1),
  },
  controls: {
    width: '100%',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 0, 1),
  },
  automation: {
    width: '100%',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 0, 1),
  },
}));



export const EquipmentDrawerActions: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.equipmentDrawerActions}>
      {/* <IconButton>
        <AddAlertOutlinedIcon />
      </IconButton> */}
      <IconButton>
        <SyncOutlinedIcon />
      </IconButton>
      <IconButton>
        <SettingsOutlinedIcon />
      </IconButton>
    </Box>
  )
}

export const EquipmentMainDrawerContents: React.FC = (props) => {
  const classes = useStyles();

  const data = [
    {
      "name": "-10",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-9",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-8",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-7",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-6",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-5",
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "-4",
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "-3",
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "-2",
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "-1",
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "0",
      "pv": 4300,
      "amt": 2100
    },
    {
      "name": "-10",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-9",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-8",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-7",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-6",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-5",
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "-4",
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "-3",
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "-2",
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "-1",
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "0",
      "pv": 4300,
      "amt": 2100
    },
    {
      "name": "0",
      "pv": 4300,
      "amt": 2100
    },
    {
      "name": "-10",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-9",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-8",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-7",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-6",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-5",
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "-4",
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "-3",
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "-2",
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "-1",
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "0",
      "pv": 4300,
      "amt": 2100
    },
    {
      "name": "-10",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-9",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-8",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-7",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-6",
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "-5",
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "-4",
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "-3",
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "-2",
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "-1",
      "pv": 3800,
      "amt": 2500
    },
  ]

  return (
    <Box className={classes.equipmentDrawer}>
      <Panel
        header={
          <Toolbar>
            <Box>
              <Typography variant="overline" display="block">Equipment</Typography>
              <Typography variant="h6">Fermenter #2</Typography>
            </Box>
            <Box className={classes.grow} />
            <Box className={classes.actions}>
              <Hidden xsDown>
                <EquipmentDrawerActions />
                <IconButton>
                  <CloseOutlinedIcon />
                </IconButton>
              </Hidden>
            </Box>
          </Toolbar>
        }
        body={
          <>
            <Container className={classes.controlInfo}>
              <Typography> </Typography>
              <Typography variant="h3">45°</Typography>
            </Container>
            <Box className={classes.chart}>
              <ResponsiveContainer width="100%" height={330}>
                <ComposedChart data={data}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={cpVars.paletteBrandPrimary} stopOpacity={0.8} />
                      <stop offset="95%" stopColor={cpVars.paletteBrandPrimary} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    interval={5}
                    padding={{ left: 5, right: 10 }}
                  />
                  <YAxis
                    orientation={"right"}
                    axisLine={false}
                  />
                  <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                  />
                  <Tooltip
                    isAnimationActive={false}
                  />
                  {/* <Area type="monotone" dataKey="pv" stroke={cpVars.paletteBrandPrimary} fillOpacity={1} fill="url(#colorPv)" /> */}
                  <Line type="monotone" dataKey="pv" stroke={cpVars.paletteBrandPrimary} strokeWidth="2" />
                </ComposedChart>
              </ResponsiveContainer>
            </Box>

            {/* <Divider />

            <Box className={classes.automation}>
              <Typography variant="h6">Automation</Typography>
              <Typography variant="body2">Automatically perform actions based on conditions like date/time, elapsed time, temperature, and more.</Typography>
              <Button
                variant="text"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
              >
                Automation
              </Button>
            </Box> */}

          </>
        }
        footer={
          <Hidden smUp>
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
            <EquipmentDrawerActions />
          </Hidden>
        }
      />
    </Box>
  )
}
