import * as React from "react";
import {
  Box, Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel, Link,
  OutlinedInput,
  TextField,
  Typography,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { cpTheme } from "../../theme/cpTheme";
import Axios from "axios";
import { API_BASE_URL } from "../../config";
import { useAppState } from "../../hooks/appState";
import { useQueryParam, StringParam } from "use-query-params";


const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1, 0),
    },
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3, 0),
  },
  mktbox: {
    background: theme.palette.grey[200],
  },
  authFormHeader: {
    padding: theme.spacing(3, 0),
  },
  authFormAction: {
    padding: theme.spacing(3, 0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  authFormAfter: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #ccc',
    borderTopColor: theme.palette.divider,
  },
}));

interface State {
  password: string;
  showPassword: boolean;
}


type PageLoginProps = IPageLoginProps;

interface IPageLoginProps {

}

export const PageLogin: React.FC<PageLoginProps> = (props) => {
  const { setSessionIdAndToken } = useAppState()
  const [returnTo] = useQueryParam("returnTo", StringParam)
  const classes = useStyles();
  const history = useHistory()
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState("")
  const [emailAddress, _setEmailAddress] = React.useState("")
  const [password, _setPassword] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false)

  const clearError = () => setError("")

  const setPassword = (password: string) => {
    clearError()
    _setPassword(password)
  }

  const setEmailAddress = (emailAddress: string) => {
    clearError()
    _setEmailAddress(emailAddress)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const signIn = async () => {
    if (!password || !emailAddress) {
      throw Error()
    }

    setError("")

    try {
      setLoading(true)

      const response = await Axios.post(
        `${API_BASE_URL}/auth/createSession`,
        JSON.stringify({
          emailAddress,
          password
        })
      )

      setLoading(false)

      if (!!response.data.error) {
        setError("Invalid credentials")
      } else {
        setSessionIdAndToken(response.data.sessionId, response.data.rawToken)
        history.push(returnTo ?? "/")
      }
    } catch (e) {
      setLoading(false)
      setError("Invalid credentials")
    }
  }

  return (
    <Box>
      <Box className={classes.authFormHeader}>
        <Typography component="h2" variant="h6">
          Sign in to Connected Proof
        </Typography>
      </Box>
      <form onSubmit={(event) => {
        event.preventDefault()
        signIn()
      }} className={classes.root} noValidate autoComplete="off">
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          fullWidth
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          error={!!error}
        />
        <FormControl
          className={classes.margin}
          variant="outlined"
          fullWidth
        >
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={75}
          />
        </FormControl>
        <Box className={classes.authFormAction}>
          <Button type="submit" disabled={!password || !emailAddress} size="large" variant="contained" color="primary">
            {loading ? <CircularProgress /> : "Sign In"}
          </Button>
          <Typography component="span">
            <NavLink to="/forgot-password">Forgot Password?</NavLink>
          </Typography>
        </Box>
      </form>
      {/* <Box className={classes.authFormAfter}>
        <Typography>
          Don't have an account? <Link href="#" onClick={preventDefault}>Register now.</Link>
        </Typography>
      </Box> */}
    </Box>
  )
}