import * as React from "react";
import { useAppState } from "../../hooks/appState";
import { useTenant } from "../../hooks/tenant";
import { Container, Box, Typography, TextField, Button, CircularProgress, Snackbar, IconButton, makeStyles } from "@material-ui/core";
import { AppBar } from "../app-bar/AppBar";
import { cpTheme } from "../../theme/cpTheme";
import { MainContent } from "../layout/MainContent";
import { Sidebar } from "../layout/Sidebar";
import { Panel } from "../panel";
import { LayoutWithSidebarAndBody } from "../layout-with-sidebar-and-body";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { AccountQuery } from "./__generated__/AccountQuery";
import { UpdateAccountMutation, UpdateAccountMutationVariables } from "./__generated__/UpdateAccountMutation";
import { UpdatePasswordMutationVariables, UpdatePasswordMutation } from "./__generated__/UpdatePasswordMutation";
import CloseIcon from "@material-ui/icons/CloseOutlined"
import { HeaderDefault } from "../header-default";

const useStyles = makeStyles( theme => ({
  content: {
    '& .MuiTextField-root': {
      width: '450px',
    }
  },
  button: {
    margin: theme.spacing(0, 2),
  },
}));

export const ACCOUNT_QUERY = gql`
  query AccountQuery {
    viewer {
      id
      created
      firstName
      lastName
      emailAddress
      tenants {
        id
        name
      }
    }
  }
`

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordMutation(
    $accountId: String!,
    $currentPassword: String!,
    $newPassword: String!
  ) {
    updatePassword(input: {
      accountId: $accountId,
      currentPassword: $currentPassword,
      newPassword: $newPassword
    }) {
      ...on UpdatePasswordFailedResponse {
        error
      }
      ...on UpdatePasswordSuccessfulResponse {
        account {
          id
        }
      }
    }
  }
`;

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation(
    $id: String!,
    $firstName: String!,
    $lastName: String!
  ) {
    updateAccount(input: {
      id: $id,
      firstName: { newValue: $firstName },
      lastName: { newValue: $lastName }
    }) {
      account {
        id
        firstName
        lastName
      }
    }
  }
`

export const PageUserAccount: React.FC = (props) => {
  const classes = useStyles();
  const { tenantId, logout } = useAppState()

  const { data, loading, refetch } = useQuery<AccountQuery>(ACCOUNT_QUERY)
  const [mutate, { loading: saving }] = useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UPDATE_ACCOUNT_MUTATION)
  const [mutateUpdatePassword, { loading: savingUpdatePassword }] =
    useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UPDATE_PASSWORD_MUTATION, {
      fetchPolicy: "no-cache"
    })

  const [emailAddress, setEmailAddress] = React.useState(data?.viewer.emailAddress ?? "")
  const [firstName, setFirstName] = React.useState(data?.viewer.firstName ?? "")
  const [lastName, setLastName] = React.useState(data?.viewer.lastName ?? "")
  const [currentPassword, _setCurrentPassword] = React.useState("")
  const [newPassword, _setNewPassword] = React.useState("")
  const [updatePasswordError, setUpdatePasswordError] = React.useState(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  const setNewPassword = (password: string) => {
    setUpdatePasswordError(false)
    _setNewPassword(password)
  }

  const setCurrentPassword = (password: string) => {
    setUpdatePasswordError(false)
    _setCurrentPassword(password)
  }

  const saveUserProfile = async () => {
    if (data) {
      await mutate({ variables: { id: data.viewer.id, firstName, lastName } })
      setSnackbarOpen(true)
      refetch()
    }
  }

  const saveUpdatePassword = async () => {
    if (data && currentPassword && newPassword) {
      const response = await mutateUpdatePassword({
        variables: {
          accountId: data.viewer.id,
          currentPassword,
          newPassword
        }
      })

      switch (response.data?.updatePassword.__typename) {
        case "UpdatePasswordFailedResponse":
          setUpdatePasswordError(true)
          break;
        case "UpdatePasswordSuccessfulResponse":
          setSnackbarOpen(true)
          setCurrentPassword("")
          setNewPassword("")
          break;
      }
    }
  }

  React.useEffect(
    () => {
      setFirstName(data?.viewer.firstName ?? "")
      setLastName(data?.viewer.lastName ?? "")
      setEmailAddress(data?.viewer.emailAddress ?? "")
    }, [data]
  )

  if (loading) return <CircularProgress />

  return (
    <LayoutWithSidebarAndBody
      sidebarContents={
        <>
          <Typography variant="body1" component="h1" gutterBottom>
            <b>Your User Account</b>
          </Typography>
          <Button onClick={() => logout()} variant="outlined" color="primary">
            Sign Out
          </Button>
        </>
      }
      bodyContents={
        <Panel
          body={
            <MainContent className={classes.content}>        
              {(data && !loading) && (
                <>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message="Account updated"
                    action={
                      <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                  <Box pb={6}>
                    <Typography variant="h6" gutterBottom>
                      Personal Info
                    </Typography>
                    <form>
                      <div>
                        <TextField
                          id="first-name"
                          label="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          variant="filled"
                        />
                      </div>
                      <div>
                        <TextField
                          id="last-name"
                          label="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          variant="filled"
                        />
                      </div>
                      <div>
                        <TextField
                          id="email"
                          label="Email"
                          InputProps={
                            {
                              readOnly: true
                            }
                          }
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                          variant="filled"
                        />
                      </div>
                      <div>
                        <Button variant="outlined" onClick={() => saveUserProfile()}>
                          {saving ? <CircularProgress /> : "Save"}
                        </Button>
                      </div>
                    </form>
                  </Box>
                  <Box pb={6}>
                    <Typography variant="h6" gutterBottom>
                      Password
                    </Typography>
                    <form>
                      <div>
                        <TextField
                          id="current-password"
                          type="password"
                          label="Current Password"
                          variant="filled"
                          error={updatePasswordError}
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <TextField
                          id="new-password"
                          type="password"
                          label="New Password"
                          variant="filled"
                          error={updatePasswordError}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <Button onClick={() => saveUpdatePassword()} variant="outlined" disabled={!(!!currentPassword && !!newPassword)}>
                          {savingUpdatePassword ? <CircularProgress /> : "Reset Password"}
                        </Button>
                      </div>
                    </form>
                  </Box>
                </>
              )}
            </MainContent>
          }
        />
      }
    />
  )
}