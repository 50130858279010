import * as React from "react";
import { Drawer, Box, Typography } from "@material-ui/core";
import { DevicePage } from "../_page-device";
import { useAppState } from "../../hooks/appState";
import { makeStyles } from "@material-ui/styles";
import { Panel } from "../panel";

const useStyles = makeStyles({
  drawerPaper: {
    maxWidth: "100vw",
    width: "800px",
    padding: "1rem"
  },
})

interface IRightSideSheetProps {

}

type RightSideSheetProps = IRightSideSheetProps

export const RightSideSheet: React.FC<RightSideSheetProps> = (props) => {
  const { setDrawerDeviceId, drawerDeviceId } = useAppState();
  const classes = useStyles();

  return (
    <Drawer
      open={!!drawerDeviceId}
      anchor="right"
      onClose={() => setDrawerDeviceId("")}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Panel
        header={
          <Box flexGrow={1}>
            <Typography variant="overline" display="block">Devices</Typography>
          </Box>
        }
        body={
          <>{drawerDeviceId && <DevicePage deviceId={drawerDeviceId} />}</>
        }
      />

    </Drawer>
  )
}