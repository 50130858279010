import * as React from "react";
import {Box, Grid, makeStyles} from "@material-ui/core";
import {AuthenticationMarketingContent} from "../authentication-marketing-content";
import {cpTheme} from "../../theme/cpTheme";
import { CpLogoIcon } from "../icons-custom/cp-logo";

const useStyles = makeStyles( theme => ({
  actionsPanel: {
    overflow: 'scroll',
    height: '100vh',
    padding: theme.spacing(11, 8),
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      height: 'auto',
      padding: theme.spacing(7, 4),
    },
  },
  mktbox: {
    background: theme.palette.grey[200],
    overflow: 'hidden',
    height: '100vh',
    padding: theme.spacing(11, 8),
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      height: 'auto',
      padding: theme.spacing(7, 4),
      minHeight: '400px',
    },
  },
  logo: {
    fill: theme.palette.primary.main,
  },
}));

type LayoutAuthenticationProps = ILayoutAuthenticationProps;

interface ILayoutAuthenticationProps {
  content: React.ReactNode
}

export const LayoutAuthentication: React.FC<LayoutAuthenticationProps> = (props) => {
  const classes = useStyles()

  return (
    <Grid container spacing={0}>
      <Grid item md={5} sm={12}>
        <Box className={classes.actionsPanel}>
          <CpLogoIcon color={'primary'} className={classes.logo} />
          { props.content }
        </Box>
      </Grid>
      <Grid item md={7} xs={12}>
        <Box className={classes.mktbox}>
          <AuthenticationMarketingContent/>
        </Box>
      </Grid>
    </Grid>
  )
}