import * as React from "react";
import { TenantId, ITenant } from "../models";
import { useAuth } from "react-use-auth";
import Axios from "axios";
import { API_BASE_URL } from "../config";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { TenantQuery, TenantQueryVariables } from "./__generated__/TenantQuery";

interface ITenantHook {
  loading: boolean;
  data: TenantQuery | null | undefined
}

type TenantHook = ITenantHook

export const TENANT_QUERY = gql`
  query TenantQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      id
      created
      name
      navigation
    }
  }
`;

export const useTenant = (id: TenantId): TenantHook => {
  const { data, loading } = useQuery<TenantQuery, TenantQueryVariables>(TENANT_QUERY, {
    variables: {
      tenantId: id
    }
  })

  return {
    data,
    loading
  }
}
