import * as React from "react";
import { IAccount } from "../models";
import Axios from "axios";
import { API_BASE_URL } from "../config";

interface IAccountHook {
  loading: boolean;
  account: IAccount | null;
  refetch: () => void;
}

type AccountHook = IAccountHook

export const useAccount = (): AccountHook => {
  const [account, setAccount] = React.useState<IAccount | null>(null)
  const [loading, setLoading] = React.useState(false)

  const fetch = async () => {
    // setLoading(true)
    // const response = await Axios.get(`${API_BASE_URL}/account/me`, {
    //   headers: {
    //     ["X-Connected-Proof-Token"]: authResult.idToken
    //   }
    // })
    // setAccount(response.data)
    setLoading(false)
  }

  React.useEffect(() => {
    // if (authResult) {
    //   try {
    //     fetch()
    //   } catch (ex) {
    //     window.location.href = "/logout"
    //   }
    // }
  }, [])

  return {
    loading,
    account,
    refetch: fetch
  }
}