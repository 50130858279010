import * as React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { EquipmentMainDrawerContents } from "../equipment/equipment-drawer";
import { MainContent } from "../layout/MainContent";


const useStyles = makeStyles( theme => ({
  fakePage: {
    background: theme.palette.grey[500],
    overflow: 'hidden',
    height: '100vh',
    padding: theme.spacing(10, 8),
  },
  fakeDrawer: {
    background: '#fff',
    overflow: 'hidden',
    height: '100%',
    width: '800px',
    maxWidth: '100%',
    zIndex: 1200,
    boxShadow: theme.shadows[20],
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
}));


type BeltlineEquipmentDrawerContentsProps = IBeltlineEquipmentDrawerContentsProps;


interface IBeltlineEquipmentDrawerContentsProps {

}

export const BeltlineEquipmentDrawerContents: React.FC<BeltlineEquipmentDrawerContentsProps> = (props) => {
  const classes = useStyles();

  return (
    <MainContent className={classes.fakePage}>

      <Typography>
        Static drawer testing template.
      </Typography>

      <Box className={classes.fakeDrawer}>
        {/* Place Drawer Content Here */}

        <EquipmentMainDrawerContents />

        {/* End Drawer Content  */}
      </Box>

    </MainContent>
  )
}