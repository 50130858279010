import React from "react";
import { ModeState } from "../components/equipment/equipment-controls";
import ReconnectingWebSocket from "reconnecting-websocket";
import { WS_BASE_URL, API_BASE_URL } from "../config";
import Axios from "axios";

export interface IPendingChange {
  mode?: ModeState | null;
  setpoint?: number | null;
}

export interface ILiveReadingsDevice {
  mode: ModeState | null;
  setpoint: number | null;
  title: string;
  currentValue: number | null;
  currentValueAsOf: number | null;
  pendingChange: IPendingChange | null;
  saving: boolean;
  error: LiveReadingErrorState | null
}

type Setpoint = number
type DeviceId = string;

type ModeSetterFn = (id: DeviceId, v: ModeState) => void
type SetpointSetterFn = (id: DeviceId, v: Setpoint) => void
type SubscribeFn = (id: DeviceId, port?: string | null) => void

const defaultLiveReadingsContext: ILiveReadingsContext = {
  readings: {},
  titles: {},
  subscribe: (id) => { },
  setPendingChange: (deviceId, pendingChange) => { },
  pendingChanges: {},
  errors: {}
}

export enum LiveReadingErrorState {
  ErrorSaving = "ErrorSaving",
  Retrying = "Retrying",
  ErrorRetrying = "ErrorRetrying",
}

export interface ILiveReadingsContext {
  readings: { [key: string]: ILiveReadingsDevice }
  titles: { [key: string]: string }
  subscribe: SubscribeFn,
  setPendingChange: (deviceId: DeviceId, pendingChange: IPendingChange) => void
  pendingChanges: { [key: string]: IPendingChange | null },
  errors: ErrorsMap
}

type ReadingsMap = { [key: string]: ILiveReadingsDevice }
type ErrorsMap = { [key: string]: LiveReadingErrorState | null }

export const LiveReadingsContext = React.createContext(defaultLiveReadingsContext)

export const LiveReadingsProvider: React.FC = (props) => {
  const [readings, _setReadings] = React.useState<ReadingsMap>({})
  const [websockets, setWebsockets] = React.useState<{ [key: string]: ReconnectingWebSocket }>({})
  const [titles, setTitles] = React.useState<{ [key: string]: string }>({})
  const [pendingChanges, setPendingChanges] = React.useState<{ [key: string]: IPendingChange | null }>({})
  const [errors, setErrors] = React.useState<ErrorsMap>({})

  const readingsRef = React.useRef(readings)
  const setReadings = (newReadings: ReadingsMap) => {
    readingsRef.current = newReadings
    _setReadings(newReadings)
  }

  const setCurrentValue = (id: DeviceId, currentValue: number) => {
    const newValue = {
      ...readingsRef.current,
      [id.toLowerCase()]: {
        ...(readingsRef.current[id.toLowerCase()] || {}),
        currentValue,
        currentValueAsOf: (new Date().getUTCMilliseconds())
      }
    }

    setReadings(newValue)
  }

  const setModeAndSetpoint = (id: DeviceId, mode: ModeState | null, setpoint: number | null) => {
    setReadings(
      {
        ...readingsRef.current,
        [id.toLowerCase()]: {
          ...(readingsRef.current[id.toLowerCase()] || {}),
          mode: mode || readingsRef.current[id]?.mode,
          setpoint: setpoint || readingsRef.current[id]?.setpoint
        }
      }
    )
  }

  const fetch = async (deviceId: DeviceId) => {
    const device = await Axios.get(`${API_BASE_URL}/device/${deviceId}`)
    const json = await device.data
    setTitles({
      ...titles,
      [deviceId]: json.equipmentInstance?.title ?? ""
    })
  }

  const receiveMessage = (message: MessageEvent) => {
    const json = JSON.parse(message.data)

    const id = [json['id'].toLowerCase(), json['port']].filter(v => !!v).join('-')
    const type = json['type']

    switch (type) {
      case "DeviceReadingUpdate":
        const currentValue = json['reading']
        setCurrentValue(id, currentValue)
        break;
      case "DeviceModeAndSetpointUpdate":
        const mode = json['mode']
        const setpoint = json['setpoint']
        clearPendingChange(id)
        setModeAndSetpoint(id, mode, setpoint)
        break;
      default:
        break;
    }
  }

  const getWebsocket = (id: DeviceId) => {
    console.log("instantiate websocket", id)
    if (!!websockets[id]) return websockets[id]

    const ws = new ReconnectingWebSocket(`${WS_BASE_URL}/device/${id}`)

    ws.addEventListener('open', () => {

    });

    ws.addEventListener('message', receiveMessage)

    return ws
  }

  const subscribe: SubscribeFn = (id, port) => {
    if (!id) return

    fetch(id)

    const ws = getWebsocket(id)

    setWebsockets({
      ...websockets,
      [id]: getWebsocket(id)
    })
  }

  const clearPendingChange = (deviceId: string) => {
    setPendingChanges({
      ...pendingChanges,
      [deviceId]: null
    })
  }

  const setPendingChange = async (deviceId: string, pendingChange: IPendingChange, _tries?: number) => {
    let tries = _tries || 0

    setPendingChanges({
      ...pendingChanges,
      [deviceId]: pendingChange
    })

    try {
      await Axios.put(`${API_BASE_URL}/device/${deviceId}`, {
        mode: readings[deviceId].mode,
        setpoint: readings[deviceId].setpoint,
        ...pendingChange
      })
      setErrors({ ...errors, [deviceId]: null })
    } catch (error) {
      console.log(error)
      setErrors({ ...errors, [deviceId]: LiveReadingErrorState.Retrying })

      if (tries < 5) {
        setTimeout(
          () => {
            setPendingChange(deviceId, pendingChange, tries + 1)
          }, 5000
        )
      } else {
        setErrors({ ...errors, [deviceId]: LiveReadingErrorState.ErrorRetrying })
      }
    }
  }

  const cancelPendingChange = async (deviceId: string) => {
    setPendingChanges({ ...pendingChanges, [deviceId]: null })
    setErrors({ ...errors, [deviceId]: null })
  }

  const value = {
    readings,
    titles,
    pendingChanges,
    subscribe,
    setPendingChange,
    errors
  }

  return (
    <LiveReadingsContext.Provider value={value}>
      {props.children}
    </LiveReadingsContext.Provider>
  )
}
