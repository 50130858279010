import * as React from 'react';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';


export enum SyncStatus {
  success,
  syncing,
  offline
}

export interface ICloudSyncStatusProps {
  status: SyncStatus,
}

export function CloudSyncStatus(props: ICloudSyncStatusProps) {
  return (
    <>
      {(props.status == SyncStatus.success) && <CloudDoneOutlinedIcon />}
      {(props.status == SyncStatus.offline) && <CloudOffOutlinedIcon />}
      {(props.status == SyncStatus.syncing) && <SyncOutlinedIcon />}
    </>
  );
}

const status = SyncStatus.success;

export function ConnectedCloudSyncStatus(props: {}) {
  const [state, setState] = React.useState(null as SyncStatus | null);
  const getStatus = () => status;

  React.useEffect(() => { setState(getStatus()) }, []);

  if (state === null) return null;
  return (
    <CloudSyncStatus status={state} />
  );
}
