import * as React from "react";
import { ChartLegend } from "../chart-legend";
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Line, ReferenceLine, Tooltip } from "recharts";
import { cpVars, cpTheme } from "../../theme/cpTheme";
import { Box, makeStyles, Typography, Checkbox, Divider, FormControl, Select, MenuItem } from "@material-ui/core";
import { IChartLegendSeries } from "../equipment-drawer-main-contents";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import moment from "moment";
import { useQueryParam, ArrayParam, DelimitedArrayParam, withDefault } from "use-query-params";
import * as _ from "lodash"
import { useLocation } from "react-router-dom";
import { NoData } from "../device-card";

const defaultFormatter = (v: number) => String(v)

const useStyles = makeStyles(theme => ({
  // Chart Styles
  chartContainer: {
    margin: theme.spacing(1, 0),
  },
  chartControls: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: theme.spacing(2, 0, 1),
  },
  rechartContainer: {
    position: 'relative',
  },
  noData: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    width: '100%',
    height: '100%',
    background: 'rgba(255,255,255,0.2)',
    '& .MuiTypography-root': {
      display: 'inline-block',
      background: theme.palette.grey[200],
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
    }
  },
  legendContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
    maxWidth: '60%',
  },
  legendItem: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    marginTop: '-0.5em',
    paddingRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    // '&:hover': {
    //   background: theme.palette.action.hover,
    // },
  },
  legendItemColor: {
    display: 'inline-block',
    width: '0.8em',
    height: '0.8em',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  legendItemName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  legendItemValue: {
    paddingLeft: theme.spacing(4),
  },

  // Drawer Styles
  deviceInfo: {
    paddingBottom: theme.spacing(2),
  },
  equipmentInstall: {
    textDecoration: 'underline',
    marginBottom: '0.2rem',
    display: 'flex',
    alignItems: 'center',
    opacity: '0.7',
    '& .MuiSvgIcon-root': {
      padding: '0.3rem',
    },
    '&:hover': {
      opacity: '1',
    }
  },
  allControls: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  currentCard: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    // Boxes in the Card
    '& > .MuiBox-root': {
      padding: theme.spacing(2),
      flex: '1 1 auto',
    }
  },
  currentReading: {
    textAlign: 'center',
  },
}));

interface ITimeSeriesDataPoint {
  time: number;
  values: { [key: string]: number }
}

export type TimeSeriesDataPoint = ITimeSeriesDataPoint

type LineGraphProps = ILineGraphProps;

interface ILineGraphProps {
  series: IChartLegendSeries[]
  data: TimeSeriesDataPoint[]
  initialSelected?: string
}

export const LineGraph: React.FC<LineGraphProps> = (props) => {
  const classes = useStyles()
  const { series } = props

  const [hoverData, setHoverData] = React.useState<any>()
  const [highlighted, setHighlighted] = React.useState([] as string[])

  const noData = true

  React.useEffect(
    () => {
      if (props.initialSelected) {
        setHighlighted([...highlighted, props.initialSelected])
      }
    }, [props.initialSelected]
  )

  const data = React.useMemo<any[]>(
    () => props.data.map(d => ({
      time: d.time,
      ...d.values
    })), [props.data]
  )

  const isChecked = (key: string) => (highlighted.indexOf(key) > -1) || (highlighted.length === 0)

  const currentValue = (key: string) => {
    const last = _.last(data.filter(v => !!v[key]))
    return last ? last[key] : null
  }

  const hoveredValue = (key: string) => hoverData ? hoverData[key] : null

  const valueToShow = (key: string) => hoveredValue(key) || currentValue(key) || null

  const getHighlighted = () => (highlighted.length === 0) ? series.map(s => s.key) : highlighted

  const strokeWidthForSeries = (s: IChartLegendSeries) => {
    return isChecked(s.key) ? "2" : "1"
  }

  const readings = props.data
    .map(d => Object
      .keys(d.values)
      .filter(k => k !== "secondsBucket")
      .map(k => d.values[k]))
    .flat()

  const average = readings ? readings[0] : 0

  const minTemp = readings ? (Math.min(...readings)) : 0
  const maxTemp = readings ? (Math.max(...readings)) : 0

  const TEMP_MARGIN = 5

  const setChecked = (key: string, checked: boolean) => {
    setHighlighted(
      checked
        ? Array.from(new Set([...(getHighlighted() as string[]), key]))
        : [...getHighlighted().filter(v => v !== key)]
    )
  }

  const legend = (
    <Box className={classes.legendContainer}>
      {series.map(s => (
        <Box className={classes.legendItem}>
          <Typography variant="body2" color="textSecondary" className={classes.legendItemName}>
            <Checkbox
              onChange={(e) => setChecked(s.key, e.target.checked)}
              checked={isChecked(s.key)}
              disabled={highlighted.length === 1 && isChecked(s.key)}
              color="default"
              size="small"
            />
            <Box className={classes.legendItemColor} style={{ background: s.color }}></Box>
            {s.title}
          </Typography>
          <Typography variant="body2" className={classes.legendItemValue}>
            {fahrenheitTemperatureFormatter(valueToShow(s.key))}
          </Typography>
        </Box>
      ))}
    </Box>
  )

  return (
    <Box className={classes.chartContainer}>
      <Typography variant="body1" gutterBottom>
        Last 30 Minutes
      </Typography>
      {/* <Box className={classes.chartControls}>
        {!noData && <span>legend</span>}
        <FormControl variant="outlined" disabled>
          <Select
            labelId="lid"
            id="id"
            value='30m'
          >
            <MenuItem value="30m">Last 30 minutes</MenuItem>
          </Select>
        </FormControl>
      </Box> */}
      <Box className={classes.rechartContainer}>
        {noData && (
          <Box className={classes.noData}>
            <Typography variant="body1">
              No data available for this range
            </Typography>
          </Box>
        )}
        <ResponsiveContainer width="100%" height={330}>
          <ComposedChart data={data}>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={cpVars.paletteBrandPrimary} stopOpacity={0.8} />
                <stop offset="95%" stopColor={cpVars.paletteBrandPrimary} stopOpacity={0} />
              </linearGradient>
            </defs>
            {/* 
            We don't need these: 
            - CartesianGrid should give us the background lines
            <ReferenceLine
              strokeDasharray="3 3"
              y={minTemp - TEMP_MARGIN}
              stroke="#9F9F9F"
            />
            <ReferenceLine
              strokeDasharray="3 3"
              y={maxTemp + TEMP_MARGIN}
              stroke="#9F9F9F"
            />
            <ReferenceLine
              strokeDasharray="3 3"
              y={average}
              stroke="#9F9F9F"
            /> */}
            <XAxis
              dataKey="time"
              interval={100}
              padding={{ left: 5, right: 10 }}
              tickFormatter={(v) => `- ${moment(v).fromNow(true)}`}
            />
            <XAxis
              dataKey="time"
              interval={200}
              padding={{ left: 5, right: 10 }}
              tickMargin={20}
              tickFormatter={(v) => `${moment(v).format()}`}
            />
            {!noData && (
              <YAxis
                domain={[`dataMin - ${TEMP_MARGIN / 5}`, `dataMax + ${TEMP_MARGIN / 5}`]}
                tickSize={0}
                // ticks={[minTemp - TEMP_MARGIN, average, maxTemp + TEMP_MARGIN]}
                tickMargin={10}
                orientation={"right"}
                axisLine={false}
                tickFormatter={fahrenheitTemperatureFormatter}
              />)
            }
            {noData && (
              <YAxis
                domain={[0, 100]}
                tickSize={0}
                // ticks={[minTemp - TEMP_MARGIN, average, maxTemp + TEMP_MARGIN]}
                tickMargin={10}
                orientation={"right"}
                axisLine={false}
                tickFormatter={() => <NoData />}
              />)
            }
            {!noData && (
              <>
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                />
                <Tooltip
                  content={
                    (innerProps: any) => {
                      if (innerProps.payload) {
                        const hd = innerProps.payload
                          .reduce((acc: {}, curr: any) => ({
                            ...acc,
                            [curr.dataKey]: curr.value
                          }), {})

                        if (!_.isEqual(hd, hoverData)) {
                          setHoverData(hd)
                        }
                      }

                      return <span />
                    }
                  }
                />
                {props.series.map((s, index) => (
                  <Line
                    type="monotone"
                    dataKey={s.key}
                    strokeWidth={strokeWidthForSeries(s)}
                    dot={false}
                    isAnimationActive={false}
                    stroke={s.color}
                  />
                ))}
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
};