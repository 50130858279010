import * as React from "react"
import { TextField as MUITextField} from "@material-ui/core"
import { TextFieldProps } from "@material-ui/core/TextField"

// const useStyles = makeStyles(theme => ({
//   textField: {
//     background: theme.palette.primary.main
//   }
// }))

export const TextField: React.FC<TextFieldProps> = (props) => {
  // const styles = useStyles()

  return (
    <MUITextField
      // className={styles.textField}
     {...props}
     />
  )
}