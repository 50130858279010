import * as React from "react";
import { makeStyles, Box, Typography, IconButton, Divider, Button, CircularProgress, Hidden, Container, Toolbar, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Panel } from '../panel';
import { cpTheme, cpVars } from '../../theme/cpTheme';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ResponsiveContainer, LineChart, ReferenceLine, YAxis, Line } from 'recharts';
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { DeviceSettingsQuery, DeviceSettingsQueryVariables, DeviceSettingsQuery_installation } from "./__generated__/DeviceSettingsQuery";
import { DeviceDrawerMainQuery } from "../device-drawer/__generated__/DeviceDrawerMainQuery";
import { FormikProps } from "formik";
import { DeviceForm } from "../device-drawer"

type DeviceSettingsDrawerContentsProps = IDeviceSettingsDrawerContentsProps;

interface IDeviceSettingsDrawerContentsProps {
  data: DeviceDrawerMainQuery
  formikProps: FormikProps<DeviceForm>
}

export const DeviceSettingsDrawerContents: React.FC<DeviceSettingsDrawerContentsProps> = (props) => {
  const classes = useStyles();
  const { data, formikProps } = props

  const configs = data.installation.instance.interfaces.map(iface => {
    switch (iface.equipmentInterface.__typename) {
      case "EquipmentInputInterface":
        let name = ""

        switch (iface.installationTarget.__typename) {
          case "EquipmentInterfaceInstanceInstallationTarget":
            name = iface.installationTarget.installations[0].name || ""
            break
          default:
            name = ""
            break
        }

        return (
          <Box pt={2}>
            <Typography variant="body1" className={classes.type}>
              <strong>{iface.equipmentInterface.name}</strong>
            </Typography>
            <div>
              <TextField
                label="Input name"
                variant="filled"
                value={formikProps.values.interfaceInstanceNames[iface.id]}
                onChange={(e) => formikProps.setFieldValue(`interfaceInstanceNames.${iface.id}`, e.target.value)}
              />
            </div>
            <div>
              <FormControl variant="filled">
                <InputLabel>
                  Input Type
                </InputLabel>
                <Select
                  value={"temp"}
                  disabled
                >
                  <MenuItem value={"temp"}>Temperature Sensor</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
        )
      case "EquipmentOutputInterface":
        return (
          <Box pt={2}>
            <Typography variant="body1" className={classes.type}>
              <strong>{iface.equipmentInterface.name}</strong>
            </Typography>
            <div>
              <TextField
                label="Output name"
                variant="filled"
                value={formikProps.values.interfaceInstanceNames[iface.id]}
                onChange={(e) => formikProps.setFieldValue(`interfaceInstanceNames.${iface.id}`, e.target.value)}
              />
            </div>
            <div>
              <FormControl variant="filled">
                <InputLabel id="demo-simple-select-outlined-label">
                  Output Effect
                  </InputLabel>
                <Select
                  disabled
                  value={iface.equipmentInterface.defaultOutputEffect?.id || ""}
                >
                  {data.outputEffects.map(effect => (
                    <MenuItem value={effect.id}>{effect.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl variant="filled">
                <InputLabel id="demo-simple-select-outlined-label">
                  Output Type
                  </InputLabel>
                <Select
                  disabled
                  value={iface.equipmentInterface.defaultOutputType?.id || ""}

                >
                  {data.outputTypes.map(type => (
                    <MenuItem value={type.id}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl variant="filled">
                <InputLabel id="demo-simple-select-outlined-label">
                  Output Failsafe / Standby State
                  </InputLabel>
                <Select
                  disabled
                  value={iface.equipmentInterface.defaultFailsafeStandbyState?.id || ""}

                >
                  {data.outputStates.map(state => (
                    <MenuItem value={state.id}>{state.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>
        )
    }
  })

  return (
    <Box>
      <form className={classes.root}>
        <Typography variant="body1" className={classes.type}>
          <strong>General Device Settings</strong>
        </Typography>
        <div>
          <TextField
            label="Device name"
            value={formikProps.values.title}
            onChange={(e) => formikProps.setFieldValue("title", e.target.value)}
            defaultValue=""
            variant="filled"
          />
        </div>
        {configs.map(config => <Box className={classes.section}>{config}</Box>)}
      </form>
    </Box >
  )
}

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
      minWidth: '100%',
    },
  },
  section: {
    margin: theme.spacing(2, 0, 3),
  },
  type: {
    margin: theme.spacing(0, 0, 2),
  },
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },

}));


