import * as React from "react";
import { Typography, IconButton, MenuItem, Box, makeStyles, fade, FormControl, Select, Divider, CircularProgress, Card, Chip, Button, CardActions, CardContent } from "@material-ui/core";
import clsx from 'clsx';

// Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Pulse } from "../pulse/pulse";
import { CoolIcon } from "../icons-custom/cool";
import { fahrenheitTemperatureFormatter, fahrenheitTemperatureFormatterSetpoint } from "../_page-device";
import { useLiveReading } from "../physical-devices/temperature-controller";
import { SyncStatus } from "../status-cloud-sync";
import { LiveReadingErrorState } from "../../hooks/liveReadings";
import { relative } from "path";


const useStyles = makeStyles(theme => ({

  variableControlContainer: {
    display: 'inline-flex',
    width: '100%',

    // Preview Chip
    '& .modePreviewChip': {
      display: 'inline-flex',
      borderRadius: '4em',
      padding: theme.spacing(1.5, 2),
      backgroundColor: fade(theme.palette.text.primary, 0.15),
    },

    // Override for Modes
    '&.modeCool': {
      // Control Buttons
      '& .MuiOutlinedInput-root': {
        color: theme.palette.modeCool.main,
        backgroundColor: fade(theme.palette.modeCool.main, 0.15),
        borderColor: fade(theme.palette.modeCool.main, 0.35),
        '&:hover': {
          backgroundColor: fade(theme.palette.modeCool.main, 0.25),
        },
      },
      // Preview Chips
      '& .modePreviewChip': {
        backgroundColor: fade(theme.palette.modeCool.main, 0.15),
        color: theme.palette.modeCool.main,
      },
    },
  },

  previewMode: {
    textTransform: 'lowercase',
    fontVariant: 'small-caps',
  },

  controlGroup: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    // Boxes in the Card
    '& > .MuiBox-root': {
      padding: theme.spacing(1,0),
      flex: '1 1 auto',
    }
  },
  controls: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  // Wrapper for <CircularProgress>
  loadingScreen: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 2,
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.bgScreen.main,
    borderRadius: '6em',
  },
  // Wrapper for Errors
  errorScreen: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 2,
    width: '100%',
    minHeight: '100%',
    // marginBottom: theme.spacing(2),
    '& .MuiCardContent-root': {
      paddingBottom: '0',
    },
    '& .MuiCardActions-root': {
      justifyContent: 'flex-end',
    },
  },

  // Mode Select
  // Todo:
  // • We should probably abstract this
  // • The Mui-Focused state is causing slight design issue: wrong bg color. Would like to remove focus after option is selected in menu.
  
  roundedSelect: {
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: '4em',
    overflow: 'hidden',
    '& .MuiOutlinedInput-root': {
      color: theme.palette.text.primary,
      backgroundColor: fade(theme.palette.text.primary, 0.15),
      borderColor: fade(theme.palette.text.primary, 0.35),
      borderRadius: '4em',
      fontWeight: 700,
      fontSize: '1.2em',
      '&:hover': {
        backgroundColor: fade(theme.palette.text.primary, 0.25),
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2.1, 2.3),
      },
      '& .MuiSelect-icon': {
        color: 'inherit',
        opacity: '70%',
      },
      '& fieldset': {
        border: 'none',
      },
    },
  },
  
  // Numeral Input
  setPointInput: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    '& p': {
      margin: theme.spacing(0, 1.0),
      fontWeight: 700,
      fontSize: '1.8em',
      color: theme.palette.modeCool.main,
    },
  },
  currentReading: {
    textAlign: 'center',
  },
  mutedIconButtons: {
    color: theme.palette.modeCool.main,
    backgroundColor: fade(theme.palette.modeCool.main, 0.15),
    marginLeft: theme.spacing(0.5),
    borderColor: fade(theme.palette.modeCool.main, 0.45),
    '&:hover': {
      backgroundColor: fade(theme.palette.modeCool.main, 0.25),
    },
    '& .MuiIconButton-label': {
      touchAction: 'none',
      pointerEvents: 'none',
    },
    '& .MuiSvgIcon-root': {
      width: '1.2em',
      height: '1.2em',
    }
  },
}));

export enum ModeState {
  Cool = 'Cool',
  Off = 'Off'
}

const modeStates = [
  ModeState.Cool,
  ModeState.Off
]

const labelForMode = (mode: ModeState) => {
  switch (mode) {
    case ModeState.Off:
      return 'OFF'
    case ModeState.Cool:
      return 'COOL'
    default:
      throw Error()
  }
}

export interface IEquipmentControls {
  deviceId: string;
}

type EquipmentControls = IEquipmentControls

export const EquipmentControlChip: React.FC<IEquipmentControls> = (props) => {
  const classes = useStyles();
  const liveReading = useLiveReading(props.deviceId)

  const colorClassNameForMode = (mode: ModeState): string => {
    switch (mode) {
      case ModeState.Cool:
        // return classes.activeMode
        return 'modeCool'
      case ModeState.Off:
        // return classes.inactiveMode
        return 'modeOff'
      default:
        throw Error(`unexpected mode - ${mode}`)
    }
  }

  return (
    <>
    {liveReading.mode && (
      <Box 
        className={clsx(
          classes.variableControlContainer,
          colorClassNameForMode(liveReading.mode),
        )}
      >
        <Box className="modePreviewChip">
          <Typography component="p" variant="h6">
            <span className={classes.previewMode}>{liveReading.mode} </span>
            {(liveReading.setpoint && (liveReading.mode !== ModeState.Off)) && (<span>{liveReading.setpoint ? fahrenheitTemperatureFormatterSetpoint(liveReading.setpoint) : ""}</span>)}
          </Typography>
        </Box>
      </Box>
    )}
    </>
  )
}

export const EquipmentControls: React.FC<EquipmentControls> = (props) => {
  const classes = useStyles();
  const device = useLiveReading(props.deviceId)

  const colorClassNameForMode = (mode: ModeState): string => {
    switch (mode) {
      case ModeState.Cool:
        // return classes.activeMode
        return 'modeCool'
      case ModeState.Off:
        // return classes.inactiveMode
        return 'modeOff'
      default:
        throw Error(`unexpected mode - ${mode}`)
    }
  }

  const {
    syncStatus,
    pendingSetpoint,
    pendingMode,
    setpoint,
    setSetpoint,
    mode,
    setMode,
    error
  } = device

  const effectiveSetpoint = pendingSetpoint || setpoint
  const effectiveMode = pendingMode || mode

  const incrementSetpoint = () => {
    effectiveSetpoint && setSetpoint(effectiveSetpoint + 1)
  }

  const decrementSetpoint = () => {
    effectiveSetpoint && setSetpoint(effectiveSetpoint - 1)
  }

  const syncStatusProgressSpinner =
    ((error !== LiveReadingErrorState.ErrorRetrying) && (syncStatus === SyncStatus.syncing)) && 
      <Box className={classes.loadingScreen}>
        <CircularProgress size={24} thickness={6} color={'inherit'} />
        {/* <Typography>Working...</Typography> */}
      </Box>

  const syncStatusErrorState = (
    () => {
      const fieldsToUpdate = [pendingMode ? "mode" : null, pendingSetpoint ? "setpoint" : null].filter(v => !!v).join(" and ")

      switch (error) {
        case LiveReadingErrorState.ErrorRetrying:
          return (
            <Card className={classes.errorScreen}>
              <CardContent>
                <Typography variant="body2">Could not update {fieldsToUpdate}.</Typography>
              </CardContent>
              <CardActions>
                <Button>Retry</Button><Button>Cancel</Button>
              </CardActions>
            </Card>
          )
        case LiveReadingErrorState.ErrorSaving:
          return <></>
        case LiveReadingErrorState.Retrying:
          return (
            <Card className={classes.errorScreen}>
              <CardContent>
                <Typography variant="body2" gutterBottom>Could not update {fieldsToUpdate}. Retrying...</Typography>
              </CardContent>
              <CardActions>
                <Button>Cancel</Button>
              </CardActions>
            </Card>
          )
        default:
          return <></>
      }
    }
  )()

  const controlsDisabledDuringSyncEvent = !!error || syncStatus === SyncStatus.syncing

  const modeControl = (
    <>
      {(effectiveMode && (
        <FormControl
          className={classes.roundedSelect}
          variant="outlined"
        >
          <Select
            value={effectiveMode}
            onChange={(e) => {
              setMode(e.target.value as ModeState)
            }}>
            {modeStates.map(modeState => (
              <MenuItem key={modeState} value={modeState}>
                {labelForMode(modeState)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    </>
  )

  const setpointControl = (
    <>
      {((effectiveMode !== ModeState.Off) && !!effectiveSetpoint) && (
        <Box className={classes.setPointInput}>
          <IconButton 
            onClick={() => decrementSetpoint()} 
            className={`${classes.mutedIconButtons}`}
            aria-label="decrease temperature"
          >
            <RemoveIcon />
          </IconButton>
          <Typography>{effectiveSetpoint && fahrenheitTemperatureFormatterSetpoint(effectiveSetpoint)}</Typography>
          <IconButton 
            onClick={() => incrementSetpoint()} 
            className={`${classes.mutedIconButtons}`}
            aria-label="increase temperature">
            <AddIcon />
          </IconButton>
        </Box>
      )}
    </>
  )

  const currentReading = (
    <Box className={classes.currentReading}>
      <Typography variant="h2">
        {device.currentReading ? <span>{fahrenheitTemperatureFormatter(device.currentReading)}</span> : <span />}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Live
      </Typography>
    </Box>
  )

  return (
    <Box className={classes.controlGroup}>
      <Box>
        {currentReading}
      </Box>
      <Box 
        className={clsx(
          classes.variableControlContainer,
          colorClassNameForMode(effectiveMode || ModeState.Off),
        )}
      >
        <Box className={classes.controls}>
          {syncStatusErrorState}
          {syncStatusProgressSpinner}
          {modeControl}
          {setpointControl}
        </Box >
      </Box>
    </Box>
  )
}
