import * as React from "react";
import { Box, Toolbar, Typography, Hidden, IconButton, Container, makeStyles, Button, CircularProgress } from "@material-ui/core";
import { Panel } from "../panel";
import { ArrowBack, CloseOutlined, SettingsOutlined } from "@material-ui/icons";
import { cpTheme, cpVars } from '../../theme/cpTheme';
import { EquipmentDrawerMainContents } from "../equipment-drawer-main-contents";
import { EquipmentSettingsDrawerContents } from "../equipment-settings-drawer-contents";
import { PanelWithSettingsAndBack } from "../panel-with-settings-and-back";
import { Formik, FormikConfig, FormikValues } from "formik";
import { useAppState } from "../../hooks/appState";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { DEVICE_FRAGMENT } from "../../fragments";
import { EquipmentDrawerQueryVariables, EquipmentDrawerQuery } from "./__generated__/EquipmentDrawerQuery";
import { EquipmentDrawerMutation, EquipmentDrawerMutationVariables } from "./__generated__/EquipmentDrawerMutation";
import { useLiveReading } from "../physical-devices/temperature-controller";

type EquipmentDrawerProps = IEquipmentDrawerProps;

interface IEquipmentDrawerProps {
  installationId: string
  deviceId: string;
  onClose?: () => void;
}

enum EquipmentDrawerMode {
  Main,
  Settings
}

const EQUIPMENT_DRAWER_MUTATION = gql`
  mutation EquipmentDrawerMutation(
    $installationId: String!,
    $title: String!
  ) {
    updateFacilityViewInstallation(input: {
      installation: $installationId,
      title: $title
    }) {
      installation {
        id
      }
    }
  }
`;

const EQUIPMENT_DRAWER_QUERY = gql`
  query EquipmentDrawerQuery(
    $installationId: String!,
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      locations {
        id
        name
      }
    }
    equipments {
      id
      name
    }
    installation(id: $installationId) {
      id
      name
      facilityView {
        title
        deviceId
        installedDevices {
          ...DeviceFragment
        }
        facilityGroup {
          id
        }
        type {
          id
        }
        outputs {
          id
        }
        inputs {
          device {
            id
          }
          port
          installation {
            id
            name
            readings(latest: 1) {
              id
              created
              value
              secondsBucket
            }
          }
        }
      }
    }
  }

  ${DEVICE_FRAGMENT}
`

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
      minWidth: 450,
    },
  },
  section: {
    margin: theme.spacing(2, 0, 3),
  },
  type: {
    margin: theme.spacing(0, 0, 2),
  },
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
}));

interface IEquipmentForm {
  title: string;
  type: string;
  location: string;
}

export type EquipmentForm = IEquipmentForm

export const EquipmentDrawer: React.FC<EquipmentDrawerProps> = (props) => {
  const [mode, setMode] = React.useState(EquipmentDrawerMode.Main)
  const classes = useStyles()

  const device = useLiveReading(props.deviceId)

  const { tenantId } = useAppState()

  const { data, loading } =
    useQuery<EquipmentDrawerQuery, EquipmentDrawerQueryVariables>(EQUIPMENT_DRAWER_QUERY, {
      variables: {
        installationId: props.installationId,
        tenantId: tenantId || ""
      },
      fetchPolicy: "no-cache"
    })

  const [mutate, { data: mutationData, loading: mutationLoading }] =
    useMutation<EquipmentDrawerMutation, EquipmentDrawerMutationVariables>(EQUIPMENT_DRAWER_MUTATION)

  const [saving, setSaving] = React.useState(false)

  if (loading || !data) return <CircularProgress />

  const saveForm = async (values: EquipmentForm) => {
    setSaving(true)
    await mutate({
      variables: {
        title: values.title,
        installationId: data.installation.id
      }
    })
    setSaving(false)
  }

  const formikConfig: FormikConfig<EquipmentForm> = {
    initialValues: {
      title: data.installation.facilityView?.title || "",
      type: data.installation.facilityView?.type?.id || "",
      location: data.installation.facilityView?.facilityGroup?.id || ""
    },
    onSubmit: (values, { }) => {
      saveForm(values)
    }
  }

  return (
    <Formik {...formikConfig}>
      {(formikProps) => (
        <PanelWithSettingsAndBack
          title={"Equipment"}
          mainContents={
            <EquipmentDrawerMainContents
              {
              ...{
                data,
                ...props
              }
              }
            />
          }
          settingsContents={
            <EquipmentSettingsDrawerContents
              {...{
                data,
                formikProps
              }}
            />
          }
          settingsActions={
            <Button
              color="primary"
              disabled={saving}
              onClick={() => formikProps.submitForm()}
            >
              {saving && <CircularProgress />}
              {!saving && <span>Save</span>}
            </Button>
          }
          onClose={props.onClose}
          onBack={() => formikProps.resetForm()}
        />
      )}
    </Formik>
  )
};