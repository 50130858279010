import * as React from "react";
import { Avatar, makeStyles } from "@material-ui/core";
import { cpTheme } from "../../theme/cpTheme";
import { useAppState } from "../../hooks/appState";

const avatarStyles = makeStyles( theme => ({
  navAvatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    fontSize: '1.3em',
    fontWeight: 700,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.hint,
  },
}));

interface IUserAvatarProps {

}

type UserAvatarProps = IUserAvatarProps

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const classes = avatarStyles();
  const { viewer } = useAppState()

  return <Avatar
    alt={viewer ? `${viewer.firstName} ${viewer.lastName}` : ""}
    src="/broken-image.jpg"
    className={classes.navAvatar}
  />;
}