import * as React from "react";
import { makeStyles, Card, Box, Typography, CardContent, CardActionArea, Chip, Tooltip, Link } from "@material-ui/core";
import { cpTheme } from "../../theme/cpTheme";
import { WirelessIndicator, WirelessStrength } from "../status-wifi";
import { EquipmentControls, IEquipmentControls, ModeState, EquipmentControlChip } from "../equipment/equipment-controls";
import { DeviceFragment } from "../../fragments/__generated__/DeviceFragment";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import { useLiveReading } from "../physical-devices/temperature-controller";
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { history } from "../../history";


const useStyles = makeStyles(theme => ({
  // Start Full Devices Card
  deviceCard: {
    height: '100%',
  },
  deviceAction: {
    height: '100%',
  },
  deviceContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  deviceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deviceType: {
    marginBottom: '0.8rem',
  },
  deviceDetails: {
    paddingRight: theme.spacing(2),
  },
  installEquipment: {
    marginBottom: '0.2rem',
  },
  deviceIcon: {
    width: '30%',
    overflow: 'hidden',
    // backgroundColor: '#ccc',
    marginRight: `-${theme.spacing(2)}px`,
    marginTop: `-${theme.spacing(2)}px`,
    '& svg': {
      // maxWidth: '100%',
      float: 'right',
      padding: theme.spacing(0.2),
      margin: theme.spacing(1),
    },
  },
  deviceReadings: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    width: '100%',
  },
  deviceInput: {
    // alignSelf: 'flex-end',
    // flex: '1 0 auto',
  }
}));

type DeviceCardProps = IDeviceCardProps;

interface IDeviceCardProps {
  data: DeviceFragment
  onClick: () => any;
  showEquipmentName?: boolean;
}

interface IDeviceCardDefaultInputProps {
  formatter?: (value: number) => string;
  type: string;
  deviceId: string;
  port: string;
}

type DeviceCardDefaultInputProps = IDeviceCardDefaultInputProps

const defaultFormatter = (v: number) => String(v)

export const DeviceCardDefaultInput: React.FC<DeviceCardDefaultInputProps> = (props) => {
  const liveReading = useLiveReading(props.deviceId, props.port)

  return (<>{liveReading.currentReading}</>)
}

interface IDeviceCardDefaultOutputProps {
  readOnly?: boolean;
  mini?: boolean;
}

type DeviceCardDefaultOutputProps = IDeviceCardDefaultOutputProps & IEquipmentControls
export const DeviceCardDefaultOutput: React.FC<DeviceCardDefaultOutputProps> = (props) => {
  return (
    <>
      {/* <EquipmentControls {...props} /> */}
    </>
  )
}

const DEFAULT_DEVICE_TYPE = "Temperature"

export const DeviceCard: React.FC<DeviceCardProps> = (props) => {
  const classes = useStyles();
  const liveReading = useLiveReading(props.data.deviceId || "")

  const liveReadings = [
    useLiveReading(props.data.inputs[0]?.device?.id ?? "", props.data.inputs[0]?.port ?? ""),
    useLiveReading(props.data.inputs[1]?.device?.id ?? "", props.data.inputs[1]?.port ?? ""),
    useLiveReading(props.data.inputs[2]?.device?.id ?? "", props.data.inputs[2]?.port ?? ""),
    useLiveReading(props.data.inputs[3]?.device?.id ?? "", props.data.inputs[3]?.port ?? ""),
  ]


  let installedInto = null
  let installedIntoId = ""

  switch (props.data.installation.installedTo.__typename) {
    case "EquipmentInterfaceInstanceInstallationTarget":
      installedInto = <span>{props.data.installation.installedTo.equipmentInterfaceInstance.equipmentInstance.name || props.data.installation.installedTo.equipmentInterfaceInstance.equipmentInstance.equipment.name}</span>
      installedIntoId = props.data.installation.installedTo.equipmentInterfaceInstance.equipmentInstance.installations[0].id
      break;
    case "LocationInstallationTarget":
      installedInto = <span />
      break;
  }

  return (
    <Card className={classes.deviceCard} variant="outlined">
      <CardActionArea className={classes.deviceAction} onClick={props.onClick}>
        <CardContent className={classes.deviceContent}>
          <Box className={classes.deviceHeader}>
            <Box className={classes.deviceDetails}>
              <Typography className={classes.deviceType} variant="body2" display="block" color="textSecondary">
                {props.data.installation.instance.equipment.name}
              </Typography>
              {/* 
                Chris ToDo: 
                When the devices page is grouped by "none" (aka showing all devices)
                then we should show the equipment name below. 
                
                If the user turns on group by equipment, we should hide the name below.
               */}
              {installedIntoId && (
                <Typography className={classes.installEquipment} variant="body2" color="textPrimary">
                  {installedInto || " - "}
                </Typography>
              )}
              <Typography variant="h6" component="h3">
                {props.data.title}
              </Typography>
            </Box>
            {/* <Box className={classes.deviceIcon}>
              <WirelessIndicator strength={WirelessStrength.High} />
            </Box> */}
          </Box>
          <Box className={classes.deviceReadings}>
            <Box className={classes.deviceInput}>
              <Typography variant="h5">
                <ValueRange
                  values={liveReadings.map(r => r.currentReading).filter(v => !!v) as number[]}
                  formatter={fahrenheitTemperatureFormatter}
                />
              </Typography>
            </Box>
            <Box>
              <EquipmentControlChip deviceId={props.data.deviceId || ""}></EquipmentControlChip>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card >
  )
};

type ValueRangeProps = IValueRangeProps

interface IValueRangeProps {
  values: number[];
  formatter: (v: number) => string;
}

export const ValueRange = (props: ValueRangeProps) => {
  const { values, formatter } = props
  const min = Math.min(...values.filter(v => !!v))
  const max = Math.max(...values.filter(v => !!v))

  if (values.length === 0) {
    return <NoData />
  }

  let rangeValues = []
  if (values.length === 1) {
    rangeValues = [min]
  } else {
    if (min === max) {
      rangeValues = [min]
    } else {
      rangeValues = [min, max]
    }
  }

  const formattedValues = rangeValues
    .filter(v => !!v)
    .map(fahrenheitTemperatureFormatter)
    .join("-")

  return (<>{formattedValues}</>)
}

export const NoData = () => (
  <>
    <Tooltip title="No live data" arrow>
      <ErrorOutlineIcon />
    </Tooltip>
  </>
)