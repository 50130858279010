import * as React from "react"
import clsx from 'clsx';
import { makeStyles, Box } from "@material-ui/core"
import { cpTheme } from "../../theme/cpTheme";


const useStyles = makeStyles( theme => ({
  sidebar: {
    background: theme.palette.grey[200],
    height: '100vh',
    width: '220px',
    flex: '0 0 auto',
    overflowY: 'scroll',
    padding: theme.spacing(4, 2),
  },
}));

export interface ISidebarProps {
  children?: React.ReactNode | string,
  className?: string,
}

type SidebarProps = ISidebarProps

export const Sidebar: React.FC<SidebarProps> = ({
  className, ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(
      classes.sidebar,
      className,
    )}
      {...props}
    >
      {props.children}
    </Box>
  )
}
