import * as React from "react";
import { useTenant } from "../../hooks/tenant";
import { CircularProgress } from "@material-ui/core";

type AdminTenantBasicInformationProps = IAdminTenantBasicInformationProps;

interface IAdminTenantBasicInformationProps {
  tenantId: string;
}

export const AdminTenantBasicInformation: React.FC<AdminTenantBasicInformationProps> = (props) => {
  const { data, loading } = useTenant(props.tenantId)

  if (loading) return <CircularProgress />

  return (
    <div>
      <strong>Name:  </strong>{data?.tenant.name}
    </div>
  )
}