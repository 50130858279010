import * as React from "react";
import { useAppState } from "../../hooks/appState";
import { useTenant } from "../../hooks/tenant";
import {
  Container,
  Box,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button, CircularProgress, Select, MenuItem, makeStyles
} from "@material-ui/core";
import { cpTheme } from "../../theme/cpTheme";
import { MainContent } from "../layout/MainContent";
import { Sidebar } from "../layout/Sidebar";
import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";
import { LayoutWithSidebarAndBody } from "../layout-with-sidebar-and-body";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { OrganizationSettings, OrganizationSettingsVariables } from "./__generated__/OrganizationSettings";
import { useHistory } from "react-router";
import { AccountTenantAssociationRoleSelector } from "../account-tenant-association-role-selector";
import { InviteNewUserDialog } from "../invite-new-user-dialog";
import { Panel } from "../panel";

export const ORGANIZATION_SETTINGS_QUERY = gql`
query OrganizationSettings($tenantId: String!) {
  tenant(id: $tenantId) {
    id
    name
    accounts {
      id
      role
      account {
        id
        firstName
        lastName
        emailAddress
      }
    }
  }
}
`

const useStyles = makeStyles( theme => ({
  content: {
    '& .MuiTextField-root': {
      width: '450px',
    }
  },
  button: {
    margin: theme.spacing(0, 2),
  },
}));

const NotificationPreferencesSelector: React.FC = (props) => {
  return (
    <Select
      value={"all"}
      disabled={true}
      placeholder="Notification preferences"
      onChange={(e) => { }}
    >
      <MenuItem value={"all"}>All Notifications</MenuItem>
    </Select>
  )
}

const PermissionSelection: React.FC = (props) => {
  return (
    <select>
      <option>Owner</option>
      <option>Admin</option>
      <option>Member</option>
    </select>
  )
}

export const PageOrganizationSettings: React.FC = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const { tenantId } = useAppState()
  const [inviteNewUserDialogOpen, setInviteNewUserDialogOpen] = React.useState(false)

  const { data, loading } = useQuery<OrganizationSettings, OrganizationSettingsVariables>(
    ORGANIZATION_SETTINGS_QUERY,
    {
      variables: {
        tenantId: tenantId || ""
      }
    }
  )

  if (loading) return <CircularProgress />

  const accountRows = data ? (data.tenant.accounts.map(a => (
    <TableRow key={a.id}>
      <TableCell component="th" scope="row">
        {a.account.firstName} {a.account.lastName}
      </TableCell>
      <TableCell>{a.account.emailAddress}</TableCell>
      <TableCell>
        <NotificationPreferencesSelector />
      </TableCell>
      <TableCell>
        <AccountTenantAssociationRoleSelector disabled onChange={() => { }} value={a.role} />
      </TableCell>
    </TableRow>
  ))) : []


  return (
    <LayoutWithSidebarAndBody
      sidebarContents={
        <>
          <Typography variant="body2">

          </Typography>
          <Typography variant="body1" component="h1">
            <b>Organization Settings</b>
          </Typography>
        </>
      }
      bodyContents={
        data ?
        <>
          <Panel 
            body={
              <MainContent className={classes.content}>   
                <InviteNewUserDialog
                  open={inviteNewUserDialogOpen}
                  onClose={() => setInviteNewUserDialogOpen(false)}
                  onSubmit={() => { }}
                />
                <Box pb={6}>
                  <Typography variant="h6" gutterBottom>
                    General Information
                  </Typography>
                  <TextField
                    id="organization-name"
                    label="Organization Name"
                    placeholder="Your Org Here"
                    variant="filled"
                    value={data.tenant.name}
                  />
                </Box>
                <Box pb={6}>
                  <Typography variant="h6">
                    <Box component="span" mr={2}>Users </Box>
                    {/* <Button onClick={() => setInviteNewUserDialogOpen(true)} variant="outlined" color="primary">
                      Invite New User
                    </Button> */}
                  </Typography>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Notifications</TableCell>
                        <TableCell>Permissions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accountRows}
                    </TableBody>
                  </Table>
                </Box>
              </MainContent>
            }
          /> 
        </> : <span />
      }
    />
  )
}