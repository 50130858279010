import * as React from "react";
import { Container, Typography, Box, Divider, Button, makeStyles, CircularProgress, Checkbox, Table, TableRow, TableCell, Card, Grid } from "@material-ui/core";
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend } from "recharts";
import { cpTheme, getGraphColor } from "../../theme/cpTheme";
import { EquipmentControls } from "../equipment/equipment-controls";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { EquipmentDrawerMainContentsWithReadingsQuery, EquipmentDrawerMainContentsWithReadingsQueryVariables } from "./__generated__/EquipmentDrawerMainContentsWithReadingsQuery";
import { fahrenheitTemperatureFormatter } from "../_page-device";
import { EquipmentDrawerQuery } from "../equipment-drawer/__generated__/EquipmentDrawerQuery";
import { TimeSeriesDataPoint, LineGraph } from "../line-graph";
import { ChartLegend } from "../chart-legend";
import { BreakpointValues } from "@material-ui/core/styles/createBreakpoints";
import { useLiveReading } from "../physical-devices/temperature-controller";
import { SensorReadingRow, SensorReadings } from "../sensor-readings";

const EQUIPMENT_DRAWER_MAIN_CONTENTS_WITH_READINGS_QUERY = gql`
  query EquipmentDrawerMainContentsWithReadingsQuery(
    $installationId: String!
  ) {
    installation(id: $installationId) {
      id
      facilityView {
        title
        deviceId
        inputs {
          device {
            id
          }
          port
          installation {
            id
            readings {
              created
              value
              secondsBucket
              differenceFromNow
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
  equipmentDrawerActions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  controlInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'baseline',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(0, 0, 1),
  },
  chart: {
    width: '100%',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 0, 1),
  },
  controls: {
    width: '100%',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 0, 1),
  },
  automation: {
    width: '100%',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 0, 1),
  },
  //
  allControls: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  allSensors: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  // Single Device
  deviceTitle: {
    padding: theme.spacing(1),
  },


}));


type EquipmentDrawerMainContentsProps = IEquipmentDrawerMainContentsProps;

interface IEquipmentDrawerMainContentsProps {
  data: EquipmentDrawerQuery
  deviceId: string;
}

export const EquipmentDrawerMainContents: React.FC<EquipmentDrawerMainContentsProps> = (props) => {
  const classes = useStyles()
  const data = props.data

  const names = props.data.installation.facilityView?.inputs.map(i => i.installation.name) ?? []


  return (
    <>
      <Box mb={2}>
        <Typography variant="body2" display="block" color="textSecondary">
          Equipment
        </Typography>
      </Box>

      <Typography variant="h5">
        {data.installation.facilityView?.title || " "}
      </Typography>

      <Box className={classes.allControls}>
        {((data.installation.facilityView?.outputs?.length || 0) > 0) && (
          <EquipmentControls deviceId={data.installation?.facilityView?.deviceId ?? ""} />
        )}
      </Box>

      <Box className={classes.allSensors}>
        <Box>
          <SensorReadings>
            {data.installation.facilityView?.inputs.map(input => (
              <>
                {(input.device && input.port) && (
                  <SensorReadingRow
                    title={input.installation.name || "Unnamed sensor"}
                    deviceId={input.device.id}
                    port={input.port}
                  ></SensorReadingRow>
                )}
              </>
            ))}
          </SensorReadings>
        </Box>
      </Box>

      {/* <EquipmentDrawerMainContentsChart {...{ ...props, installationId }} /> */}

      {/* <Divider />

      <Box className={classes.automation}>
        <EquipmentAutomation {...props} />
      </Box> */}
    </>
  )
}

const EquipmentAutomation: React.FC = (props) => {
  return (
    <>
      {/* <Typography variant="h6">Automation</Typography>
      <Typography variant="body2">Automatically perform actions based on conditions like date/time, elapsed time, temperature, and more.</Typography>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
      >
        Automation
        </Button> */}
    </>
  )
}

export interface IChartLegendSeries {
  key: string;
  color: string;
  title: string;
  currentValue?: number;
  formatter?: (v: number) => string;
}

interface IEquipmentDrawerMainContentsChartProps {
  installationId: string;
}

type EquipmentDrawerMainContentsChartProps =
  IEquipmentDrawerMainContentsChartProps &
  IEquipmentDrawerMainContentsProps

const EquipmentDrawerMainContentsChart: React.FC<EquipmentDrawerMainContentsChartProps> = (props) => {
  const { installationId } = props;

  const [chartData, setChartData] = React.useState<TimeSeriesDataPoint[]>()
  const [series, setSeries] = React.useState<IChartLegendSeries[]>()

  const { data, loading } = useQuery<EquipmentDrawerMainContentsWithReadingsQuery, EquipmentDrawerMainContentsWithReadingsQueryVariables>(
    EQUIPMENT_DRAWER_MAIN_CONTENTS_WITH_READINGS_QUERY, {
    variables: {
      installationId
    }
  })

  React.useEffect(
    () => {
      if (!data) return;


      const secondsGroups = data.installation.facilityView ?
        _.groupBy(
          (
            data.installation.facilityView?.inputs
              .map(input => {
                const seriesInstallationId = input.installation.id

                return input.installation.readings.map(reading => {
                  return {
                    secondsBucket: reading.secondsBucket,
                    [seriesInstallationId]: reading.value
                  }
                })
              }).flat()
          ) || [],
          (item) => item.secondsBucket
        ) : {}

      setChartData(
        Object.keys(secondsGroups)
          .map(group => (
            {
              values: secondsGroups[group]
                .reduce((acc, curr) => ({
                  ...acc,
                  ...curr
                })),
              time: parseInt(group, 10)
            }
          ))
      )

      setSeries(
        data.installation.facilityView?.inputs?.map((input, index) => ({
          key: input.installation.id,
          color: getGraphColor(index),
          title: `${input.installation.id}`,
          currentValue: 0,
          formatter: fahrenheitTemperatureFormatter
        })) || []
      )

      return undefined;
    }, [data]
  )

  if (!data || loading) return <CircularProgress />

  if (!data || loading || !series || !chartData) return <CircularProgress />

  return (
    <Box>
      <LineGraph series={series} data={chartData} />
    </Box>
  )
};