import * as React from "react";
import { useTenant } from "../../hooks/tenant";
import { CircularProgress, Table, TableHead, TableRow, Card, CardContent, CardActions, Button, Typography, Box, Grid, TableCell, TableBody } from "@material-ui/core";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { AdminTenantsInstallationsTableQuery, AdminTenantsInstallationsTableQueryVariables } from "./__generated__/AdminTenantsInstallationsTableQuery";
import moment from "moment";
import { IdLink } from "../id-link";
import { TreeView, TreeItem } from "@material-ui/lab";
import { PinDrop, SettingsApplications } from "@material-ui/icons"

type AdminTenantInstallationsTableProps = IAdminTenantInstallationsTableProps;

interface IAdminTenantInstallationsTableProps {
  tenantId: string;
}

export const ADMIN_TENANT_INSTALLATIONS_TABLE_QUERY = gql`
  query AdminTenantsInstallationsTableQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      id
      installations {
        id
        name
        created
        instance {
          id
          name
          equipment {
            id
            name
          }
        }
        installationTarget {
          id
          ...on EquipmentInterfaceInstanceInstallationTarget {
            equipmentInterfaceInstance {
              id
              name
              equipmentInstance {
                id
                name
                equipment {
                  id
                  name
                }
              }
              equipmentInterface {
                id
                name
              }
            }
          }
          ...on LocationInstallationTarget {
            location {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const AdminTenantInstallationsTable: React.FC<AdminTenantInstallationsTableProps> = (props) => {
  const tenant = useTenant(props.tenantId)

  const installations = useQuery<AdminTenantsInstallationsTableQuery, AdminTenantsInstallationsTableQueryVariables>(
    ADMIN_TENANT_INSTALLATIONS_TABLE_QUERY,
    {
      variables: {
        tenantId: props.tenantId
      },
      fetchPolicy: "no-cache"
    })

  if (tenant.loading || installations.loading) return <CircularProgress />

  return (
    <>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Installation ID</strong></TableCell>
              <TableCell><strong>Created</strong></TableCell>
              <TableCell><strong>Installation Name</strong></TableCell>
              <TableCell><strong>Equipment Instance</strong></TableCell>
              <TableCell><strong>Installation Target</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              installations.data?.tenant.installations.map(device => (
                <TableRow key={device.id}>
                  <TableCell><IdLink value={device.id} /></TableCell>
                  <TableCell>{device.created}</TableCell>
                  <TableCell>{device.name ?? "(unnamed)"}</TableCell>
                  <TableCell>
                    <IdLink value={device.instance.id} />
                    ({device.instance.name ?? `Unnamed - ${device.instance.equipment.name}`}
                  </TableCell>
                  {
                    (device.installationTarget.__typename === "EquipmentInterfaceInstanceInstallationTarget") && (
                      <TableCell>
                        <SettingsApplications />
                        <TreeView
                          expanded={
                            [device.installationTarget.equipmentInterfaceInstance.equipmentInstance.id]
                          }
                          onNodeToggle={() => { }}>
                          <TreeItem
                            nodeId={device.installationTarget.equipmentInterfaceInstance.equipmentInstance.id}
                            label={
                              <>
                                <IdLink value={device.installationTarget.equipmentInterfaceInstance.equipmentInstance.id} />
                                ({device.installationTarget.equipmentInterfaceInstance.equipmentInstance.name ?? `Unnamed - ${device.installationTarget.equipmentInterfaceInstance.equipmentInstance.equipment.name}`})
                            </>
                            }
                          >
                            <TreeItem
                              nodeId={device.installationTarget.equipmentInterfaceInstance.id}
                              label={
                                <>
                                  <IdLink value={device.installationTarget.equipmentInterfaceInstance.id} />
                                  ({device.installationTarget.equipmentInterfaceInstance.name ??
                                    device.installationTarget.equipmentInterfaceInstance.equipmentInterface.name})
                              </>
                              }></TreeItem>

                          </TreeItem>
                        </TreeView>
                      </TableCell>
                    )
                  }
                  {
                    (device.installationTarget.__typename === "LocationInstallationTarget") && (
                      <TableCell>
                        <Box flex flexDirection="row">
                          <PinDrop />
                          <IdLink value={device.installationTarget.location.id} />
                        </Box>
                        {device.installationTarget.location.name}
                      </TableCell>
                    )
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Card >
    </>
  )
}