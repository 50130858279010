import * as React from "react";
import { Drawer, makeStyles } from "@material-ui/core";

type RightDrawerModalProps = IRightDrawerModalProps;

interface IRightDrawerModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles( theme => ({
  drawerPaper: {
    maxWidth: "100vw",
    width: "450px",
    boxShadow: theme.shadows[10],
    
    [theme.breakpoints.down('xs')]: {
      width: "100vw",
    },
  },
})); 

export const RightDrawerModal: React.FC<RightDrawerModalProps> = (props) => {
  const classes = useStyles()

  return (
    <Drawer
      open={props.open}
      anchor="right"
      // variant="persistent"
      onClose={() => props.onClose()}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {props.children}
    </Drawer>
  )
};