import * as React from 'react';
import { ICardProps } from '../card/Card';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

export interface ICardGridProps {
  cards: React.ReactElement<ICardProps>[]
}

export default function CardGrid(props: ICardGridProps) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  });

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 3}
    >
      {props.cards.map((card, index) => (
        <Grid item xs={6} sm={4} md={6} lg={4} xl={3} key={String(index)}>
          {card}
        </Grid>
      ))}
    </Grid>
  );
}   
