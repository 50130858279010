import * as React from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { AdminTenantAccountsQuery, AdminTenantAccountsQueryVariables } from "./__generated__/AdminTenantAccountsQuery";
import { CircularProgress, TableHead, TableRow, Table, TableContainer, Paper, TableCell, TableBody, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Card, Typography, Link } from "@material-ui/core";
import { AccountTenantAssociationRoleSelector } from "../account-tenant-association-role-selector";
import { AccountTenantRoleType } from "../../__generated/globalTypes";
import { UpdateAccountTenantAssociation, UpdateAccountTenantAssociationVariables } from "./__generated__/UpdateAccountTenantAssociation";

type AdminTenantAccountsTableProps = IAdminTenantAccountsTableProps;

interface IAdminTenantAccountsTableProps {
  tenantId: string;
}

export const ADMIN_TENANT_ACCOUNTS_TABLE = gql`
  query AdminTenantAccountsQuery(
    $tenantId: String!
  ) {
    tenant(id: $tenantId) {
      id
      name
      accounts {
        id
        role
        account {
          id
          emailAddress
          firstName
          lastName
        }
      }
    }
  }
`

interface IPendingRoleModification {
  id: string;
  name: string;
  oldRole: AccountTenantRoleType
  newRole: AccountTenantRoleType
}

type PendingRoleModification = IPendingRoleModification

const UPDATE_ACCOUNT_TENANT_ASSOCIATION_MUTATION = gql`
  mutation UpdateAccountTenantAssociation(
    $id: String!
    $newRole: AccountTenantRoleType!
  ) {
    updateAccountTenantAssociation(input: {
      id: $id
      role: { newValue: $newRole }
    }) {
      accountTenantAssociation {
        id
        role
        account {
          id
        }
      }
    }
  }
`;

export const AdminTenantAccountsTable: React.FC<AdminTenantAccountsTableProps> = (props) => {
  const accounts = useQuery<AdminTenantAccountsQuery, AdminTenantAccountsQueryVariables>(
    ADMIN_TENANT_ACCOUNTS_TABLE, {
    variables: {
      tenantId: props.tenantId
    }
  })

  const [mutateAccountTenantAssociation, { loading: saving }] = useMutation<UpdateAccountTenantAssociation, UpdateAccountTenantAssociationVariables>(UPDATE_ACCOUNT_TENANT_ASSOCIATION_MUTATION)

  const [pendingRoleModification, setPendingRoleModification] = React.useState<PendingRoleModification | null>(null)

  const commitPendingRoleModification = async () => {
    if (pendingRoleModification) {
      await mutateAccountTenantAssociation({
        variables: {
          id: pendingRoleModification.id,
          newRole: pendingRoleModification.newRole
        }
      })
      accounts.refetch()
      setPendingRoleModification(null)
    }
  }

  if (accounts.loading) return <CircularProgress />

  return (
    <>
      <Box>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small"><strong>Account ID</strong></TableCell>
                <TableCell><strong>First name</strong></TableCell>
                <TableCell><strong>Last name</strong></TableCell>
                <TableCell><strong>Email address</strong></TableCell>
                <TableCell><strong>Role</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.data?.tenant.accounts.map(association => (
                <TableRow key={association.id}>
                  <TableCell style={{ maxWidth: 100 }}>
                    <Link href="#" onClick={() => { }}>
                      <Typography noWrap>{association.account.id}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell>{association.account.firstName}</TableCell>
                  <TableCell >{association.account.lastName}</TableCell>
                  <TableCell>{association.account.emailAddress}</TableCell>
                  <TableCell >
                    <AccountTenantAssociationRoleSelector
                      value={association.role}
                      onChange={(newRole) =>
                        setPendingRoleModification({
                          id: association.id,
                          name: `${association.account.firstName} ${association.account.lastName}`,
                          oldRole: association.role,
                          newRole
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Box>
      <Dialog
        open={!!pendingRoleModification}
        onClose={() => setPendingRoleModification(null)}
      >
        <DialogTitle>{`Change ${pendingRoleModification?.name}'s role?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to change {pendingRoleModification?.name}'s role from {pendingRoleModification?.oldRole} to {pendingRoleModification?.newRole}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPendingRoleModification(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => commitPendingRoleModification()} color="primary" autoFocus>
            {saving ? <CircularProgress /> : "Change Role"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}