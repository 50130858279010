import * as React from "react";

type BeltlineDevicesViewProps = IBeltlineDevicesViewProps;

interface IBeltlineDevicesViewProps {

}

export const BeltlineDevicesView: React.FC<BeltlineDevicesViewProps> = (props) => {
  return (
    <span/>
  )
}