import * as React from "react";
import { Grid, Paper, Typography, AppBar, Divider, Box, ThemeProvider, makeStyles } from "@material-ui/core";
import { TextField } from "../text-field/TextField";
import { Panel } from "../panel";
import { HeaderDefault } from "../header-default";
import { MainContent } from "../layout/MainContent";
import { cpThemeDark, cpTheme } from "../../theme/cpTheme";


// Type scale
interface ITypeScaleProps {
  children?: React.ReactNode | string,
}
type TypeScaleProps = ITypeScaleProps

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  bounding: {
    border: '1px solid',
    borderColor: theme.palette.divider,
    padding: theme.spacing(5),
    background: theme.palette.background.paper,
  },
}));

const TypeScale: React.FC<TypeScaleProps> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bounding}>
      
      <Box className={classes.root} my={5}>
        <Divider />
        <Typography gutterBottom>Input <br/><br/> &nbsp;</Typography>
          
        <TextField
          id="filled-uncontrolled"
          label="Uncontrolled"
          margin="none"
          variant="outlined"
        />
        <TextField
          id="filled-uncontrolled"
          label="Test"
          margin="none"
          variant="outlined"
          defaultValue="Hello World"
        />
        <br/><br/>
        <TextField
          id="filled-uncontrolled"
          label="Uncontrolled"
          margin="none"
          variant="filled"
        />
        <TextField
          id="filled-uncontrolled"
          label="Uncontrolled"
          margin="none"
          variant="filled"
          defaultValue="Hello World"
        />
      </Box>

      <Divider />
      <Box mt={1} mb={5}>
        <Typography gutterBottom>Typography</Typography>
      </Box>
      
      <Grid container spacing={3}>
        
        {/* Default: no padding */}
        <Grid item xs={12} md={6}>
          <Typography variant="caption" paragraph>
            This illustrates the type scale WITHOUT bottom gutters, margins, or padding.
          </Typography>
          <Typography variant="overline">
            Overline
          </Typography>
          <Typography variant="h1">
            h1. Heading
          </Typography>
          <Typography variant="h2">
            h2. Heading
          </Typography>
          <Typography variant="h3">
            h3. Heading
          </Typography>
          <Typography variant="h4">
            h4. Heading
          </Typography>
          <Typography variant="h5">
            h5. Heading
          </Typography>
          <Typography variant="h6">
            h6. Heading
          </Typography>
          <Typography variant="overline">
            Overline
          </Typography>
          <Typography variant="subtitle1">
            Subtitle 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          </Typography>
          <Typography variant="subtitle2">
            Subtitle 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          </Typography>
          <Typography variant="body1">
            Body 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="body2">
            Body 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="caption" component="p">
            Caption. Component p. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Grid>
      
        {/* Added Padding */}
        <Grid item xs={12} md={6}>
          <Typography variant="caption" paragraph>
            This illustrates the type scale WITH added bottom gutters, margins, or padding.
          </Typography>
          <Typography variant="overline" gutterBottom>
            Overline
          </Typography>
          <Typography variant="h1" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="overline" gutterBottom display="block">
            Overline
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Subtitle 1. Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          </Typography>
          <Typography variant="subtitle2" paragraph>
            Subtitle 2. Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          </Typography>
          <Typography variant="body1" paragraph>
            Body 1. Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="body2" paragraph>
            Body 2. Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Typography variant="caption" paragraph>
            Caption. Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Grid>
      
      </Grid>
    </Box>
  )
}



export const DesignTestPage: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Panel
      header={
        <HeaderDefault
          title='Design Guide'
          actionsSection='Actions'
        />
      }
      body={
        <>
          <MainContent className={classes.root}>
          
            {/* <AppBar position="sticky">
              <Typography>Sticky App Bar</Typography>
            </AppBar> */}

            <Box my={5}>
              <Divider />
              <Typography gutterBottom>Input <br/><br/> &nbsp;</Typography>
                
              <TextField
                id="filled-uncontrolled"
                label="Uncontrolled"
                margin="none"
                variant="outlined"
              />
              <TextField
                id="filled-uncontrolled"
                label="Test"
                margin="none"
                variant="outlined"
                defaultValue="Hello World"
              />
              <br/><br/>
              <TextField
                id="filled-uncontrolled"
                label="Uncontrolled"
                margin="none"
                variant="filled"
              />
              <TextField
                id="filled-uncontrolled"
                label="Uncontrolled"
                margin="none"
                variant="filled"
                defaultValue="Hello World"
              />
            </Box>

            <ThemeProvider theme={cpThemeDark}>
              <Paper>
                <Box my={5}>
                  <TypeScale/>
                </Box>
              </Paper>
            </ThemeProvider>

            <Box my={5}>
              <TypeScale/>
            </Box>

            <Box my={5}>
              <Divider />
              <Typography>Input</Typography>
                
              <TextField
                id="filled-uncontrolled"
                label="Uncontrolled"
                margin="none"
                variant="outlined"
              />
            </Box>

            <Box my={5}>
              <Divider />
              <Typography>Grid</Typography>
            
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper>xs=12</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper>xs=6</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper>xs=6</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper>xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper>xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper>xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper>xs=3</Paper>
                </Grid>
              </Grid>
            </Box>

          </MainContent>
        </>
      }
    />
  )
}