import * as React from "react"
import clsx from 'clsx';
import { makeStyles, Box } from "@material-ui/core"

const panelStyles = makeStyles( theme => ({
  panelContainer: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: "column"
  },
  panelHeader: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  panelBody: {
    overflowY: 'scroll',
    flex: '1 1 0%'
  },
  // If variant = overflowX is applied
  overflowX: {
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  panelFooter: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,
  },
  // If 'raisedFooter' is applied
  raisedFooter: {
    borderTop: 'none',
    boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
  },
}));


// Panel Container
interface IPanelContainerProps {
  children?: React.ReactNode | string,
  className?: string,
}

type PanelContainerProps = IPanelContainerProps

const PanelContainer: React.FC<PanelContainerProps> = ({
  className, ...props
}) => {
  const classes = panelStyles();

  return (
    <Box className={clsx(
      classes.panelContainer, 
      className,
    )}
      {...props}>
      {props.children}
    </Box>
  )
}

// Panel Header 
interface IPanelHeaderProps {
  children?: React.ReactNode | string,
  className?: string,
}
type PanelHeaderProps = IPanelHeaderProps

const PanelHeader: React.FC<PanelHeaderProps> = ({
  className, ...props
}) => {
  const classes = panelStyles();

  return (
    <Box 
      className={clsx(
        classes.panelHeader,
        className,
      )}
      {...props}
    >
      {props.children}
    </Box>
  )
}


// Panel Body 
interface IPanelBodyProps {
  children?: React.ReactNode | string,
  className?: string,
}
type PanelBodyProps = IPanelBodyProps

const PanelBody: React.FC<PanelBodyProps> = ({
  className, ...props
}) => {
  const classes = panelStyles();

  return (
    <Box 
      className={clsx(
        classes.panelBody,
        className,
      )}
      {...props}
    >
      {props.children}
    </Box>
  )
}



// Panel Footer 

interface IPanelFooterProps {
  children?: React.ReactNode | string,
  className?: string,
}
type PanelFooterProps = IPanelFooterProps

const PanelFooter: React.FC<PanelFooterProps> = ({
  className, ...props
}) => {
  const classes = panelStyles();

  return (
    <Box className={clsx(
      classes.panelFooter,
      className,
    )}
      {...props}
    >
      {props.children}
    </Box>
  )
}


// Composed Panel

interface IPanelProps {
  body?: React.ReactNode,
  header?: React.ReactNode,
  footer?: React.ReactNode,
  className?: string,
  variant?: 'overflowX',
  footerVariant?: 'raised',
}
type PanelProps = IPanelProps

export const Panel: React.FC<PanelProps> = ({
  className, variant, footerVariant, ...props
}) => {
  const classes = panelStyles();

  return (
    <PanelContainer className={clsx(
      className,
    )}>
      {props.header ? (
        <PanelHeader>
          {props.header}
        </PanelHeader>
      ) : null}
      <PanelBody 
        className={clsx(
          {[classes.overflowX]: variant === 'overflowX',},
          className,
        )}
      >
        {props.body}
      </PanelBody>
      {props.footer ? (
        <PanelFooter
          className={clsx(
            {[classes.raisedFooter]: footerVariant === 'raised',},
            className,
          )}
        >
          {props.footer}
        </PanelFooter>
      ) : null}
    </PanelContainer>
  )
}
