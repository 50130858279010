import * as React from "react";
import { AppStateProvider, useAppState } from "./hooks/appState"
import { history } from "./history"
import { ApolloProvider, useMutation } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { API_BASE_URL } from "./config";
import CssBaseline from '@material-ui/core/CssBaseline';
import { cpTheme, cpThemeDark } from "./theme/cpTheme";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ThemeProvider } from "@material-ui/core";
import { LiveReadingsProvider } from "./hooks/liveReadings";
import { UPDATE_EXPANDED_SIDEBAR_MUTATION } from "./components/nav-global/NavBarGlobal";
import { UpdateExpandedSidebarMutation, UpdateExpandedSidebarMutationVariables } from "./components/nav-global/__generated__/UpdateExpandedSidebarMutation";

export const AppProviders: React.FC = (props) => {
  const theme = process.env.REACT_APP_IS_TOUCH === "true" ? cpThemeDark : cpTheme

  return (
    <LiveReadingsProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryParamProvider history={history as any} ReactRouterRoute={Route}>
            <AppStateProvider>
              <CssBaseline>
                <AppApolloProvider>
                  <AppNavigationProvider>
                    {props.children}
                  </AppNavigationProvider>
                </AppApolloProvider>
              </CssBaseline>
            </AppStateProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </ThemeProvider>
    </LiveReadingsProvider>
  )
}

interface IAppNavigationContext{
  navDesktopOpen: boolean;
  handleDesktopDrawerToggle: () => any;
};

const AppNavigationContext = React.createContext<IAppNavigationContext>({
  navDesktopOpen: false,
  handleDesktopDrawerToggle: () => { }
});

export const useNavigationProvider = () => {
  const currentTenantContext = React.useContext(AppNavigationContext)
  return currentTenantContext
};

export const AppNavigationProvider: React.FC = (props) => {

  const {viewer} = useAppState();

  const [mutate, { loading: saving }] = useMutation<UpdateExpandedSidebarMutation, UpdateExpandedSidebarMutationVariables>(UPDATE_EXPANDED_SIDEBAR_MUTATION)
  
  const [navDesktopOpen, _setDesktopOpen] = React.useState(false);
  const handleDesktopDrawerToggle = () => {
    setDesktopOpen(!navDesktopOpen);

    if (viewer) {
      mutate({
        variables: {
          id: viewer.id,
          showSidebarExpanded: !navDesktopOpen
        }
      })
    }
  };

  React.useEffect(
    () => {
      _setDesktopOpen(viewer?.showSidebarExpanded ?? false)
    }, [viewer]
  )

  const setDesktopOpen = (value: boolean) => {
    // console.log(`Persist setsidebarexpanded = ${!navDesktopOpen}`)
    _setDesktopOpen(!navDesktopOpen)
  }
  
  return (
    <AppNavigationContext.Provider value={
      {
        navDesktopOpen,
        handleDesktopDrawerToggle
      }
    }>
      {props.children}
    </AppNavigationContext.Provider>
  )
}

export const AppApolloProvider: React.FC = (props) => {
  const { sessionId, token } = useAppState()

  let headers: any = {}

  headers["X-Connected-Proof-Session-Id"] = sessionId
  headers["X-Connected-Proof-Token"] = token

  const client = new ApolloClient({
    uri: `${API_BASE_URL}/graphql`,
    headers
  });

  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  )
}