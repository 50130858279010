import * as React from "react";
import { ModalDialog } from "../modal-dialog";
import { DialogContentText, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { CreateTenantMutation, CreateTenantMutationVariables } from "./__generated__/CreateTenantMutation";

type CreateNewOrganizationDialogProps = ICreateNewOrganizationDialogProps;

interface ICreateNewOrganizationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const CREATE_TENANT_MUTATION = gql`
  mutation CreateTenantMutation(
    $name: String!
  ) {
    createTenant(input: {
      name: $name
    }) {
      ... on CreateTenantSuccessfulResponse {
        tenant {
          id
          name
        }
      }
    }
  }
`

export const CreateNewOrganizationDialog: React.FC<CreateNewOrganizationDialogProps> = (props) => {
  const [orgName, setOrgName] = React.useState("")
  const [type, setType] = React.useState("")

  const [mutate, { data, loading }] = useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CREATE_TENANT_MUTATION)

  const submit = async () => {
    await mutate({
      variables: {
        name: orgName
      }
    })
    props.onSubmit()
    props.onClose()
  }

  return (
    <ModalDialog
      title={"Create new organization"}
      open={props.open}
      handleClose={props.onClose}
      handleSubmit={submit}
      submitText="Create org"
      content={
        <>
          <DialogContentText>
            You'll add users and setup the org after it's created.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            label="Org Name"
            fullWidth
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />

          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              style={{ width: 200 }}
              value={type}
              onChange={(e) => setType(e.target.value as string)}
            >
              <MenuItem value={"brewery"}>Brewery</MenuItem>
              <MenuItem value={"cidery"}>Cidery</MenuItem>
              <MenuItem value={"distillery"}>Distillery</MenuItem>
              <MenuItem value={"winery"}>Winery</MenuItem>
            </Select>
          </FormControl>
        </>
      }
    />
  )
}