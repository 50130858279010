import * as React from "react";
import { Redirect } from "react-router";

type SuperadminProps = ISuperadminProps;

interface ISuperadminProps {

}

export const Superadmin: React.FC<SuperadminProps> = (props) => {
  return (
    <Redirect to="/_superadmin/tenant" />
  )
}