import * as React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";

type IdLinkProps = IIdLinkProps;

interface IIdLinkProps {
  value: string;
  path?: string;
}

export const IdLink: React.FC<IdLinkProps> = (props) => {
  return (
    <Typography style={{ width: "100px" }} noWrap><NavLink to={props.path ?? "/"}>{props.value}</NavLink></Typography>
  )
}