import * as React from "react";
import { makeStyles, Box, Toolbar, Typography, Hidden, IconButton, Container, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Card, Button } from "@material-ui/core";
import { cpTheme } from "../../theme/cpTheme";
import { Panel } from "../panel";
import {
  CloseOutlined as CloseOutlinedIcon,
  ArrowBack as ArrowBackIcon
} from "@material-ui/icons"
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { EquipmentSettingsQuery, EquipmentSettingsQueryVariables } from "./__generated__/EquipmentSettingsQuery";
import { FormikConfig, Formik, FormikValues, FormikBag, FormikProps } from "formik";
import { useAppState } from "../../hooks/appState";
import { WiredInstallationCard } from "../card-installation/card-installation";
import { DeviceCard } from "../device-card";
import { DEVICE_FRAGMENT } from "../../fragments";
import { EquipmentDrawerQuery } from "../equipment-drawer/__generated__/EquipmentDrawerQuery";
import { EquipmentForm } from "../equipment-drawer";
import { history } from "../../history";


type EquipmentSettingsDrawerContentsProps = IEquipmentSettingsDrawerContentsProps;

interface IEquipmentSettingsDrawerContentsProps {
  data: EquipmentDrawerQuery;
  formikProps: FormikProps<EquipmentForm>
}

const useStyles = makeStyles( theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
      minWidth: '100%',
    },
  },
  section: {
    margin: theme.spacing(2, 0, 3),
  },
  type: {
    margin: theme.spacing(0, 0, 2),
  },
  equipmentDrawer: {
    backgroundColor: '#fff',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },

  // Device list
  deviceList: {
    listStyle: 'none',
    paddingLeft: 0,
    '&>li': {
      paddingBottom: theme.spacing(2),
    }
  },
}));


export const EquipmentSettingsDrawerContents: React.FC<EquipmentSettingsDrawerContentsProps> = (props) => {
  const classes = useStyles();
  const { data, formikProps } = props

  let installedDevicesSection = <span />

  if ((data.installation.facilityView?.installedDevices?.length ?? 0) > 0) {
    installedDevicesSection = (
      <ul className={classes.deviceList}>
        {data.installation.facilityView
          ?.installedDevices
          ?.map(installedDevice => (
            installedDevice && (
              <li>
                {/* <Button>Remove</Button> */}
                <DeviceCard data={installedDevice} onClick={() => window.location.href = "/devices"} />
              </li>
            )
          ))
        }
      </ul>
    )
  }

  return (
    <Box className={classes.root}>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.type}>
          <strong>General Equipment Settings</strong>
        </Typography>
        <div>
          <TextField
            label="Equipment name"
            variant="filled"
            value={formikProps.values.title}
            onChange={(e) => formikProps.setFieldValue("title", e.target.value)}
          />
        </div>
        <div>
          <FormControl variant="filled" disabled>
            <InputLabel id="demo-simple-select-outlined-label">
              Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={formikProps.values.type}
              onChange={(e) => formikProps.setFieldValue("type", e.target.value)}
            >
              {data.equipments.map(equipment => (
                <MenuItem value={equipment.id}>{equipment.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-outlined-label">
              Installation Location
            </InputLabel>
            <Select
              disabled
              onChange={(e) => formikProps.setFieldValue("location", e.target.value)}
              value={formikProps.values.location}
            >
              {data.tenant.locations.map(location => (
                <MenuItem value={location.id}>{location.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>

      <Typography variant="body1" className={classes.type}>
        <strong>Installed Devices</strong>
      </Typography>

      {installedDevicesSection}

      <Box>
        {/* <Button>Add</Button> */}
      </Box>

    </Box>
  )
};