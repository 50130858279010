import * as React from 'react';
import { Card as MUICard, makeStyles } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { cpTheme } from "../../theme/cpTheme";

const useStyles = makeStyles( theme => ({
  root: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.divider,
    // cursor: 'pointer',
    // "&:hover": {
    //   boxShadow: theme.shadows[3],
    // }
  }
}));

export interface ICardProps {
  children?: React.ReactNode | string,
  classes?: React.ReactNode | string,
}

export const Card: React.FC<CardProps> = (props) => {
  const classes = useStyles();
  return (
    <MUICard className={classes.root}>
      {props.children}
    </MUICard>
  );
}
