import * as React from "react";
import { TenantId, LocationId } from "../models";
import { useCookies } from "react-cookie";
import Axios from "axios";
import { API_BASE_URL } from "../config";
import { useHistory } from "react-router";
import { Backdrop, CircularProgress } from "@material-ui/core";
import createPersistedState from 'use-persisted-state'

interface IAppState {
  tenantId: TenantId | null;
  locationId: LocationId | null;
  drawerDeviceId: string | null;
  setDrawerDeviceId: (drawerDeviceId: string) => void;
  setTenantId: (tenantId: TenantId) => void;
  setLocationId: (locationId: LocationId) => void;
  setSessionIdAndToken: (sessionId: string, token: string) => void;
  sessionId: string | null;
  token: string | null;
  viewer: IViewer | null;
  logout: () => any;
  appRoute: boolean;
  setAppRoute: (value: boolean) => void;
}

const AppStateContext = React.createContext<IAppState>({
  tenantId: null,
  locationId: null,
  setLocationId: () => null,
  setTenantId: () => null,
  drawerDeviceId: null,
  setDrawerDeviceId: () => null,
  setSessionIdAndToken: () => null,
  sessionId: null,
  token: null,
  viewer: null,
  logout: () => { },
  appRoute: false,
  setAppRoute: () => {},
})

interface IViewer {
  firstName: string;
  lastName: string;
  emailAddress: string;
  id: string;
  superadmin: boolean;
  showSidebarExpanded: boolean;
}

const useTenantIdState = createPersistedState("tenantId")

export const AppStateProvider: React.FC = (props) => {
  const SESSION_ID_COOKIE_NAME = 'CONNECTED_PROOF_SESSION_ID'
  const TOKEN_COOKIE_NAME = 'CONNECTED_PROOF_TOKEN'

  const [tenantId, _setTenantId] = useTenantIdState("")
  const [locationId, setLocationId] = React.useState<string | null>(null)
  const [drawerDeviceId, setDrawerDeviceId] = React.useState<string | null>(null)
  const [viewer, setViewer] = React.useState<IViewer | null>(null)
  const history = useHistory()
  const [loading, setLoading] = React.useState(true)
  const [appRoute, setAppRoute] = React.useState(false)

  const setTenantId = (newTenantId: string) => {
    _setTenantId(newTenantId)
  }

  const [cookies, setCookie, removeCookie] =
    useCookies([SESSION_ID_COOKIE_NAME, TOKEN_COOKIE_NAME])

  const setSessionIdAndToken = (sessionId: string, token: string) => {
    setCookie(SESSION_ID_COOKIE_NAME, sessionId)
    setCookie(TOKEN_COOKIE_NAME, token)
  }

  const logout = () => {
    removeCookie(SESSION_ID_COOKIE_NAME)
    removeCookie(TOKEN_COOKIE_NAME)
    setTenantId("")
    window.location.href = "/login"
  }

  const sessionId = cookies[SESSION_ID_COOKIE_NAME]
  const token = cookies[TOKEN_COOKIE_NAME]

  const getViewer = async () => {
    if (sessionId && token) {
      try {
        const response = await Axios.get(`${API_BASE_URL}/auth/viewer`, {
          headers: {
            "X-Connected-Proof-Session-Id": sessionId,
            "X-Connected-Proof-Token": token
          }
        })
        setLoading(false)

        if (response.status === 200) {
          setViewer(response.data)
          if (!tenantId) {
            if (response.data.tenants.length) {
              setTenantId(response.data.tenants[0].id)
            } else {
              setTenantId("fc708916-f669-40d2-a22e-81d07d2ebc49")
            }
          }
          
        }
      } catch (e) {
        logout()
        setLoading(false)
        redirectToLogin()
      }
    } else {
      setLoading(false)

      if ((window.location.href.indexOf("reset") === -1) && !(process.env.REACT_APP_IS_TOUCH === "true")) {
        redirectToLogin()
      }
    }
  }

  const redirectToLogin = () => {
    history.push("/login")
  }

  React.useEffect(
    () => {
      getViewer()
    }, [sessionId, token]
  )

  return (
    <AppStateContext.Provider value={
      {
        tenantId,
        locationId,
        drawerDeviceId,
        setLocationId,
        setTenantId,
        setDrawerDeviceId,
        setSessionIdAndToken,
        sessionId,
        token,
        viewer,
        logout,
        appRoute,
        setAppRoute,
      }
    }>
      {loading ? (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : props.children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  const currentTenantContext = React.useContext(AppStateContext)

  return currentTenantContext
};