import * as React from "react";
import clsx from 'clsx';
import { Box } from "@material-ui/core";
import { Panel } from "../panel";
import { HeaderDefault } from "../header-default";
import { MainContent } from "../layout/MainContent";

interface ILayoutWithHeaderAndBodyProps {
  tenantName: string,
  title: string,
  actionsSection?: React.ReactNode,
  contentSection: React.ReactNode,
  className?: string,
}

type LayoutWithHeaderAndBodyProps = ILayoutWithHeaderAndBodyProps

export const LayoutWithHeaderAndBody: React.FC<LayoutWithHeaderAndBodyProps> = ({
  className, ...props
}) => {
  return (
    <Panel 
      className={clsx(
        className
      )}
      header={
      <HeaderDefault
        tenantName={props.tenantName}
        title={props.title}
        actionsSection={props.actionsSection}
      />
      }
      body={
        <MainContent>
          {props.contentSection}
        </MainContent>
      }
    />
  )
}