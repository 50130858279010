import * as React from "react";
import { useAppState } from "../../hooks/appState";
import { useTenant } from "../../hooks/tenant";
import moment from "moment"
import {
  Container,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { AppBar } from "../app-bar/AppBar";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { TenantsQuery } from "./__generated__/TenantsQuery";
import { NavLink } from "react-router-dom";
import { CreateNewOrganizationDialog } from "../create-new-organization-dialog";
import { LayoutWithHeaderAndBody } from "../layout-with-header-and-body";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  dialog: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
    },
  },
}));

function createData(orgName: string, createdDate: string, isTest: string, actions: string) {
  return { orgName, createdDate, isTest, actions };
}

export const TENANTS_QUERY = gql`
query TenantsQuery {
  tenants {
    id
    created
    isTest
    name
  }
}
`

interface ITenantsTableProps {
  refresh?: string;
}
type TenantsTableProps = ITenantsTableProps

const TenantsTable: React.FC<TenantsTableProps> = (props) => {
  const { data, loading, refetch } = useQuery<TenantsQuery>(TENANTS_QUERY)
  const { tenantId, setTenantId } = useAppState()

  React.useEffect(
    () => {
      props.refresh && refetch()
    }, [props.refresh]
  )

  if (loading) return <CircularProgress />

  const rows = data?.tenants?.map(tenant => (
    <TableRow key={tenant.id}>
      <TableCell component="th" scope="row"><NavLink to={`/_superadmin/tenant/${tenant.id}`}>{tenant.name}</NavLink></TableCell>
      <TableCell>{moment(tenant.created).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
      <TableCell>{tenant.isTest ? "Yes" : "No"}</TableCell>
      <TableCell>
        {(tenantId?.toLowerCase() === tenant.id.toLowerCase()) ? (
          <Button disabled variant="outlined" onClick={() => setTenantId(tenant.id)}>
            Current
          </Button>
        ) : (
            <Button variant="outlined" onClick={() => setTenantId(tenant.id)}>
              Switch
            </Button>
          )
        }
      </TableCell>
    </TableRow>
  ))

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Organization Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Is Test Organization</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </>
  )
}


export const AdminTenantPage: React.FC = (props) => {
  const classes = useStyles();
  const { tenantId } = useAppState()
  const { data } = useTenant(tenantId || "")
  const [createNewOrganizationDialogOpen, setCreateNewOrganizationDialogOpen] = React.useState(false)

  // Start Dialog stuff
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End dialog stuff


  // Start Select stuff
  const [age, setAge] = React.useState('');

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  // 
  // Couldn't get this to work, but would be cool if we could componentize this and figure this part out.
  //
  // React.useEffect(() => {
  //   setLabelWidth(inputLabel.current!.offsetWidth);
  // }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };
  // end select stuff

  const [refresh, setRefresh] = React.useState<string | null>(null)

  const doRefresh = () => setRefresh(String(new Date()))

  return (
    <LayoutWithHeaderAndBody
      tenantName={""}
      title={"CP Super Admin"}
      contentSection={
        <>
          <Box>
            <Typography variant="h6">
              <Box component="span" mr={2}>Organizations </Box>
              <Button variant="outlined" color="primary" onClick={() => setCreateNewOrganizationDialogOpen(true)}>
                Create Org
              </Button>
            </Typography>
            <TenantsTable refresh={refresh || undefined} />
          </Box>
          
          <CreateNewOrganizationDialog
            open={createNewOrganizationDialogOpen}
            onClose={() => setCreateNewOrganizationDialogOpen(false)}
            onSubmit={() => { doRefresh() }}
          />
        </>
      }
    />
  )
}