import * as React from "react";
import { Route } from "react-router-dom"
import { Box } from "@material-ui/core";
import { RouteProps } from "../route";
import { NavBarGlobalMobile } from "../nav-global/NavBarGlobal";
import { useAppState } from "../../hooks/appState";

type RouteWithAppLayoutProps = RouteProps

export const RouteWithAppLayout: React.FC<RouteWithAppLayoutProps> = (props) => {
  const { setAppRoute } = useAppState()

  React.useEffect(
    () => {
      setAppRoute(true)
    }, []
  )

  return (
    <>
    <NavBarGlobalMobile/>
    <Route key={props.path} path={props.path} render={() => {
      return (
        <Box
          className="route-app"
          key={props.path}
          overflow="hidden"
          height="100vh"
          flex="1"
        >
          <props.component key={props.path} />
        </Box>
      )
    }}>
    </Route>
    </>
  )
}