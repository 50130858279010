import * as React from "react";
import {Button, Typography, Box, Hidden} from "@material-ui/core";
import {Sidebar} from "../layout/Sidebar";
import { makeStyles } from "@material-ui/styles"
import {cpTheme} from "../../theme/cpTheme";

type LayoutWithSidebarAndBodyProps = ILayoutWithSidebarAndBodyProps;


interface ILayoutWithSidebarAndBodyProps {
  sidebarContents: React.ReactElement
  bodyContents: React.ReactElement
}

const useStyles = makeStyles({
  container: {
    height: '100vh',
    overflow: 'hidden',
    display: "flex",
    flexDirection: "row"
  },
  body: {
    overflowY: "scroll",
    flex: '1 1 auto'
  }
})

export const LayoutWithSidebarAndBody: React.FC<LayoutWithSidebarAndBodyProps> = (props) => {
  const boxStyles = useStyles()

  return (
    <Box className={boxStyles.container}>
      <Hidden smDown>
      <Box>
        <Sidebar>
          {props.sidebarContents}
        </Sidebar>
      </Box>
      </Hidden>
      <Box className={boxStyles.body}>
        {props.bodyContents}
      </Box>
    </Box>
  )
}