import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { ButtonBase, Typography, Box, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { WirelessIndicator, WirelessStrength } from "../../status-wifi";
import { ConnectedCloudSyncStatus, SyncStatus, CloudSyncStatus } from '../../status-cloud-sync';

const useStyles = makeStyles(theme => ({
  // Whole Component
  componentContainer: {
    width: '100%',
  },

  // Persistent bar, top of screen
  statusBarContainer: {
    width: '100%',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
  },
  statusIcons: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
      opacity: '70%',
      marginLeft: theme.spacing(1),
    }
  },

  // Pull-out drawer and contents
  statusDrawer: {
    '& .MuiDrawer-paper': {
      width: 'auto',
      maxHeight: '90vh',
      overflowY: 'scroll',
      borderRadius: theme.shape.borderRadius,
      position: 'relative',
      display: 'block',
      padding: theme.spacing(0, 1),

      // '&:after': {
      //   content: '" "',
      //   display: 'block',
      //   backgroundColor: '#fff',
      //   width: '100%',
      //   height: '20px',
      //   position: 'sticky',
      //   bottom: '0',
      //   left: '0',
      //   zIndex: '99',
      //   pointerEvents: 'none',
      // },
    }
  },
  statusDrawerContents: {
    // Override default icon size and spacing to make alerts and list items share layout spacing:
    '& .MuiAlert-root:not(:last-of-type)': {
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-body1': {
      fontSize: '1rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
    '& .MuiAlert-icon, & .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
      marginRight: '0',
    },
  },
  deviceName: {
    margin: theme.spacing(1.5, 1, 0),
  },
  deviceAlerts: {
    marginTop: theme.spacing(2),
  },
}));

interface ISystemStatusBarProps {
  systemTitle: string,
  statusBarContent?: React.ReactNode,
  statusDrawerContent?: React.ReactNode,
  className?: string,
  syncStatus?: SyncStatus
}
type SystemStatusBarProps = ISystemStatusBarProps

export const SystemStatusBar: React.FC<SystemStatusBarProps> = ({
  className, ...props
}) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor: 'top', open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const statusDrawer = (anchor: 'top') => (
    <div
      className={classes.statusDrawerContents}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

      <Typography className={classes.deviceName} variant="body1">
        {props.systemTitle}
      </Typography>

      {/* Optional notifications */}
      {props.statusDrawerContent &&
        <div className={classes.deviceAlerts}>
          {props.statusDrawerContent}
        </div>
      }

      {/* Required System Settings Below */}
      <List>
        <ListItem>
          <ListItemIcon>
            {props.syncStatus ? <CloudSyncStatus status={props.syncStatus} /> : <ConnectedCloudSyncStatus />}
          </ListItemIcon>
          <ListItemText primary="Cannot reach cloud" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <WirelessIndicator strength={WirelessStrength.Low} />
          </ListItemIcon>
          <ListItemText primary="Employee-wifi-5g" />
        </ListItem>
      </List>

    </div>
  );

  return (
    <div className={clsx(
      classes.componentContainer,
      className,
    )}>
      <ButtonBase
        className={classes.statusBarContainer}
        // TODO
        // Disabling click for now before we add wifi status, etc
        // onClick={toggleDrawer('top', true)}
      >
        <Typography variant="body2" color="textSecondary">
          {props.systemTitle}
        </Typography>
        {/* <Box className={classes.statusIcons}>
          {props.statusBarContent}
          <ConnectedCloudSyncStatus />
          <WirelessIndicator strength={WirelessStrength.Low} />
        </Box> */}
      </ButtonBase>
      <SwipeableDrawer
        className={classes.statusDrawer}
        anchor={'top'}
        open={state['top']}
        onClose={toggleDrawer('top', false)}
        onOpen={toggleDrawer('top', true)}
      >
        {statusDrawer('top')}
      </SwipeableDrawer>
    </div>
  );
};