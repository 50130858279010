import * as React from "react";
import { IInstallation } from "../../models";
import { useInstallation } from "../../hooks/installations";
import { Card } from "../card/Card"
import { WirelessIndicator, WirelessStrength } from "../status-wifi";
import { CircularProgress, Typography, Box, CardContent, Grid, Divider, CardActionArea, CardActions, makeStyles } from "@material-ui/core";
import { LineChart, YAxis, Line, ResponsiveContainer, ReferenceLine } from "recharts";
import numeral from "numeral";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import {
  InstallationQuery,
  InstallationQueryVariables,
  InstallationQuery_installation,
  InstallationQuery_installation_installedTo
} from "./__generated__/InstallationQuery";
import { EquipmentControls } from "../equipment/equipment-controls";
import { INSTALLATION_FRAGMENT } from "../../fragments";
import { useLiveReading } from "../physical-devices/temperature-controller";
import { NoData } from "../device-card";
import { InstallationCardQuery, InstallationCardQueryVariables } from "./__generated__/InstallationCardQuery";

const useStyles = makeStyles(theme => ({
  miniCard: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    transition: 'all 0.3s ease',
    "&:hover": {
      boxShadow: theme.shadows[3],
      cursor: 'pointer',
    }
  },
}));

interface IInstallationCard {
  installation: IInstallation
  onClick?: () => void;
  refresh?: string;
  min?: number;
  max?: number;
  mini?: boolean;
}

type InstallationCard = IInstallationCard

const TEMP_MARGIN = 5

const fahrenheitTemperatureFormatter = (temp: number) => `${numeral(temp).format("0.0")}°F`

const INSTALLATION_CARD_QUERY = gql`
  query InstallationCardQuery($installationId: String!) {
    installation(id: $installationId) {
      ...InstallationFragment
    }
  }

  ${INSTALLATION_FRAGMENT}
`

type WiredInstallationCardProps = {
  id: string;
  onClick: () => any;
  refresh?: string | null | undefined;
}

const latestReading = (installation: InstallationQuery_installation) =>
  installation.readings[0].value

export const WiredMiniInstallationCard: React.FC<WiredInstallationCardProps> = (props) => {
  const [refetching, setRefetching] = React.useState(false)

  const { data, loading, refetch } =
    useQuery<InstallationCardQuery, InstallationQueryVariables>(INSTALLATION_CARD_QUERY, {
      variables: {
        installationId: props.id
      },
      fetchPolicy: "no-cache"
    })

  const refresh = async () => {
    if (props.refresh) {
      setRefetching(true)
      await refetch()
      setRefetching(false)
    }
  }

  React.useEffect(() => { refresh() }, [props.refresh])

  const wiredProps = (data && !loading) ?
    ({
      title: data.installation.instance.equipment.name,
      type: "Temperature",
      latestReading: latestReading(data.installation),
      readings: data.installation.readings.map(r => r.value)
    }) : {}

  return (
    <MiniInstallationCard
      loading={loading || refetching}
      onClick={props.onClick}
      {...wiredProps}
    />
  )
}


export const WiredInstallationCard: React.FC<WiredInstallationCardProps> = (props) => {
  const [refetching, setRefetching] = React.useState(false)

  const { data, loading, refetch } =
    useQuery<InstallationCardQuery, InstallationCardQueryVariables>(INSTALLATION_CARD_QUERY, {
      variables: {
        installationId: props.id
      },
      fetchPolicy: "no-cache"
    })

  const refresh = async () => {
    if (props.refresh) {
      setRefetching(true)
      await refetch()
      setRefetching(false)
    }
  }

  React.useEffect(() => { refresh() }, [props.refresh])

  const resolveInstallationName = (installation: InstallationQuery_installation): string => {
    if (installation.name) {
      return installation.name
    }

    if (installation.installedTo.__typename === "LocationInstallationTarget") {
      return installation.name || installation.instance.name || installation.instance.equipment.name
    }

    if (installation.installedTo.__typename === "EquipmentInterfaceInstanceInstallationTarget") {
      if (installation.installedTo.equipmentInterfaceInstance.name) {
        return installation.installedTo.equipmentInterfaceInstance.name
      } else {
        return [
          (installation.installedTo.equipmentInterfaceInstance.equipmentInstance.name ||
            installation.installedTo.equipmentInterfaceInstance.equipmentInstance.equipment.name),
          installation.installedTo.equipmentInterfaceInstance.equipmentInterface.name
        ].filter(v => !!v).join(" ")
      }
    }

    throw Error()
  }

  const wiredProps = (data && !loading) ?
    ({
      title: resolveInstallationName(data.installation),
      type: data.installation.instance.equipment.name,
      latestReading: data.installation.readings[data.installation.readings.length - 1]?.value,
      readings: data.installation.readings.map(r => r.value),
      graphicalDiagramSvg: data.installation.instance.equipment.graphicalDiagramSvg
    }) : {}

  return (
    <span />
    // <InstallationCard
    // deviceId={data?.installation.}
    //   loading={loading || refetching}
    //   onClick={props.onClick}
    //   {...wiredProps}
    // />
  )
}

type InstallationCardProps = IInstallationCardProps

interface IInstallationCardProps {
  loading: boolean;
  onClick: () => any;
  title?: string;
  type?: string;
  latestReading?: number;
  readings?: number[];
  graphicalDiagramSvg?: string | null;
  deviceId: string;
}

interface IMiniInstallationCardProps {
  loading: boolean;
  onClick: () => any;
  latestReading?: number;
}

type MiniInstallationCardProps = IMiniInstallationCardProps

export const MiniInstallationCard: React.FC<MiniInstallationCardProps> = (props) => {
  const classes = useStyles()

  const {
    latestReading,
    onClick,
    loading
  } = props

  return (
    <Box
      className={classes.miniCard}
      onClick={(e) => {
        e.preventDefault()
        props.onClick()
        return false
      }}
    >
      {!loading && (
        <Typography variant="h6"><b>{latestReading ?
          <span>{fahrenheitTemperatureFormatter(latestReading)}</span> : <NoData />}</b></Typography>
      )}
      {loading && <CircularProgress />}
    </Box>
  )
}

export const InstallationCard: React.FC<InstallationCardProps> = (props) => {
  const {
    loading,
    title,
    type,
    latestReading,
    onClick,
    readings,
    deviceId
  } = props

  const wirelessStrength = WirelessStrength.Medium
  const average = readings ? readings[0] : 0

  const minTemp = readings ? (Math.min(...readings)) : 0
  const maxTemp = readings ? (Math.max(...readings)) : 0
  const liveReading = useLiveReading(props.deviceId)

  return (
    <Card className="installationCard">
      {/* <CardContent onClick={onClick}> */}
      <Grid container spacing={3}>

        <Grid item>
          {props.graphicalDiagramSvg && (
            <span dangerouslySetInnerHTML={
              { __html: props.graphicalDiagramSvg }
            } />
          )}
        </Grid>

        <Grid item>
          <CardActionArea>
            <CardContent onClick={onClick}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="overline">{type}</Typography>
                <WirelessIndicator strength={wirelessStrength} />
              </Box>
              <Typography variant="h6" component="h2">
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>

        <Grid item>
          <Box style={{ minHeight: "50px" }}>
            {!loading && (
              <Typography variant="h5" component="p" color="primary"><b>{latestReading ?
                <span>{fahrenheitTemperatureFormatter(latestReading)}</span> : <span />}</b></Typography>
            )}
            {loading && <CircularProgress />}
          </Box>
        </Grid>
        <Grid item>
          <CardActions>
            {/* <Divider orientation="vertical" /> */}
            {/* if controls exist for this device, show correct controls here */}
            {/* <EquipmentControls /> */}
            {/* endif */}
          </CardActions>
        </Grid>
      </Grid>
    </Card >
  )
}

// export const InstallationCard: React.FC<InstallationCard> = (props) => {
//   const installation = props.installation

//   const classes = useStyles();


//   const readings: any[] = []
//   // const readings = installation ?
//   //   (installation.status.readings
//   //     .map(reading => [reading.metricIndex, reading.temperature])
//   //     .filter(reading => !!reading)
//   //   ) : []

//   const readingGroups = groupBy(readings, (r: any) => r[0])

//   const type = <span>Temp</span>
//   const title = <span>{installation?.instance.equipment.name ? installation.instance.equipment.name : `Device ${installation.id.substring(0, 8)}`}</span>

//   const [cardHoverState, setCardHoverState] = React.useState({})

//   const arrAvg = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length

//   const cards = Object.keys(readingGroups).map((index: string) => {
//     const cardReading = readingGroups[index].map((r: any) => r[1])

//     const latestReading = readingGroups[index].map((r: any) => r[1]).slice(-1)[0]

//     const average = arrAvg(cardReading)

//     const minTemp = Math.min(...cardReading)
//     const maxTemp = Math.max(...cardReading)

//     const horizontalPoints = [0, 1, 2]

//     if (props.mini) {
//       return (
//         <Box
//           className={classes.miniCard}
//         >
//           {!loading && (
//             <Typography variant="h6" color="primary"><b>{latestReading ? <span>{fahrenheitTemperatureFormatter(latestReading)}</span> : <span />}</b></Typography>
//           )}
//           {loading && <CircularProgress />}
//         </Box>
//       )
//     }

//     return (
//       <Card className="installationCard">
//         <CardContent onClick={props.onClick}>
//           <Box display="flex" justifyContent="space-between" width="100%">
//             <Typography variant="overline">{type}</Typography>
//             <WirelessIndicator strength={WirelessStrength.Medium} />
//           </Box>

//           <Typography variant="h6" component="h2">
//             {installation ? installation.installation.instance.equipment.name || "" : ""}
//           </Typography>

//           <Box style={{ minHeight: "50px" }}>
//             {!loading && (
//               <Typography variant="h5" component="p" color="primary"><b>{latestReading ? <span>{fahrenheitTemperatureFormatter(latestReading)}</span> : <span />}</b></Typography>
//             )}
//             {loading && <CircularProgress />}
//           </Box>

//           <div className={loading ? `tc center` : ""} style={{ minHeight: "130px", minWidth: "100%" }}>
//             {loading && (<CircularProgress />)}
//             {!loading && (
//               <ResponsiveContainer width="100%" height={130}>
//                 <LineChart
//                   margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
//                   data={cardReading.map((r: any) => ({ reading: r }))}
//                   width={400}
//                   height={130}
//                 >
//                   <ReferenceLine
//                     strokeDasharray="3 3"
//                     y={minTemp - TEMP_MARGIN}
//                     stroke="#9F9F9F"
//                   />
//                   <ReferenceLine
//                     strokeDasharray="3 3"
//                     y={maxTemp + TEMP_MARGIN}
//                     stroke="#9F9F9F"
//                   />
//                   <ReferenceLine
//                     strokeDasharray="3 3"
//                     y={average}
//                     stroke="#9F9F9F"
//                   />
//                   <YAxis
//                     domain={[`dataMin - ${TEMP_MARGIN / 5}`, `dataMax + ${TEMP_MARGIN / 5}`]}
//                     tickSize={0}
//                     ticks={[minTemp - TEMP_MARGIN, average, maxTemp + TEMP_MARGIN]}
//                     tickMargin={10}
//                     axisLine={false}
//                     orientation={"right"}
//                     tickFormatter={fahrenheitTemperatureFormatter}
//                   />
//                   <Line
//                     dot={false}
//                     type="monotone"
//                     dataKey="reading"
//                     strokeWidth={3}
//                     stroke={cpVars.paletteBrandPrimary}
//                   />
//                 </LineChart>
//               </ResponsiveContainer>
//             )}
//           </div>
//         </CardContent>
//       </Card >
//     )
//   })

//   return (
//     <>
//       {cards}
//     </>
//   )
// }

