import * as React from "react"
import clsx from 'clsx';
import { makeStyles, Box} from "@material-ui/core"


const useStyles = makeStyles( theme => ({
  mainContent: {
    width: '100%',
    
    // Should match padding in <HeaderDefault>
    // Padding on XS and SM 
    // needs to accommodate for fixed bottom global nav
    padding: theme.spacing(3,3,10),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4,4,10),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5,5,7),
    },
  },
}));

export interface IMainContentProps {
  children?: React.ReactNode | string,
  className?: string,
}

type MainContentProps = IMainContentProps

export const MainContent: React.FC<MainContentProps> = ({
  className, ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(
      classes.mainContent,
      className,
    )}
      {...props}>
      {props.children}
    </Box>
  )
}
